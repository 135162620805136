<template>
  <div class="OperatingCosts" style="padding-top: 46px">
    <!-- 顶部导航 -->
    <van-nav-bar
      title="我的工费"
      left-arrow
      @click-left="onClickBack"
      fixed
      safe-area-inset-top
    ></van-nav-bar>
    <van-notice-bar
      wrapable
      :scrollable="false"
      text="当未查询到工费数据时，可能由于店工部工费录入人未及时录入，请您主动联系该负责人确认具体情况。"
    />
    <br>
    <van-cell-group inset>
      <van-field
        v-model="repairType"
        is-link
        readonly
        label="月份"
        :placeholder="selectedMonth"
        @click="showMonthPicker = true"
      />

      <van-popup v-model:show="showMonthPicker" round position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          title="选择年月"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatterMonth"
          @cancel="showMonthPicker = false"
          @confirm="onMonthConfirm"
        />
      </van-popup>
      <van-field
        v-model="orderStatus"
        is-link
        readonly
        label="确认状态"
        placeholder="全部"
        @click="showOrderStatusPicker = true"
      />

      <van-popup v-model:show="showOrderStatusPicker" round position="bottom">
        <van-picker
          default-index="1"
          :columns="orderStatusList"
          @cancel="showOrderStatusPicker = false"
          @confirm="onOrderStatusConfirm"
        />
      </van-popup>
    </van-cell-group>
    <br />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div>
          <span
            style="
              margin-bottom: 10px;
              text-align: center;
              display: block;
              color: rgb(150, 151, 153);
              font-size: 14px;
            "
            >{{ yearMonth }}合计工费（含税）：{{ total.toFixed(2) }}元</span
          >
        </div>
        <van-cell v-for="item in list" :key="item">
          <van-row>
            <van-col span="6">费用编号：</van-col>
            <van-col span="18">{{ item.feiyongCode }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6">订单编号：</van-col>
            <van-col span="18">{{ item.order.code }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6">订单名称：</van-col>
            <van-col span="18">{{ item.order.name }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6">下单日期：</van-col>
            <van-col span="18"
              >{{ formatDateDay(item.order.createdAt) }}
            </van-col>
          </van-row>
          <van-row>
            <van-col span="6">工费金额：</van-col>
            <van-col span="18">{{ item.money.toFixed(2) }} </van-col>
          </van-row>

          <van-row>
            <van-col span="6">录入日期：</van-col>
            <van-col span="6">{{ item.recordDate }} </van-col>
            <van-col
              span="12"
              style="text-align: right; color: #325cfe"
              @click="onConfirme(item.id)"
              v-if="!item.confirmed"
            >
              确认
            </van-col>
          </van-row>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <van-dialog
      v-model:show="showDialog"
      message="确认此工费信息？"
      show-cancel-button
      confirm-button-text="是"
      cancel-button-text="否"
      :beforeClose="beforeClose"
    >
    </van-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { GetAircost, GetAirConfirmed } from "../api";
import { useRoute } from "vue-router";
import { Toast } from "vant";
export default {
  setup() {
    const list = ref([]);
    let route = useRoute();
    const userList = ref([]);
    const orderStatus = ref("");
    const repairType = ref("");
    const airId = ref("");
    const orderStatusList = ref(["未确认", "全部", "已确认"]);
    const loading = ref(false);
    const finished = ref(false);
    const showDialog = ref(false);
    const refreshing = ref(false);
    const selectedMonth = ref(
      new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1 < 10
          ? "0" + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1)
    );
    const currentDate = ref(new Date());
    const maxDate = ref(new Date(2100, 10, 1));
    const showMonthPicker = ref(false);
    const showOrderStatusPicker = ref(false);
    const error = ref(false);
    const total = ref(0);
    const yearMonth = ref(
      new Date().getFullYear() + "年" + (new Date().getMonth() + 1) + "月"
    );
    const onLoad = () => {
      let confirmed = "";
      switch (orderStatus.value) {
        case "未确认":
          confirmed = 0;
          break;
        case "已确认":
          confirmed = 1;
          break;
        default:
          confirmed = 2;
          break;
      }
      GetAircost({
        token: route.query[1],
        userid: route.query[0],
        confirmed: confirmed,
        recorddate: selectedMonth.value,
      })
        .then((result) => {
          if (refreshing.value) {
            list.value = [];
            total.value = 0;
            refreshing.value = false;
          }
          list.value.push(...result.data);
          list.value.forEach((element) => {
            total.value += element.money;
          });
          loading.value = false;

          finished.value = true;
        })
        .catch((err) => {
          console.log(err);
          error.value = true;
          loading.value = false;
          // this.finished = true;
          // Toast("网络错误");
        });
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      //   this.pageNum = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      list,
      loading,
      refreshing,
      onRefresh,
      showDialog,
      airId,
      finished,
      selectedMonth,
      currentDate,
      maxDate,
      repairType,
      orderStatus,
      showMonthPicker,
      showOrderStatusPicker,
      orderStatusList,
      total,
      yearMonth,
      error,
      onLoad,
      userList,
    };
  },
  methods: {
    onConfirme(id) {
      this.showDialog = true;
      this.airId = id;
    },
    beforeClose(action) {
      if (action === "confirm") {
        Toast.loading({
          message: "正在加载...",
          forbidClick: true,
          duration: 0,
        });
        GetAirConfirmed({
          token: this.$route.query[1],
          userid: this.$route.query[0],
          feiyongid: this.airId,
        })
          .then((result) => {
            Toast.clear();
            this.showDialog = false;
            this.list = [];
            this.onLoad();
            if (result.data.code == 1) {
              Toast(result.data);
            }
          })
          .catch((err) => {
            console.log(err);
            // this.finished = true;
            Toast("网络错误");
          });
      } else {
        // 重置表单校验
        this.showDialog = false;
        this.airId = 0;
      }
    },
    onMonthConfirm(value) {
      this.finished = false;
      this.repairType = this.formatDateMonth(value);
      this.selectedMonth = this.formatDateMonth(value);
      this.showMonthPicker = false;
      this.list = [];
      this.total = 0;
      this.yearMonth =
        value.getFullYear() + "年" + (value.getMonth() + 1) + "月";
      this.getAircost(this.orderStatus);
    },
    onOrderStatusConfirm(value) {
      this.finished = false;
      this.showOrderStatusPicker = false;
      this.orderStatus = value;
      this.list = [];
      this.total = 0;
      // this.getAircost(value);
    },
    formatterMonth(type, val) {
      if (type === "year") {
        return `${val}年`;
      }
      if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    onClickBack() {
      if (this.$route.query[2] == "listDetails") {
        // 跳转上一级。
        this.$router.go(-1);
      } else {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      }
    },
    formatDateMonth(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        // let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month
          // +
          // "-" +
          // // dt.getDate() +
          // day +
          // " "
        );
      }
    },
    formatDateDay(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day
          // +
          // " "
        );
      }
    },
  },
};
</script>

<style scoped>
div .main {
  width: 100%;
  height: max-content;
  position: absolute;
  background-color: #f8f8f8;
}
.van-nav-bar {
  background-color: #325cfe;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
.reback {
  color: red;
}
</style>
