<template>
  <!-- 顶部导航  @load="onLoad"-->
  <div class="main" style="padding-top: 46px">
    <NavBar z_left_arrow title="物料跟踪" @click-left="onClickLeft" />
    <van-pull-refresh v-model="isLoading" @refresh="onLoad">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
      >
        <br />
        <van-cell-group inset>
          <van-cell v-for="item in list" :key="item" @click="jump(item)">
            <span>物料编号{{ "\u00A0\u00A0\u00A0\u00A0" }}{{ item.code }}</span>
            <span v-if="item.state == 'NEW'" class="contentSpan">[已申请]</span>
            <span v-if="item.state == 'VERIFY'" class="contentSpan"
              >[已审核]</span
            >
            <span v-if="item.state == 'ISCONFIRM'" class="contentSpan"
              >[已确认]</span
            >
            <span v-if="item.state == 'XIADAN'" class="contentSpan"
              >[已下单]</span
            >
            <span v-if="item.state == 'MAKE'" class="contentSpan">
              [制作中]</span
            >
            <span v-if="item.state == 'SEND'" class="contentSpan"
              >[已发货]</span
            >
            <span v-if="item.state == 'RECEIVE'" class="contentSpan"
              >[已收货]</span
            >
            <span v-if="item.state == 'CLOSED'" class="contentOtherSpan"
              >[已取消]</span
            >
            <span v-if="item.state == 'RETURNED'" class="contentOtherSpan"
              >[已退货]</span
            >
            <span
              v-if="item.riskStatementVo"
              style="display: inline-block; color: red"
            >
              [物流风险]
            </span>
            <br />
            <span
              >申请日期{{ "\u00A0\u00A0\u00A0\u00A0" }}{{ item.strDate }}</span
            >
            <br />
            <span
              >订单编号{{ "\u00A0\u00A0\u00A0\u00A0"
              }}{{ this.$route.query.code }}</span
            >
          </van-cell>
          <!-- <div class="divLine"></div> -->
        </van-cell-group>
      </van-list>
    </van-pull-refresh>

    <van-popup
      v-model:show="show"
      round
      :style="{ width: '80%', height: '80%' }"
    >
      <van-steps direction="vertical" :active="0">
        <van-step v-for="item in wuliuList" :key="item">
          <h5>{{ item.context }}</h5>
          <p>{{ formatDate(item.ftime) }}</p>
        </van-step>
      </van-steps>
      <van-button
        style="display: inline"
        type="primary"
        size="large"
        round
        @click="shouhuoBtn(item)"
        >确认收货</van-button
      >
    </van-popup>
    <van-popup
      v-model:show="tuihuoShow"
      round
      :style="{ width: '90%', height: '30%' }"
    >
      <span
        style="
          text-align: center;
          display: block;
          line-height: 33px;
          text-align: center;
          display: block;
          font-size: 14px;
          height: 33px;
        "
        >填写退货信息</span
      >
      <van-field
        v-model="result"
        readonly
        label="快递公司"
        placeholder="请选择"
        @click="showPicker = true"
        required
      />
      <van-field
        v-model="kuaididanhao"
        label="快递单号"
        placeholder="请输入"
        required
      />
      <br />
      <van-button
        style="display: block; margin: 0 auto"
        type="primary"
        size="large"
        round
        :disabled="disable"
        @click="querentuihuo(result, reindex)"
        >确认退货</van-button
      >
    </van-popup>
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
        :columns="wuliuDataList"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
  <div class="footer" style="width: 95%; margin-left: 10px">
    <van-button type="primary" size="large" round @click="addMaterial"
      >物料添加</van-button
    >
  </div>
</template>

<script>
import NavBar from "../../view/NavBar.vue";
import {
  Viewitem,
  Express,
  Confirmreceipt,
  Returnmreceipt,
  Iscompany,
} from "../../api";
import { Toast } from "_vant@3.4.5@vant";
import { ref } from "vue";

export default {
  components: {
    NavBar,
  },
  setup() {
    const columns = ["顺丰快递"];
    const result = ref("");
    const reindex = ref("");
    const showPicker = ref(false);

    const onConfirm = (value, index) => {
      reindex.value = index;
      result.value = value;
      showPicker.value = false;
    };

    const value = ref("");

    return {
      result,
      reindex,
      columns,
      onConfirm,
      showPicker,
      value,
    };
  },
  name: "MapContainer",
  data() {
    return {
      // list: ["", ""],
      list: [],
      detailsData: {},
      show: false,
      tuihuoShow: false,
      wuliuList: [],
      wuliuDataList: [],
      wuliuIDDataList: [],
      clickId: "",
      kuaidigongsi: "",
      kuaididanhao: "",
      isLoading: false,
      loading: false,
      finished: false,
      disable: false,

      // finished = false,
    };
  },
  watch: {
    kuaididanhao(newValue) {
      // console.log(newValue);
      this.kuaididanhao = newValue.replace(
        /[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g,
        ""
      );
    },
  },
  methods: {
    jump(item) {
      // console.log(item);
      this.detailsData = item;
      this.detailsData.code = this.$route.query.code;
      this.detailsData.name = this.$route.query.name;
      this.detailsData.z_token = this.$route.query.z_token;
      this.detailsData.z_userid = this.$route.query.z_userid;
      this.detailsData.z_id = this.$route.query.id;
      this.detailsData.z_materialList = JSON.stringify(item.cailiaoList);
      this.detailsData.z_primaryPhotoList = JSON.stringify(
        item.primaryPhotoList
      );
      this.detailsData.z_riskStatementVo = JSON.stringify(item.riskStatementVo);
      this.detailsData.z_fahuoWuliuCompany1 = JSON.stringify(
        item.fahuoWuliuCompany1
      );
      this.detailsData.z_fahuoWuliuCompany2 = JSON.stringify(
        item.fahuoWuliuCompany2
      );
      this.detailsData.z_fahuoWuliuCompany3 = JSON.stringify(
        item.fahuoWuliuCompany3
      );
      this.detailsData.z_returnWuliuCompany = JSON.stringify(
        item.returnWuliuCompany
      );
      this.detailsData.z_isLook = true;
      this.$router.push({
        path: "/MaterialLook",
        // query: JSON.stringify(this.detailsData),
        query: this.detailsData,
      });
    },
    onConf() {
      Iscompany({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0MyIsImV4cCI6MTk2NTUyNTY2NCwiaWF0IjoxNjQ5OTA2NDY0fQ.tWkomw7zIoi8nmR4gBxok8fY4niKIK8a_LMy1h2Rd4A",
        // userid: "43",
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
      })
        .then((result) => {
          // this.wuliuList.push(...result.data);
          if (result.data.length > 0) {
            console.log(result);
            result.data.forEach((item) => {
              // console.log(item);
              this.wuliuDataList.push(item.name);
              this.wuliuIDDataList.push(item.id);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          // Toast("网络错误");
        });
    },
    onLoad() {
      // 显示loading
      // this.bus.$emit("loading", true);
      // alert(111 + state);
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      Viewitem({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
        // userid: "24",
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        orderid: this.$route.query.id,
      })
        .then((result) => {
          Toast.clear();
          // this.list = [];
          // let pageNum = result.data.pageNum; //请求返回当页的列表
          // this.loading = false;
          // // this.total = result.data.total;

          // if (pageNum == null || pageNum.length === 0) {
          //   // 加载结束
          //   // Toast("加载结束");
          //   this.finished = true;
          //   return;
          // }
          this.list = [];
          this.isLoading = false;
          // 将新数据与老数据进行合并
          this.list = [...this.list, ...result.data];

          //如果列表数据条数>=总条数，不再触发滚动加载 parseInt
          // if (this.list.length >= result.data.total) {
          //   // alert(this.list.length + result.data.total);
          //   // Toast(1111);
          this.finished = true;
          // }
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
          // Toast("网络错误");
        });
    },
    // 添加
    addMaterial() {
      this.detailsData = this.$route.query;
      this.detailsData.z_look = true;
      this.$router.push({
        path: "/MaterialSubmission",
        query: this.$route.query,
      });
    },
    // 查看物流
    lookLogistics(item) {
      this.clickId = item.id;
      // console.log(JSON.stringify(item.fahuoWuliu));
      Express({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        com: "shunfeng",
        // num: item.code,
        num: item.fahuoCode,
      })
        .then((result) => {
          this.list = [];
          if (result.code == 1) {
            this.wuliuList = result.data;
            // console.log(this.wuliuList);
            this.onLoad();
            this.show = true;
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 确认收货
    shouhuoBtn() {
      Confirmreceipt({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        orderid: this.$root.$route.query.id,
        // num: item.code,
        ordermaterialid: this.clickId,
      })
        .then((result) => {
          if (result.code == 1) {
            // console.log(this.wuliuList);
            this.list = [];
            this.show = false;
            // 刷新数据
            this.onLoad();
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 退货
    tuihuoBtn(item) {
      // Toast(111);
      this.clickId = item.id;
      this.tuihuoShow = true;
    },
    querentuihuo(result, reindex) {
      // this.showPicker = true;
      // console.log();
      if (result == "") {
        Toast("请选择快递公司");
        return;
      }
      if (this.kuaididanhao == "") {
        Toast("请输入快递单号");
        return;
      }
      this.disable = true;
      Returnmreceipt({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        orderid: this.$root.$route.query.id,
        // num: item.code,
        ordermaterialid: this.clickId,
        returncode: this.kuaididanhao,
        expresscompany: result,
        expresscompanyid: this.wuliuIDDataList[parseInt(reindex)],
      })
        .then((result) => {
          this.disable = false;
          if (result.code == 1) {
            // console.log(this.wuliuList);
            this.list = [];
            this.tuihuoShow = false;
            // 刷新数据
            this.onLoad();
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          this.disable = false;
          console.log(err);
        });
    },
    // 时间戳转换
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          "-" +
          dt.getHours() +
          ":" +
          dt.getMinutes() +
          ":" +
          dt.getSeconds()
        );
      }
    },
  },
  mounted: function () {
    // Toast(222 + this.$route.query[0]);
    // console.log(this.$route.query);
    this.onLoad();
    this.onConf();
  },
};
</script>

<style scoped>
div .main {
  height: 100%;
  width: 100%;
  background-color: #f8f8f8;
  padding-bottom: 80px;
  position: absolute;
}
.addressSpan {
  background-color: black;
  color: rgb(0, 158, 237);
  font-size: 13px;
  height: 200px;
}
.topSpan {
  background-color: rgb(0, 158, 237);
  width: 25%;
  border: 1 solid rgb(110, 110, 110);
  text-align: center;
  color: white;
  font-size: 18px;
}
.contentSpan {
  background-color: white;
  width: 25%;
  /* border: 1% solid rgb(110, 110, 110); */
  text-align: center;
  color: rgb(10, 146, 207);
  font-size: 13px;
}
.contentOtherSpan {
  background-color: white;
  width: 25%;
  /* border: 1% solid rgb(110, 110, 110); */
  text-align: center;
  color: red;
  font-size: 13px;
}
.lookBtn {
  color: rgb(0, 158, 237);
  width: 25%;
  border: none;
  background-color: white;
  font-size: 13px;
  text-align: center;
}
.divLine {
  height: 10px;
  background-color: #f8f8f8;
}
div .footer {
  height: 60px;
  width: 100%;
  background-color: #f8f8f8;
  position: fixed;
  bottom: 0;
}
</style>
