<template>
  <!-- 顶部导航 -->
  <NavBar
    z_left_arrow
    :title="$route.query.z_status === '3' ? '查看签单照片' : '上传签单照片'"
    @click-left="onClickLeft"
    safe-area-inset-top
  />

  <div class="main" style="padding-top: 56px">
    <!-- 签单 -->
    <div
      v-if="
        this.$route.query.z_status === '0' || this.$route.query.z_status === '3'
      "
    >
      <van-notice-bar
        color="#1989fa"
        background="#ecf9ff"
        left-icon="wap-home-o"
      >
        签单照
      </van-notice-bar>
    </div>
    <div v-if="this.$route.query.z_status === '1'">
      <van-notice-bar left-icon="volume-o">
        {{ this.qiandanData.verifyMemo + "（继续追加即可）" }}
      </van-notice-bar>
    </div>
    <br />
    <van-cell-group inset>
      <van-uploader
        v-model="fileList"
        :max-count="50"
        :after-read="afterRead"
        :max-size="12 * 1024 * 1024"
        @oversize="onOversize"
        class="preview-cover"
        :multiple="false"
        :deletable="false"
        :show-upload="$route.query.z_status === '3' ? false : true"
      />
      <!-- :deletable="$route.query.z_status === '3' ? false : true" -->
    </van-cell-group>
    <br />
  </div>
  <!-- 提交按钮 -->
  <div
    class="footer"
    style="width: 95%; margin-left: 10px"
    v-if="this.$route.query.z_status === '3' ? false : true"
  >
    <van-button round type="primary" size="large" @click="uploadComplete"
      >上传完成</van-button
    >
  </div>
</template>

<script>
// import { compressImage } from "../config/utils";
import { Chakanqiandan } from "../../api";
import { Toast } from "vant";
import NavBar from "../../view/NavBar.vue";
import { Dialog } from "vant";
import compressor from "compressorjs";
import axios from "axios";
import global from "@/js/global";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      imageBase64: "",
      fileList: [],
      qiandanData: {},
      // urlString: "https://capacityapi.dbshopplus.com",
    };
  },
  methods: {
    onOversize(file) {
      console.log(file);
      Toast("照片过大");
    },
    // 返回
    onClickLeft() {
      // 跳转上一级。
      this.$router.go(-1);
    },
    // 上传完成
    uploadComplete() {
      Dialog.confirm({
        title: "",
        message: "是否确定全部签单已上传完成",
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {
          // on cancel
        });
    },

    // 查看签单
    ChakanqiandanMeth() {
      if (
        this.$route.query.z_status === "1" ||
        this.$route.query.z_status === "2" ||
        this.$route.query.z_status === "3"
      ) {
        Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
        Chakanqiandan({
          token: this.$route.query.z_token,
          userid: this.$route.query.z_userid,

          orderid: this.$route.query.id,
        })
          .then((result) => {
            Toast.clear();
            if (result.code == 1) {
              this.qiandanData = result.data[0];
              if (result.data[0]) {
                if (result.data[0].nameList.length > 0) {
                  result.data[0].nameList.forEach((item) => {
                    if (item != null) {
                      let parent = { url: item };
                      this.fileList.push(parent);
                    }
                  });
                }
              }
            } else {
              // Toast(result.message);
            }
          })
          .catch((err) => {
            Toast.clear();
            console.log(err);
          });
      }
    },
    //读取完图片后
    afterRead(file) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "上传中...",
        forbidClick: true,
      });
      if (file.length >= 1) {
        file.forEach((item, index) => {
          new compressor(item.file, {
            quality: 0.2,
            success(result) {
              const formData = new FormData();
              formData.append("signphoto", result, result.name);
              formData.append("token", that.$route.query.z_token);
              formData.append("userid", that.$route.query.z_userid);
              formData.append("orderid", that.$route.query.id);
              formData.append("sta", that.$route.query.z_status);
              let config = {
                headers: {
                  //添加请求头
                  "Content-Type": "multipart/form-data",
                },
              };
              axios
                .post(
                  global.apiUrl + "/order/uploadqiandanphoto",
                  formData,
                  config
                )
                .then((resultData) => {
                  if (resultData.data.code == 1) {
                    if (file.length - 1 == index) {
                      Toast.clear();
                      Toast("上传成功");
                    }
                    // file.status = "done ";
                    // Toast("上传成功");
                  } else {
                    if (file.length - 1 == index) {
                      Toast.clear();
                      Toast("上传失败");
                    }
                    // Toast("上传失败");
                    // file.status = "failed";
                    // file.message = "上传失败";
                  }

                  // this.getDateList();
                })
                .catch((err) => {
                  if (file.length - 1 == index) {
                    Toast.clear();
                  }
                  console.log(err);
                });
            },
          });
          // compressImage(item.content, item.file.size).then((result) => {
          //   OrderUploadqiandanphoto({
          //     token: this.$route.query.z_token,
          //     userid: this.$route.query.z_userid,
          //     signphoto: result,
          //     orderid: this.$route.query.id,
          //     sta: this.$route.query.z_status,
          //   })
          //     .then((result) => {
          //       if (result.code == 1) {
          //         file.status = "done ";
          //         Toast("上传成功");
          //       } else {
          //         Toast("图片过大,上传失败");
          //         file.status = "failed";
          //         file.message = "上传失败";
          //       }
          //       // this.getDateList();
          //     })
          //     .catch((err) => {
          //       Toast("上传失败");
          //       console.log(err);
          //     });
          //   // photo.push(result);
          // });
        });
      } else {
        file.status = "uploading";
        file.message = "上传中...";
        // photo.push(file.content);
        new compressor(file.file, {
          quality: 0.2,
          success(result) {
            const formData = new FormData();
            formData.append("signphoto", result, result.name);
            formData.append("token", that.$route.query.z_token);
            formData.append("userid", that.$route.query.z_userid);
            formData.append("orderid", that.$route.query.id);
            formData.append("sta", that.$route.query.z_status);
            let config = {
              headers: {
                //添加请求头
                "Content-Type": "multipart/form-data",
              },
            };
            axios
              .post(
                global.apiUrl + "/order/uploadqiandanphoto",
                formData,
                config
              )
              .then((resultData) => {
                Toast.clear();
                if (resultData.data.code == 1) {
                  file.status = "done";
                  Toast("上传成功");
                } else {
                  Toast("上传失败");
                  file.status = "failed";
                  file.message = "上传失败";
                }
                // this.getDateList();
              })
              .catch((err) => {
                Toast.clear();
                console.log(err);
              });
          },
        });
        // compressImage(file.content, file.file.size).then((result) => {
        //   OrderUploadqiandanphoto({
        //     token: this.$route.query.z_token,
        //     userid: this.$route.query.z_userid,
        //     signphoto: result,
        //     orderid: this.$route.query.id,
        //     sta: this.$route.query.z_status,
        //   })
        //     .then((result) => {
        //       if (result.code == 1) {
        //         file.status = "done ";
        //         Toast("上传成功");
        //       } else {
        //         Toast("图片过大,上传失败");
        //         file.status = "failed";
        //         file.message = "上传失败";
        //       }
        //       // this.getDateList();
        //     })
        //     .catch((err) => {
        //       Toast("上传失败");
        //       console.log(err);
        //     });
        //   // photo.push(result);
        // });
      }
    },
    //压缩图片保存
    // compressAndUploadFile(file) {
    //   // console.log(1);
    //   // console.log(file);

    //   compressImage(file.content, file.file.size).then((result) => {
    //     if (file.file.size > 1024 * 2) {
    //       //保存压缩图片二进制
    //       if (result.size > file.file.size) {
    //         // console.log("上传原图" + file.content);
    //         //压缩后比原来更大，则将原图上传
    //         this.imageBase64 = file.content;
    //       } else {
    //         this.imageBase64 = result;
    //         // console.log("上传压缩图" + result.file);
    //         //压缩后比原来小，上传压缩后的
    //         // 保存base64
    //         // this._uploadFile(result, file.file.name);
    //       }
    //     } else {
    //       //保存压缩图片二进制
    //       this.imageBase64 = result;
    //     }
    //   });
    // },
  },
  mounted: function () {
    this.ChakanqiandanMeth();
    console.log(333 + this.$route.query.z_status);
  },
};
</script>

<style>
div .main {
  /* position: fixed; */
  height: 100%;
  width: 100%;
  background-color: #f8f8f8;
  padding-top: 20px;
  position: absolute;
}
.preview-cover {
  margin: 5px 0 0 10px;
}
</style>
