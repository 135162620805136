<template>
  <div class="articleList">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
      <!-- <van-button type="primary" @click="jump11()">主要按钮</van-button> -->
        <div v-show="total > 0">
          <span
            style="
              text-align: center;
              display: block;
              font-size: 14px;
              height: 18px;
              padding-top: 5px;
              padding-bottom: 5px;
              color: rgb(151, 152, 154);
            "
            >{{ menuName }}订单数：{{ total }}单</span
          >
        </div>
        <van-cell v-for="item in list" :key="item" @click="jump(item)">
          <p class="projectName" style="display: inline-block">
            [{{ item.code }}]
          </p>
          <p
            v-if="item.relationFlag == 2"
            style="display: inline-block; color: rgb(253, 184, 120)"
          >
            [子单]
          </p>
          <p
            v-if="item.relationFlag == 1"
            style="display: inline-block; color: rgb(253, 116, 118)"
          >
            [母单]
          </p>
          <p
            v-if="item.relationFlag == 0"
            style="display: inline-block; color: rgb(74, 175, 202)"
          >
            [原单]
          </p>
          <p
            v-if="item.orderLevel == 3"
            style="display: inline-block; color: red"
          >
            [紧急]
          </p>
          <p
            v-if="item.preOrder == true"
            style="display: inline-block; color: rgb(97, 125, 83)"
          >
            [预备]
          </p>
          <p
            v-if="item.riskStatementVo"
            style="display: inline-block; color: red"
          >
            [物流风险]
          </p>
          <p>{{ item.name + " " }}</p>
          <p>下单日期：{{ item.strDate + " " }}</p>
          <p>计划完成日期：{{ item.askDate + " " }}</p>
          <!-- <p v-if="item.state != 'SENDED'">
                完工日期：{{ item.planEnterEndDate + " " }}
              </p> -->
          <!-- <p v-show="item.state != 'SENDED'">订单状态：{{ item.state }}</p> -->
          <div v-show="item.state == 'RECEIVE'">
            <p style="float: left">订单状态：执行中</p>
            <!-- <p style="float: left; color: green" v-show="item.status == '2'">
              
            </p> -->
            <p style="float: left; color: red" v-show="item.status == '3'">
              （审核未通过）
            </p>
          </div>
          <div v-show="item.state == 'CLOSED' || item.state == 'EXECUTE_SING'">
            <p v-show="item.state == 'CLOSED'">订单状态：已完工</p>

            <p style="float: left" v-show="item.state == 'EXECUTE_SING'">
              订单状态：已审单
            </p>
            <p
              style="float: left; color: red"
              v-show="item.state == 'EXECUTE_SING'"
            >
              （该单在15天后关闭）
            </p>
          </div>
          <div v-show="item.state == 'EXECUTE_VERFY'">
            <p style="float: left" v-show="item.state == 'EXECUTE_VERFY'">
              订单状态：
            </p>
            <p
              style="float: left; color: red"
              v-show="item.state == 'EXECUTE_VERFY'"
            >
              已关闭
            </p>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from "vue";
import { OrderList } from "../api";
import { Toast } from "_vant@3.4.5@vant";
// import { Toast } from "_vant@3.4.5@vant";
export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const error = ref(false);
    const pageNum = ref(1);
    const userid = ref(props.channel.z_userid);
    const token = ref(props.channel.z_token);
    const menuName = ref(props.channel.name);
    const name = ref(props.channel.z_name);
    const phone = ref(props.channel.z_phone);
    const state = ref(props.channel.state);
    const total = ref("");
    const onLoad = () => {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      OrderList({
        page: pageNum.value,
        size: 100,
        userid: props.channel.z_userid,
        token: props.channel.z_token,
        findfactor: "",
        state: props.channel.state,
      })
        .then((result) => {
          Toast.clear();
          if (refreshing.value) {
            list.value = [];
            refreshing.value = false;
          }
          list.value.push(...result.data.list);
          total.value = result.data.total;
          emit("changeComponentData", props.channel.z_active, total.value);
          loading.value = false;
          if (result.data.hasNextPage) {
            pageNum.value = result.data.nextPage;
          } else {
            finished.value = true;
          }
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
          error.value = true;
          loading.value = false;
          finished.value = true;
        });
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      pageNum.value = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      list,
      onLoad,
      onRefresh,
      loading,
      refreshing,
      finished,
      pageNum,
      userid,
      token,
      menuName,
      name,
      phone,
      state,
      total,
    };
  },
  data() {
    return {};
  },
  methods: {
    // 测试
    // jump11() { 
    //   // this.$router.push({
    //   //   path: "/TestJump",
    //   //   query: this.$route.query,
    //   // });
    //   this.$router.push({
    //     name: "TestJump",
    //     params: {
    //       code: 10021
    //     }
    //   });
    // },
    jump(item) {
      console.log(this.state);
      // Toast(1111);
      this.listData = item;
      if (this.state=="EXECUTE_VERFY") {
        this.listData.z_itemState = "CLOSED";
      } else {
        this.listData.z_itemState = this.state;
      }
      console.log("item.riskStatementVo"+item.riskStatementVo);
      this.listData.z_userid = this.userid;
      this.listData.z_token = this.token;
      this.listData.z_name = this.name;
      this.listData.z_phone = this.phone;
      this.listData.z_riskStatementVo = JSON.stringify(item.riskStatementVo);
      this.$router.push({
        path: "/ListDetails",
        query: this.listData,
      });
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " "
          // +
          // dt.getHours() +
          // ":" +
          // dt.getMinutes() +
          // ":" +
          // dt.getSeconds()
        );
      }
    },
  },
};
</script>

<style scoped>
.projectName {
  color: #02a7f0;
}
p {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}
</style>
