<template>
  <div class="OperatingCosts" style="padding-top: 56px">
    <!-- 顶部导航 -->
    <NavBar z_left_arrow title="工费" fixed="true" @click-left="onClickLeft" />
    <van-notice-bar
      v-if="sum > 0 ? true : false"
      color="#1989fa"
      background="#ecf9ff"
      left-icon="info-o"
    >
      工费合计：{{ sum }}元
    </van-notice-bar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <van-cell v-for="item in list" :key="item">
          <p>费用科目：直接人工费</p>
          <p>费用合计：{{ item.money + " " }}</p>
          <p>
            备注：{{
              item.memo == "" || item.memo == null ? "暂无" : item.memo
            }}
          </p>
          <p>录入日期：{{ formatDate(item.createdAt) + " " }}</p>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import NavBar from "../view/NavBar.vue";
import { ref } from "vue";
import { LaborCost } from "../api";
import { useRoute } from "vue-router";
import { Toast } from "_vant@3.4.5@vant";
export default {
  components: {
    NavBar,
  },
  setup() {
    const list = ref([]);
    let route = useRoute();
    const userList = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const error = ref(false);
    const sum = ref(0);
    const onLoad = () => {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      LaborCost({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk2NDE1Njk1MywiaWF0IjoxNjQ4NTM3NzUzfQ._KfES3vyOLUwAEBijcSlwXJ1yvLTvQ3aVGjylt6gvTQ",
        // userid: "24",
        token: route.query.z_token,
        userid: route.query.z_userid,
        orderid: route.query.id,
      })
        .then((result) => {
          Toast.clear();
          if (refreshing.value) {
            list.value = [];
            sum.value = 0;
            refreshing.value = false;
          }
          list.value.push(...result.data);
          list.value.forEach((element) => {
            sum.value += element.money;
            console.log(sum);
          });
          loading.value = false;

          finished.value = true;
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
          error.value = true;
          loading.value = false;
          // this.finished = true;
          // Toast("网络错误");
        });
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      //   this.pageNum = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      list,
      loading,
      refreshing,
      onRefresh,
      finished,
      sum,
      error,
      onLoad,
      userList,
    };
  },
  methods: {
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " "
        );
      }
    },
  },
};
</script>

<style scoped>
p {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}
</style>
