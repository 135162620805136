<template>
  <!-- 顶部导航 -->

  <!-- <br /> -->
  <NavBar style="z-index: 999; position: fixed" z_left_arrow :title="
    $route.query.z_isSeePhones === 'false' ? '查看施工照片' : '上传施工照片'
  " @click-left="onClickLeft" />
  <!-- <van-sticky>
    <van-notice-bar style="padding-top: 52px" wrapable :scrollable="false"
      text="当出现无法上传的情况时，请将图片通过微信文件助手发送后保存至相册再次上传即可。" />
  </van-sticky> -->
  <div>
    <!-- <div style="z-index: 999">
      
    </div> -->
    <!-- 门头照 -->
    <!-- <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="wap-home-o" @click="toasts">
      门头照（限制上传1张）
    </van-notice-bar> -->
    <!-- <van-notice-bar left-icon="volume-o" v-if="this.order.mentoPhotoVerify === 'DENY' ? true : false"
      :text="this.order.mentoPhotoVerifyMemo" /> -->
    <br />
    <br />
    <!-- <van-cell-group inset>
      <van-uploader class="preview-cover" :preview-options="{
        images: this.doorHeadGQList,
      }" v-model="doorHeadList" :after-read="doorHearAfterRead" :before-delete="doorHearDelete" :max-count="1"
        :max-size="12 * 1024 * 1024" @oversize="onOversize" :deletable="
          $route.query.z_isSeePhones === 'false' ||
            this.order.mentoPhotoVerify === 'PASS'
            ? false
            : true
        " :show-upload="
  $route.query.z_isSeePhones === 'false' ||
    this.order.mentoPhotoVerify === 'PASS'
    ? false
    : true
" />
    </van-cell-group> -->
    <br />

    <!-- 柜台全景照 @click-preview="clickPreview"
        :preview-full-image="false" -->
    <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="hotel-o">
      全景照（施工前后最多各上传9张）
    </van-notice-bar>
    <van-notice-bar left-icon="volume-o" v-if="this.order.guitaiPhotoVerify === 'DENY' ? true : false"
      :text="this.order.guitaiPhotoVerifyMemo" />
    <br />

    <van-cell-group inset>
      &nbsp; 施工前<br />
      <van-uploader class="preview-cover" :preview-options="{
        images: this.beforeCounterGQPhotoList,
      }" v-model="beforeCounterPhotoList" :after-read="beforeCounterPhoto" :before-delete="beforeCounterPhotoDelete"
        multiple :max-count="9" :max-size="12 * 1024 * 1024" @oversize="onOversize" :deletable="
          $route.query.z_isSeePhones === 'false' ||
            this.order.guitaiPhotoVerify === 'PASS'
            ? false
            : true
        " :show-upload="
  $route.query.z_isSeePhones === 'false' ||
    this.order.guitaiPhotoVerify === 'PASS'
    ? false
    : true
" />
      <br />
      &nbsp; 施工后<br />
      <van-uploader class="preview-cover" :preview-options="{
        images: this.afterCounterGQPhotoList,
      }" v-model="afterCounterPhotoList" :after-read="afterCounterPhoto" :before-delete="afterCounterPhotoDelete"
        multiple :max-count="9" :max-size="12 * 1024 * 1024" @oversize="onOversize" :deletable="
          $route.query.z_isSeePhones === 'false' ||
            this.order.guitaiPhotoVerify === 'PASS'
            ? false
            : true
        " :show-upload="
  $route.query.z_isSeePhones === 'false' ||
    this.order.guitaiPhotoVerify === 'PASS'
    ? false
    : true
" />
    </van-cell-group>
    <br />

    <div v-for="(item, index) in orderWorkList" :key="item">
      <!-- 工作内容 -->
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="orders-o">
        {{ index + 1 }}.{{ item.workContent }}
      </van-notice-bar>
      <van-notice-bar left-icon="volume-o" v-if="item.photoVerify === 'DENY' ? true : false"
        :text="item.photoVerifyMemo" />
      <br />
      <!-- (item, file)            @click-preview="clickPreviewgzx"
          :preview-full-image="false" -->
      <van-cell-group inset>
        &nbsp; 施工前<br />
        <van-uploader class="preview-cover" :preview-options="{
          images: this.workShigqianGQPhotoList[index],
        }" :after-read="beforeConstruction(item)" :before-delete="beforeConstructionDelete(item, index)"
          v-model="workShigqianPhotoList[index]" multiple :max-count="9" :max-size="12 * 1024 * 1024"
          @oversize="onOversize" :deletable="
            $route.query.z_isSeePhones === 'false' ||
              item.photoVerify === 'PASS'
              ? false
              : true
          " :show-upload="
  $route.query.z_isSeePhones === 'false' ||
    item.photoVerify === 'PASS'
    ? false
    : true
" />
        <br />
        &nbsp; 施工后<br />
        <van-uploader class="preview-cover" :preview-options="{ images: this.workShighouGQPhotoList[index] }"
          :after-read="afterConstruction(item)" :before-delete="afterConstructionDelete(item, index)"
          v-model="workShighouPhotoList[index]" multiple :max-count="9" :max-size="12 * 1024 * 1024"
          @oversize="onOversize" :deletable="
            $route.query.z_isSeePhones === 'false' ||
              item.photoVerify === 'PASS'
              ? false
              : true
          " :show-upload="
  $route.query.z_isSeePhones === 'false' ||
    item.photoVerify === 'PASS'
    ? false
    : true
" />
      </van-cell-group>
      <br />
    </div>
    <!-- 提交按钮 -->
    <div style="margin: 16px" v-if="$route.query.z_isSeePhones === 'false' ? false : true">
      <van-button round type="primary" size="large" @click="uploadComplete">上传完成</van-button>
    </div>
  </div>
</template>

<script>
import {
  ReturnWork,
  // Uploadphoto,
  Deletementouphoto,
  // Uploadsignphoto,
  Deletesignphoto,
  // Uploadsigwphoto,
  Deletesigwphoto,
  Zhaopianisok,
} from "../../api";
// import { getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
// import { compressImage } from "../config/utils";
import NavBar from "../../view/NavBar.vue";
import { ImagePreview } from "vant";
import compressor from "compressorjs";
import axios from "axios";
import global from "@/js/global";
// import compressor from "compressorjs";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      doorHeadList: [],
      doorHeadGQList: [],
      orderWorkList: [],
      workShigqianPhotoList: [],
      workShigqianGQPhotoList: [],
      workShighouPhotoList: [],
      workShighouGQPhotoList: [],
      beforeCounterPhotoList: [],
      beforeCounterGQPhotoList: [],
      afterCounterPhotoList: [],
      afterCounterGQPhotoList: [],
      guiTaiQianPhotoList: [],
      order: {},
      // isFales: true,
    };
  },
  methods: {
    onOversize(file) {
      console.log(file);
      Toast("照片过大");
    },
    clickPreviewgzx(file, datail) {
      const { index } = datail;
      if (file) {
        // 如果有attachId 说明这是已经成功上传的照片，
        // 点击预览小图时，就直接显示全屏预览图
        // 预览照片
        ImagePreview({
          images: this.imgs1,
          startPosition: index,
        });
        return;
      }
    },
    // 返回
    onClickLeft() {
      // 跳转上一级。
      this.$router.go(-1);
      // console.log(this.orderWorkImageList);
    },
    // 上传门头照
    doorHearAfterRead(file) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "上传中...",
        forbidClick: true,
      });
      file.status = "uploading";
      file.message = "上传中...";
      new compressor(file.file, {
        quality: 0.2,
        success(result) {
          const formData = new FormData();
          formData.append("path", result, result.name);
          formData.append("token", that.$route.query.z_token);
          formData.append("userid", that.$route.query.z_userid);
          formData.append("orderid", that.$route.query.id);
          let config = {
            headers: {
              //添加请求头
              "Content-Type": "multipart/form-data",
            },
          };
          axios
            .post(
              global.apiUrl + "/order/uploaddoorheadphoto",
              formData,
              config
            )
            .then((resultData) => {
              if (resultData.data.code == 1) {
                file.status = "done ";
                Toast(resultData.data.data);
              } else {
                Toast("上传失败");
                file.status = "failed";
                file.message = "上传失败";
              }
              // setTimeout(() => {
              that.getDateList();
              // }, 3000);
            })
            .catch((err) => {
              Toast("上传失败");
              that.getDateList();
              console.log(err);
            });
          // console.log(result);
        },
      });
      // if (file.file.size > 1 * 1024 * 1024) {
      //   compressImage(file.content, file.file.size).then((result) => {
      //     Uploadphoto({
      //       token: this.$route.query.z_token,
      //       userid: this.$route.query.z_userid,
      //       path: result,
      //       orderid: this.$root.$route.query.id,
      //     })
      //       .then((result) => {
      //         if (result.code == 1) {
      //           file.status = "done ";
      //           Toast(result.data);
      //         } else {
      //           Toast("上传失败");
      //           file.status = "failed";
      //           file.message = "上传失败";
      //         }
      //         // setTimeout(() => {
      //         that.getDateList();
      //         // }, 3000);
      //       })
      //       .catch((err) => {
      //         Toast("上传失败");
      //         // setTimeout(() => {
      //         that.getDateList();
      //         // }, 3000);
      //         console.log(err);
      //       });
      //   });
      // } else {
      //   Uploadphoto({
      //     token: this.$route.query.z_token,
      //     userid: this.$route.query.z_userid,
      //     path: file.content,
      //     orderid: this.$root.$route.query.id,
      //   })
      //     .then((result) => {
      //       if (result.code == 1) {
      //         file.status = "done";
      //         Toast(result.data);
      //       } else {
      //         Toast("上传失败");
      //         file.status = "failed";
      //         file.message = "上传失败";
      //       }
      //       // setTimeout(() => {
      //       that.getDateList();
      //       // }, 3000);
      //     })
      //     .catch((err) => {
      //       Toast("上传失败");
      //       // setTimeout(() => {
      //       that.getDateList();
      //       // }, 3000);
      //       console.log(err);
      //     });
      // }
    },
    //删除之后的回调
    doorHearDelete(index, detail) {
      //name.index代表图片的索引
      Dialog.confirm({
        title: "",
        message: "是否删除该照片",
      })
        .then(() => {
          if (this.$route.query.id) {
            Deletementouphoto({
              token: this.$route.query.z_token,
              userid: this.$route.query.z_userid,
              orderid: this.$route.query.id,
            })
              .then((result) => {
                if (result.code == 1) {
                  Toast("删除成功");
                  this.deleImageView(detail, index);
                } else {
                  // Toast(result.message);
                  Toast("删除失败");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Toast("删除失败");
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    // 删除照片
    deleImageView(index) {
      this.doorHeadList.splice(index, 1);
      this.doorHeadGQList.splice(index, 1);
    },
    // 上传柜台施工前照
    beforeCounterPhoto(file) {
      const that = this;
      // console.log(file);
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "上传中...",
        forbidClick: true,
      });
      // setTimeout("alert('5 asdasda!')", 1000);
      if (file.length >= 1) {
        file.forEach((item, index) => {
          // photo.push(item.content);
          new compressor(item.file, {
            quality: 0.2,
            // type: "JPEG",
            success(result) {
              const formData = new FormData();
              formData.append("counterphoto", result, result.name);
              formData.append("token", that.$route.query.z_token);
              formData.append("userid", that.$route.query.z_userid);
              formData.append("orderid", that.$route.query.id);
              formData.append("status", 0);
              let config = {
                headers: {
                  //添加请求头
                  "Content-Type": "multipart/form-data",
                },
              };
              axios
                .post(
                  global.apiUrl + "/order/uploadcounterphoto",
                  formData,
                  config
                )
                .then((resultData) => {
                  if (resultData.data.code == 1) {
                    if (file.length - 1 == index) {
                      setTimeout(() => {
                        that.getDateList();
                      }, 3000);
                    }
                  } else {
                    if (file.length - 1 == index) {
                      setTimeout(() => {
                        that.getDateList();
                      }, 3000);
                    }
                  }
                })
                .catch((err) => {
                  if (file.length - 1 == index) {
                    setTimeout(() => {
                      that.getDateList();
                    }, 3000);
                  }
                  console.log(err);
                });
            },
          });
        });
        // file.forEach((item, index) => {
        //   // photo.push(item.content);
        //   // console.log(item);
        //   if (item.file.size > 1 * 1024 * 1024) {
        //     compressImage(item.content, item.file.size).then((result) => {
        //       Uploadsignphoto({
        //         token: this.$route.query.z_token,
        //         userid: this.$route.query.z_userid,
        //         signphoto: result,
        //         status: 0,
        //         orderid: this.$root.$route.query.id,
        //       })
        //         .then((result) => {
        //           if (result.code == 1) {
        //             if (file.length - 1 == index) {
        //               setTimeout(() => {
        //                 that.getDateList();
        //               }, 3000);
        //             }
        //           } else {
        //             if (file.length - 1 == index) {
        //               setTimeout(() => {
        //                 that.getDateList();
        //               }, 3000);
        //             }
        //           }
        //         })
        //         .catch((err) => {
        //           if (file.length - 1 == index) {
        //             setTimeout(() => {
        //               that.getDateList();
        //             }, 3000);
        //           }
        //           console.log(err);
        //         });
        //     });
        //   } else {
        //     Uploadsignphoto({
        //       token: this.$route.query.z_token,
        //       userid: this.$route.query.z_userid,
        //       signphoto: item.content,
        //       status: 0,
        //       orderid: this.$root.$route.query.id,
        //     })
        //       .then((result) => {
        //         if (result.code == 1) {
        //           if (file.length - 1 == index) {
        //             setTimeout(() => {
        //               that.getDateList();
        //             }, 3000);
        //           }
        //         } else {
        //           if (file.length - 1 == index) {
        //             setTimeout(() => {
        //               that.getDateList();
        //             }, 3000);
        //           }
        //         }
        //       })
        //       .catch((err) => {
        //         if (file.length - 1 == index) {
        //           setTimeout(() => {
        //             that.getDateList();
        //           }, 3000);
        //         }
        //         console.log(err);
        //       });
        //   }
        // });
      } else {
        file.status = "uploading";
        file.message = "上传中...";
        new compressor(file.file, {
          quality: 0.2,
          success(result) {
            const formData = new FormData();
            formData.append("counterphoto", result, result.name);
            formData.append("token", that.$route.query.z_token);
            formData.append("userid", that.$route.query.z_userid);
            formData.append("orderid", that.$route.query.id);
            formData.append("status", 0);
            let config = {
              headers: {
                //添加请求头
                "Content-Type": "multipart/form-data",
              },
            };
            axios
              .post(
                global.apiUrl + "/order/uploadcounterphoto",
                formData,
                config
              )
              .then((resultData) => {
                if (resultData.data.code == 1) {
                  file.status = "done ";
                  Toast(result.data);
                } else {
                  Toast("上传失败");
                  file.status = "failed";
                  file.message = "上传失败";
                }
                // setTimeout(() => {
                that.getDateList();
                // }, 3000);
              })
              .catch((err) => {
                Toast("上传失败");
                // setTimeout(() => {
                that.getDateList();
                // }, 3000);
                console.log(err);
              });
          },
        });
        // if (file.file.size > 1 * 1024 * 1024) {
        //   compressImage(file.content, file.file.size).then((result) => {
        //     Uploadsignphoto({
        //       token: this.$route.query.z_token,
        //       userid: this.$route.query.z_userid,
        //       signphoto: result,
        //       status: 0,
        //       orderid: this.$root.$route.query.id,
        //     })
        //       .then((result) => {
        //         if (result.code == 1) {
        //           file.status = "done ";
        //           Toast(result.data);
        //         } else {
        //           Toast("上传失败");
        //           file.status = "failed";
        //           file.message = "上传失败";
        //         }
        //         // setTimeout(() => {
        //         that.getDateList();
        //         // }, 3000);
        //       })
        //       .catch((err) => {
        //         Toast("上传失败");
        //         // setTimeout(() => {
        //         that.getDateList();
        //         // }, 3000);
        //         console.log(err);
        //       });
        //   });
        // } else {
        //   Uploadsignphoto({
        //     token: this.$route.query.z_token,
        //     userid: this.$route.query.z_userid,
        //     signphoto: file.content,
        //     status: 0,
        //     orderid: this.$root.$route.query.id,
        //   })
        //     .then((result) => {
        //       if (result.code == 1) {
        //         file.status = "done ";
        //         Toast(result.data);
        //       } else {
        //         Toast("上传失败");
        //         file.status = "failed";
        //         file.message = "上传失败";
        //       }
        //       // setTimeout(() => {
        //       that.getDateList();
        //       // }, 3000);
        //     })
        //     .catch((err) => {
        //       Toast("上传失败");
        //       // setTimeout(() => {
        //       that.getDateList();
        //       // }, 3000);
        //       console.log(err);
        //     });
        // }
      }
    },
    // 删除柜台施工前照
    beforeCounterPhotoDelete(index, detail) {
      // console.log(this.beforeCounterPhotoList[detail.index]);
      // console.log(detail.index);
      Dialog.confirm({
        title: "",
        message: "是否删除该照片",
      })
        .then(() => {
          if (this.beforeCounterPhotoList[detail.index].id) {
            Deletesignphoto({
              token: this.$route.query.z_token,
              userid: this.$route.query.z_userid,
              status: "0",
              photoid: this.beforeCounterPhotoList[detail.index].id,
            })
              .then((result) => {
                if (result.code == 1) {
                  Toast("删除成功");
                  this.beforeCounterPhotoList.splice(detail.index, 1);
                  this.beforeCounterGQPhotoList.splice(detail.index, 1);
                } else {
                  Toast("删除失败");
                  // Toast(result.message);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Toast("删除失败");
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    // 上传柜台施工后照片
    afterCounterPhoto(file) {
      const that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "上传中...",
        forbidClick: true,
      });
      if (file.length >= 1) {
        file.forEach((item, index) => {
          // photo.push(item.content);
          new compressor(item.file, {
            quality: 0.2,
            success(result) {
              const formData = new FormData();
              formData.append("counterphoto", result, result.name);
              formData.append("token", that.$route.query.z_token);
              formData.append("userid", that.$route.query.z_userid);
              formData.append("orderid", that.$route.query.id);
              formData.append("status", 1);
              let config = {
                headers: {
                  //添加请求头
                  "Content-Type": "multipart/form-data",
                },
              };
              axios
                .post(
                  global.apiUrl + "/order/uploadcounterphoto",
                  formData,
                  config
                )
                .then((resultData) => {
                  if (resultData.data.code == 1) {
                    if (file.length - 1 == index) {
                      setTimeout(() => {
                        that.getDateList();
                      }, 3000);
                    }
                  } else {
                    if (file.length - 1 == index) {
                      setTimeout(() => {
                        that.getDateList();
                      }, 3000);
                    }
                  }
                })
                .catch((err) => {
                  if (file.length - 1 == index) {
                    // console.log("完成" + index);
                    setTimeout(() => {
                      that.getDateList();
                    }, 3000);
                  }
                  console.log(err);
                });
            },
          });
        });

        // file.forEach((item, index) => {
        //   if (item.file.size > 1 * 1024 * 1024) {
        //     compressImage(item.content, item.file.size).then((result) => {
        //       Uploadsignphoto({
        //         token: this.$route.query.z_token,
        //         userid: this.$route.query.z_userid,
        //         signphoto: result,
        //         status: 1,
        //         orderid: this.$root.$route.query.id,
        //       })
        //         .then((result) => {
        //           if (result.code == 1) {
        //             if (file.length - 1 == index) {
        //               setTimeout(() => {
        //                 that.getDateList();
        //               }, 3000);
        //             }
        //           } else {
        //             if (file.length - 1 == index) {
        //               setTimeout(() => {
        //                 that.getDateList();
        //               }, 3000);
        //             }
        //           }
        //         })
        //         .catch((err) => {
        //           if (file.length - 1 == index) {
        //             // console.log("完成" + index);
        //             setTimeout(() => {
        //               that.getDateList();
        //             }, 3000);
        //           }
        //           console.log(err);
        //         });
        //     });
        //   } else {
        //     Uploadsignphoto({
        //       token: this.$route.query.z_token,
        //       userid: this.$route.query.z_userid,
        //       signphoto: item.content,
        //       status: 1,
        //       orderid: this.$root.$route.query.id,
        //     })
        //       .then((result) => {
        //         if (result.code == 1) {
        //           if (file.length - 1 == index) {
        //             setTimeout(() => {
        //               that.getDateList();
        //             }, 3000);
        //           }
        //         } else {
        //           if (file.length - 1 == index) {
        //             setTimeout(() => {
        //               that.getDateList();
        //             }, 3000);
        //           }
        //         }
        //       })
        //       .catch((err) => {
        //         if (file.length - 1 == index) {
        //           // console.log("完成" + index);
        //           setTimeout(() => {
        //             that.getDateList();
        //           }, 3000);
        //         }
        //         console.log(err);
        //       });
        //   }
        // });
      } else {
        file.status = "uploading";
        file.message = "上传中...";
        new compressor(file.file, {
          quality: 0.2,
          success(result) {
            const formData = new FormData();
            formData.append("counterphoto", result, result.name);
            formData.append("token", that.$route.query.z_token);
            formData.append("userid", that.$route.query.z_userid);
            formData.append("orderid", that.$route.query.id);
            formData.append("status", 1);
            let config = {
              headers: {
                //添加请求头
                "Content-Type": "multipart/form-data",
              },
            };
            axios
              .post(
                global.apiUrl + "/order/uploadcounterphoto",
                formData,
                config
              )
              .then((resultData) => {
                if (resultData.data.code == 1) {
                  file.status = "done ";
                  Toast(resultData.data.data);
                } else {
                  Toast("上传失败");
                  file.status = "failed";
                  file.message = "上传失败";
                }
                // setTimeout(() => {
                that.getDateList();
                // }, 3000);
              })
              .catch((err) => {
                Toast("上传失败");
                // setTimeout(() => {
                that.getDateList();
                // }, 3000);
                console.log(err);
              });
          },
        });
        // if (file.file.size > 1 * 1024 * 1024) {
        //   compressImage(file.content, file.file.size).then((result) => {
        //     Uploadsignphoto({
        //       token: this.$route.query.z_token,
        //       userid: this.$route.query.z_userid,
        //       signphoto: result,
        //       status: 1,
        //       orderid: this.$root.$route.query.id,
        //     })
        //       .then((result) => {
        //         if (result.code == 1) {
        //           file.status = "done ";
        //           Toast(result.data);
        //         } else {
        //           Toast("上传失败");
        //           file.status = "failed";
        //           file.message = "上传失败";
        //         }
        //         // setTimeout(() => {
        //         that.getDateList();
        //         // }, 3000);
        //       })
        //       .catch((err) => {
        //         Toast("上传失败");
        //         // setTimeout(() => {
        //         that.getDateList();
        //         // }, 3000);
        //         console.log(err);
        //       });
        //     // photo.push(result);
        //   });
        // } else {
        //   Uploadsignphoto({
        //     token: this.$route.query.z_token,
        //     userid: this.$route.query.z_userid,
        //     signphoto: file.content,
        //     status: 1,
        //     orderid: this.$root.$route.query.id,
        //   })
        //     .then((result) => {
        //       if (result.code == 1) {
        //         file.status = "done ";
        //         Toast(result.data);
        //       } else {
        //         Toast("上传失败");
        //         file.status = "failed";
        //         file.message = "上传失败";
        //       }
        //       // setTimeout(() => {
        //       that.getDateList();
        //       // }, 3000);
        //     })
        //     .catch((err) => {
        //       Toast("上传失败");
        //       // setTimeout(() => {
        //       that.getDateList();
        //       // }, 3000);
        //       console.log(err);
        //     });
        // }
      }
    },
    // 删除柜台施工后照片
    afterCounterPhotoDelete(index, detail) {
      // console.log(this.afterCounterPhotoList[detail.index]);
      // console.log(detail.index);
      Dialog.confirm({
        title: "",
        message: "是否删除该照片",
      })
        .then(() => {
          if (this.afterCounterPhotoList[detail.index].id) {
            Deletesignphoto({
              token: this.$route.query.z_token,
              userid: this.$route.query.z_userid,
              status: "1",
              photoid: this.afterCounterPhotoList[detail.index].id,
            })
              .then((result) => {
                if (result.code == 1) {
                  Toast("删除成功");
                  this.afterCounterPhotoList.splice(detail.index, 1);
                  this.afterCounterGQPhotoList.splice(detail.index, 1);
                } else {
                  Toast("删除失败");
                  // Toast(result.message);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            Toast("删除失败");
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    // 上传工作项施工前照片
    beforeConstruction(itmeData) {
      const that = this;
      return (file) => {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: "上传中...",
          forbidClick: true,
        });
        if (file.length >= 1) {
          file.forEach((item, index) => {
            new compressor(item.file, {
              quality: 0.2,
              success(result) {
                const formData = new FormData();
                formData.append("constructionphoto", result, result.name);
                formData.append("token", that.$route.query.z_token);
                formData.append("userid", that.$route.query.z_userid);
                formData.append("orderid", that.$route.query.id);
                formData.append("status", 0);
                formData.append("workid", itmeData.id);
                let config = {
                  headers: {
                    //添加请求头
                    "Content-Type": "multipart/form-data",
                  },
                };
                axios
                  .post(
                    global.apiUrl + "/order/uploadconstructionphoto",
                    formData,
                    config
                  )
                  .then((resultData) => {
                    if (resultData.data.code == 1) {
                      if (file.length - 1 == index) {
                        setTimeout(() => {
                          that.getDateList();
                        }, 3000);
                      }
                    } else {
                      if (file.length - 1 == index) {
                        setTimeout(() => {
                          that.getDateList();
                        }, 3000);
                      }
                    }
                  })
                  .catch((err) => {
                    if (file.length - 1 == index) {
                      setTimeout(() => {
                        that.getDateList();
                      }, 3000);
                    }
                    console.log(err);
                  });
              },
            });
          });
          // file.forEach((item, index) => {
          //   if (item.file.size > 1 * 1024 * 1024) {
          //     compressImage(item.content, item.file.size).then((result) => {
          //       Uploadsigwphoto({
          //         token: that.$route.query.z_token,
          //         userid: that.$route.query.z_userid,
          //         signphoto: result,
          //         status: 0,
          //         orderid: that.$root.$route.query.id,
          //         workid: itmeData.id,
          //       })
          //         .then((result) => {
          //           if (result.code == 1) {
          //             if (file.length - 1 == index) {
          //               setTimeout(() => {
          //                 that.getDateList();
          //               }, 3000);
          //             }
          //           } else {
          //             if (file.length - 1 == index) {
          //               setTimeout(() => {
          //                 that.getDateList();
          //               }, 3000);
          //             }
          //           }
          //         })
          //         .catch((err) => {
          //           if (file.length - 1 == index) {
          //             setTimeout(() => {
          //               that.getDateList();
          //             }, 3000);
          //           }
          //           console.log(err);
          //         });
          //     });
          //   } else {
          //     Uploadsigwphoto({
          //       token: that.$route.query.z_token,
          //       userid: that.$route.query.z_userid,
          //       signphoto: item.content,
          //       status: 0,
          //       orderid: that.$root.$route.query.id,
          //       workid: itmeData.id,
          //     })
          //       .then((result) => {
          //         if (result.code == 1) {
          //           if (file.length - 1 == index) {
          //             setTimeout(() => {
          //               that.getDateList();
          //             }, 3000);
          //           }
          //         } else {
          //           if (file.length - 1 == index) {
          //             setTimeout(() => {
          //               that.getDateList();
          //             }, 3000);
          //           }
          //         }
          //       })
          //       .catch((err) => {
          //         if (file.length - 1 == index) {
          //           setTimeout(() => {
          //             that.getDateList();
          //           }, 3000);
          //         }
          //         console.log(err);
          //       });
          //   }
          // });
        } else {
          file.status = "uploading";
          file.message = "上传中...";
          // photo.push(file.content);
          new compressor(file.file, {
            quality: 0.2,
            success(result) {
              const formData = new FormData();
              formData.append("constructionphoto", result, result.name);
              formData.append("token", that.$route.query.z_token);
              formData.append("userid", that.$route.query.z_userid);
              formData.append("orderid", that.$route.query.id);
              formData.append("status", 0);
              formData.append("workid", itmeData.id);
              let config = {
                headers: {
                  //添加请求头
                  "Content-Type": "multipart/form-data",
                },
              };
              axios
                .post(
                  global.apiUrl + "/order/uploadconstructionphoto",
                  formData,
                  config
                )
                .then((resultData) => {
                  if (resultData.data.code == 1) {
                    file.status = "done ";
                    Toast(result.data.data);
                  } else {
                    Toast("图片过大,上传失败");
                    file.status = "failed";
                    file.message = "上传失败";
                  }
                  // setTimeout(() => {
                  that.getDateList();
                  // }, 3000);
                })
                .catch((err) => {
                  Toast("上传失败");
                  // setTimeout(() => {
                  that.getDateList();
                  // }, 3000);
                  console.log(err);
                });
            },
          });
          // if (file.file.size > 1 * 1024 * 1024) {
          //   compressImage(file.content, file.file.size).then((result) => {
          //     Uploadsigwphoto({
          //       token: that.$route.query.z_token,
          //       userid: that.$route.query.z_userid,
          //       signphoto: result,
          //       status: 0,
          //       orderid: that.$root.$route.query.id,
          //       workid: itmeData.id,
          //     })
          //       .then((result) => {
          //         if (result.code == 1) {
          //           file.status = "done ";
          //           Toast(result.data);
          //         } else {
          //           Toast("图片过大,上传失败");
          //           file.status = "failed";
          //           file.message = "上传失败";
          //         }
          //         // setTimeout(() => {
          //         that.getDateList();
          //         // }, 3000);
          //       })
          //       .catch((err) => {
          //         Toast("上传失败");
          //         // setTimeout(() => {
          //         that.getDateList();
          //         // }, 3000);
          //         console.log(err);
          //       });
          //     // photo.push(result);
          //   });
          // } else {
          //   Uploadsigwphoto({
          //     token: that.$route.query.z_token,
          //     userid: that.$route.query.z_userid,
          //     signphoto: file.content,
          //     status: 0,
          //     orderid: that.$root.$route.query.id,
          //     workid: itmeData.id,
          //   })
          //     .then((result) => {
          //       if (result.code == 1) {
          //         file.status = "done ";
          //         Toast(result.data);
          //       } else {
          //         Toast("图片过大,上传失败");
          //         file.status = "failed";
          //         file.message = "上传失败";
          //       }
          //       // setTimeout(() => {
          //       that.getDateList();
          //       // }, 3000);
          //     })
          //     .catch((err) => {
          //       Toast("上传失败");
          //       // setTimeout(() => {
          //       that.getDateList();
          //       // }, 3000);
          //       console.log(err);
          //     });
          // }
        }
        // console.log(photo);
      };
    },
    // 删除工作项施工前照片
    beforeConstructionDelete(item, index) {
      return (detail, details) => {
        Dialog.confirm({
          title: "",
          message: "是否删除该照片",
        })
          .then(() => {
            if (this.workShigqianPhotoList[index][details.index].url) {
              Deletesigwphoto({
                token: this.$route.query.z_token,
                userid: this.$route.query.z_userid,
                status: "0",
                path: this.workShigqianPhotoList[index][details.index].url,
                workid: item.id,
                orderid: this.$root.$route.query.id,
              })
                .then((result) => {
                  if (result.code == 1) {
                    Toast("删除成功");
                    this.workShigqianPhotoList[index].splice(details.index, 1);
                    this.workShigqianGQPhotoList[index].splice(
                      details.index,
                      1
                    );
                  } else {
                    Toast("删除失败");
                    // Toast(result.message);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              Toast("删除失败");
            }
          })
          .catch(() => {
            // on cancel
          });
      };
    },
    // 上传工作项施工后照片
    afterConstruction(itmeData) {
      const that = this;
      return (file) => {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: "上传中...",
          forbidClick: true,
        });
        if (file.length >= 1) {
          file.forEach((item, index) => {
            new compressor(item.file, {
              quality: 0.2,
              success(result) {
                const formData = new FormData();
                formData.append("constructionphoto", result, result.name);
                formData.append("token", that.$route.query.z_token);
                formData.append("userid", that.$route.query.z_userid);
                formData.append("orderid", that.$route.query.id);
                formData.append("status", 1);
                formData.append("workid", itmeData.id);
                let config = {
                  headers: {
                    //添加请求头
                    "Content-Type": "multipart/form-data",
                  },
                };
                axios
                  .post(
                    global.apiUrl + "/order/uploadconstructionphoto",
                    formData,
                    config
                  )
                  .then((resultData) => {
                    if (resultData.data.code == 1) {
                      if (file.length - 1 == index) {
                        setTimeout(() => {
                          that.getDateList();
                        }, 3000);
                      }
                    } else {
                      if (file.length - 1 == index) {
                        setTimeout(() => {
                          that.getDateList();
                        }, 3000);
                      }
                    }
                  })
                  .catch((err) => {
                    if (file.length - 1 == index) {
                      setTimeout(() => {
                        that.getDateList();
                      }, 3000);
                    }
                    console.log(err);
                  });
              },
            });
          });
          // file.forEach((item, index) => {
          //   if (item.file.size > 1 * 1024 * 1024) {
          //     compressImage(item.content, item.file.size).then((result) => {
          //       Uploadsigwphoto({
          //         token: that.$route.query.z_token,
          //         userid: that.$route.query.z_userid,
          //         signphoto: result,
          //         status: 1,
          //         orderid: that.$root.$route.query.id,
          //         workid: itmeData.id,
          //       })
          //         .then((result) => {
          //           if (result.code == 1) {
          //             if (file.length - 1 == index) {
          //               setTimeout(() => {
          //                 that.getDateList();
          //               }, 3000);
          //             }
          //           } else {
          //             if (file.length - 1 == index) {
          //               setTimeout(() => {
          //                 that.getDateList();
          //               }, 3000);
          //             }
          //           }
          //         })
          //         .catch((err) => {
          //           if (file.length - 1 == index) {
          //             setTimeout(() => {
          //               that.getDateList();
          //             }, 3000);
          //           }
          //           console.log(err);
          //         });
          //     });
          //   } else {
          //     Uploadsigwphoto({
          //       token: that.$route.query.z_token,
          //       userid: that.$route.query.z_userid,
          //       signphoto: item.content,
          //       status: 1,
          //       orderid: that.$root.$route.query.id,
          //       workid: itmeData.id,
          //     })
          //       .then((result) => {
          //         if (result.code == 1) {
          //           if (file.length - 1 == index) {
          //             setTimeout(() => {
          //               that.getDateList();
          //             }, 3000);
          //           }
          //         } else {
          //           if (file.length - 1 == index) {
          //             setTimeout(() => {
          //               that.getDateList();
          //             }, 3000);
          //           }
          //         }
          //       })
          //       .catch((err) => {
          //         if (file.length - 1 == index) {
          //           setTimeout(() => {
          //             that.getDateList();
          //           }, 3000);
          //         }
          //         console.log(err);
          //       });
          //   }
          // });
        } else {
          file.status = "uploading";
          file.message = "上传中...";
          new compressor(file.file, {
            quality: 0.2,
            success(result) {
              const formData = new FormData();
              formData.append("constructionphoto", result, result.name);
              formData.append("token", that.$route.query.z_token);
              formData.append("userid", that.$route.query.z_userid);
              formData.append("orderid", that.$route.query.id);
              formData.append("status", 1);
              formData.append("workid", itmeData.id);
              let config = {
                headers: {
                  //添加请求头
                  "Content-Type": "multipart/form-data",
                },
              };
              axios
                .post(
                  global.apiUrl + "/order/uploadconstructionphoto",
                  formData,
                  config
                )
                .then((resultData) => {
                  if (resultData.data.code == 1) {
                    file.status = "done ";
                    Toast(resultData.data.data);
                  } else {
                    Toast("上传失败");
                    file.status = "failed";
                    file.message = "上传失败";
                  }
                  // setTimeout(() => {
                  that.getDateList();
                  // }, 3000);
                })
                .catch((err) => {
                  Toast("上传失败");
                  // setTimeout(() => {
                  that.getDateList();
                  // }, 3000);
                  console.log(err);
                });
            },
          });
          // if (file.file.size > 1 * 1024 * 1024) {
          //   compressImage(file.content, file.file.size).then((result) => {
          //     Uploadsigwphoto({
          //       token: that.$route.query.z_token,
          //       userid: that.$route.query.z_userid,
          //       signphoto: result,
          //       status: 1,
          //       orderid: that.$root.$route.query.id,
          //       workid: itmeData.id,
          //     })
          //       .then((result) => {
          //         if (result.code == 1) {
          //           file.status = "done ";
          //           Toast(result.data);
          //         } else {
          //           Toast("上传失败");
          //           file.status = "failed";
          //           file.message = "上传失败";
          //         }
          //         // setTimeout(() => {
          //         that.getDateList();
          //         // }, 3000);
          //       })
          //       .catch((err) => {
          //         Toast("上传失败");
          //         // setTimeout(() => {
          //         that.getDateList();
          //         // }, 3000);
          //         console.log(err);
          //       });
          //     // photo.push(result);
          //   });
          // } else {
          //   Uploadsigwphoto({
          //     token: that.$route.query.z_token,
          //     userid: that.$route.query.z_userid,
          //     signphoto: file.content,
          //     status: 1,
          //     orderid: that.$root.$route.query.id,
          //     workid: itmeData.id,
          //   })
          //     .then((result) => {
          //       if (result.code == 1) {
          //         file.status = "done ";
          //         Toast(result.data);
          //       } else {
          //         Toast("上传失败");
          //         file.status = "failed";
          //         file.message = "上传失败";
          //       }
          //       // setTimeout(() => {
          //       that.getDateList();
          //       // }, 3000);
          //     })
          //     .catch((err) => {
          //       Toast("上传失败");
          //       // setTimeout(() => {
          //       that.getDateList();
          //       // }, 3000);
          //       console.log(err);
          //     });
          // }
        }
      };
    },
    // 删除工作项施工后照片
    afterConstructionDelete(item, index) {
      return (detail, details) => {
        // console.log(this.beforeCounterPhotoList[detail.index]);
        // console.log(item);
        // console.log(detail);
        // console.log(details.index);
        // console.log(detail);
        Dialog.confirm({
          title: "",
          message: "是否删除该照片",
        })
          .then(() => {
            if (this.workShighouPhotoList[index][details.index].url) {
              Deletesigwphoto({
                token: this.$route.query.z_token,
                userid: this.$route.query.z_userid,
                status: "1",
                path: this.workShighouPhotoList[index][details.index].url,
                orderid: this.$root.$route.query.id,
                workid: item.id,
              })
                .then((result) => {
                  if (result.code == 1) {
                    Toast("删除成功");
                    this.workShighouPhotoList[index].splice(details.index, 1);
                    this.workShighouGQPhotoList[index].splice(details.index, 1);
                  } else {
                    Toast("删除失败");
                    // Toast(result.message);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              Toast("删除失败");
            }
          })
          .catch(() => {
            // on cancel
          });
      };
    },
    // 上传完成
    uploadComplete() {
      if (this.doorHeadList.length <= 0) {
        Toast("请上传门头照");
        return;
      }

      if (this.beforeCounterPhotoList.length <= 0) {
        Toast("请上传柜台全景照施工前照片");
        return;
      }

      if (this.afterCounterPhotoList.length <= 0) {
        Toast("请上传柜台全景照施工后照片");
        return;
      }

      for (let index = 0; index < this.workShigqianPhotoList.length; index++) {
        if (this.workShigqianPhotoList[index].length <= 0) {
          Toast("请上传第" + (index + 1) + "工作项施工前照片");
          return;
        }
      }

      for (let index = 0; index < this.workShighouPhotoList.length; index++) {
        if (this.workShighouPhotoList[index].length <= 0) {
          Toast("请上传第" + (index + 1) + "工作项施工后照片");
          return;
        }
      }
      Dialog.confirm({
        title: "",
        message: "是否确定照片已全部上传完成",
      })
        .then(() => {
          Zhaopianisok({
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
            isok: "1",
            orderid: this.$route.query.id,
            sta: this.$route.query.z_status,
          })
            .then((result) => {
              if (result.code == 1) {
                console.log(result);
                this.$router.go(-1);
                Toast("全部照片上传完成");
              } else {
                Toast(result.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    getDateList() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      ReturnWork({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        orderid: this.$route.query.id,
        // orderid: "95683",
      })
        .then((result) => {
          if (result.code == 1) {
            // console.log(
            //   this.doorHeadList.length + "，" + result.data.menTouPhoto.path
            // );
            // 门头照
            this.doorHeadList = [];
            this.doorHeadGQList = [];
            if (result.data.menTouPhoto.path != null) {
              this.doorHeadList.push({ url: result.data.menTouPhoto.path });
              this.doorHeadGQList.push(result.data.menTouPhoto.name);
            }
            this.orderWorkList = result.data.orderWorkList;
            //施工前
            this.beforeCounterPhotoList = [];
            this.beforeCounterGQPhotoList = [];
            this.afterCounterPhotoList = [];
            this.afterCounterGQPhotoList = [];
            if (result.data.guiTaiQianPhotoList.length > 0) {
              result.data.guiTaiQianPhotoList.forEach((item) => {
                if (item.path != null) {
                  let parent = { id: item.id, url: item.path };
                  this.beforeCounterPhotoList.push(parent);
                  this.beforeCounterGQPhotoList.push(item.name);
                }
              });
              console.log(this.beforeCounterPhotoList);
            }
            //门头照施工后
            if (result.data.guiTaiHouPhotoList.length > 0) {
              result.data.guiTaiHouPhotoList.forEach((item) => {
                if (item.path != null) {
                  let parent = { id: item.id, url: item.path };
                  this.afterCounterPhotoList.push(parent);
                  this.afterCounterGQPhotoList.push(item.name);
                }
              });
              console.log(this.beforeCounterPhotoList);
            }
            this.workShigqianPhotoList = [];
            this.workShigqianGQPhotoList = [];
            this.workShighouPhotoList = [];
            this.workShighouGQPhotoList = [];
            // 循环创建iamgeList
            if (result.data.orderWorkList.length > 0) {
              Object.keys(result.data.orderWorkList).forEach((item) => {
                // newArr.push(arr[item]);
                // console.log(result.data.orderWorkList[item].workShighouPhotoList);
                this.workShigqianPhotoList.push(
                  result.data.orderWorkList[item].workShigqianPhotoList
                );
                this.workShigqianGQPhotoList.push(
                  result.data.orderWorkList[item].workShigqianSLPhotoList
                );
                this.workShighouPhotoList.push(
                  result.data.orderWorkList[item].workShighouPhotoList
                );
                this.workShighouGQPhotoList.push(
                  result.data.orderWorkList[item].workShighouSLPhotoList
                );
              });
            }
            // console.log(this.doorHeadList);
            // 门头照柜台照拒绝理由
            this.order = result.data.order;
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getDateList();
    // console.log(this.$route.query.z_status);
  },
};
</script>

<style>
/* 全局属性 */
:root {
  --van-uploader-delete-icon-size: 26px;
}

.preview-cover {
  margin: 5px 0 0 10px;
}
</style>
