<template>
    <div class="articleList">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <div v-show="total > 0">
          <span
            style="
              text-align: center;
              display: block;
              font-size: 14px;
              height: 18px;
              padding-top: 5px;
              padding-bottom: 5px;
              color: rgb(151, 152, 154);
            "
            >{{ menuName }}:{{ total }}</span
          >
        </div>
        <van-cell v-for="item in list" :key="item" @click="jump(item)">
          <div style="display: inline-block; float: right;">
            <van-image
            style="display: block;"
            width="100"
            height="100"
            :src=item.path
            />
          <div style="height: 10px"></div>
          <div style="height:5px;" v-if="state != 'PASS' && item.verify41_62_2_ != true">
            <van-button round style="float: right;" type="primary" size="small" @click.stop="editApproval(item)">编辑</van-button>
            <van-button round style="margin-right: 1px; float: right;" type="danger" size="small" @click.stop="deleteApproval(item)">删除</van-button>
          </div>
          </div>
          <div style="display: inline-block;">
            <p style="display: block;">费用编号：{{ item.feiyong19_62_2_ + " " }}</p>
            <p style="display: block;">订单编号：{{ item.code57_1_ + " " }}</p>
            <p style="display: block;">报销金额：{{ item.money62_2_ + " " }}</p>
            <p style="display: block;">费用类型：{{ item.name4_0_ + " " }}</p>
            <p style="display: inline-block;">审核状态：</p>
            <van-tag
             plain
              type="primary"
              v-if="item.verify43_62_2_ == 'PASS'"
              style="display: inline-block;"
            >
              审核通过
            </van-tag>
            <van-tag
              plain
              type="primary"
              v-if="item.verify41_62_2_ === true && state === 'TODO'"
              >区审通过</van-tag
            >
            <van-tag
              plain
              type="primary"
              v-if="item.verify41_62_2_ === false && state === 'TODO'" 
              >待审核</van-tag
            >
            <van-tag
              plain
              type="danger"
              v-if="item.pre31_62_2_ && state === 'DENY'"
              >已拒绝</van-tag
            >
            <!-- <p
              v-if="item.verify43_62_2_ == 'TODO'"
              style="display: inline-block; color: red"
            >
              未审核
            </p>
            <p
              v-if="item.verify43_62_2_ == 'DENY'"
              style="display: inline-block; color: red"
            >
              已拒绝
            </p>
            <van-tag
              plain
              type="danger"
              >审核拒绝</van-tag
            >
            <van-tag
              plain
              type="primary"
              >区审通过</van-tag
            >
            <van-tag plain type="primary"
              >财务通过</van-tag
            > -->
          </div>
            <!-- <van-tag plain type="danger">区审拒绝</van-tag> -->
        
          <!-- <div style="float: right;">
           
          </div> -->
        </van-cell>
      </van-list>
      </van-pull-refresh>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  import { OrderfeiyongGetdenyfeiyong, OrderDeletefeiyong} from "../../api";
  import { Toast } from "_vant@3.4.5@vant";
  
  export default {
    props: {
      channel: {
        type: Object,
        required: true,
      },
    },
    setup(props, { emit }) {
      const list = ref([]);
      const loading = ref(false);
      const finished = ref(false);
      const refreshing = ref(false);
      const error = ref(false);
      const pageNum = ref(1);
      const userid = ref(props.channel.z_userid);
      const token = ref(props.channel.z_token);
      const menuName = ref(props.channel.name);
      const name = ref(props.channel.z_name);
      const phone = ref(props.channel.z_phone);
      const state = ref(props.channel.state);
      const total = ref("");
      const onLoad = () => {
        Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
        OrderfeiyongGetdenyfeiyong({
          page: pageNum.value,
          size: 100,
          userid: props.channel.z_userid,
          token: props.channel.z_token,
          verifystatus: props.channel.state,
        })
          .then((result) => {
            Toast.clear();
            if (refreshing.value) {
              list.value = [];
              refreshing.value = false;
            }
            list.value.push(...result.data.feiYongVoList.list);
            total.value = result.data.feiYongVoList.total;
            emit("changeComponentData", props.channel.z_active, total.value);
            loading.value = false;
            if (result.data.hasNextPage) {
              pageNum.value = result.data.nextPage;
            } else {
              finished.value = true;
            }
          })
          .catch((err) => {
            Toast.clear();
            console.log(err);
            error.value = true;
            loading.value = false;
            finished.value = true;
          });
      };
      const onRefresh = () => {
        // 清空列表数据
        finished.value = false;
        pageNum.value = 1;
        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        loading.value = true;
        onLoad();
      };
      return {
        list,
        onLoad,
        onRefresh,
        loading,
        refreshing,
        finished,
        pageNum,
        userid,
        token,
        menuName,
        name,
        phone,
        state,
        total,
      };
    },
    data() {
      return {};
    },
    methods: {
      jump(item) {
        console.log(item);
        // Toast(1111);
      },
      editApproval(item) { 
        // console.log(item);
        let listData = item;
        listData.z_isEdit = "1";
        listData.id = item.id62_2_;
        listData.z_token = this.token;
        listData.z_userid = this.userid;
        console.log(listData);
        this.$router.push({
          path: "/ReimburstMentEdit",
          query: listData,
        });
      },
      deleteApproval(item) { 
        this.$dialog.confirm({
        title: "",
        message: "是否删除该条费用报销",
      })
        .then(() => {
          OrderDeletefeiyong({
            userid: this.userid,
            token: this.token,
            feiyongid: item.id62_2_,
          })
            .then(() => {
              this.list = [];
              Toast("删除成功");
              this.onRefresh();
            })
            .catch((err) => {
              console.log(err);
              Toast("网络错误");
            });
        })
        .catch(() => {
          // on cancel
        });
      },
      formatDate(date) {
        // 获取单元格数据
        let data = date;
        if (data === "") {
          return "";
        } else {
          let dt = new Date(data);
          let month =
            dt.getMonth() + 1 < 10
              ? "0" + (dt.getMonth() + 1)
              : "" + (dt.getMonth() + 1);
          let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
          return (
            dt.getFullYear() +
            "-" +
            // (dt.getMonth() + 1) +
            month +
            "-" +
            // dt.getDate() +
            day +
            " "
            // +
            // dt.getHours() +
            // ":" +
            // dt.getMinutes() +
            // ":" +
            // dt.getSeconds()
          );
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .projectName {
    color: #02a7f0;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }
  .spanTemplate {
    text-align: left;
    display: block;
    font-size: 14px;
    padding-left: 108px;
    /* height: 18px;
    padding-top: 5px;
    padding-bottom: 5px; */
    color: rgb(151, 152, 154);
  }
  </style>
  