<template>
  <div id="NavBar">
    <!-- @click-left="onClickLeft" -->
    <!-- <div class="nav-top-placeholder"></div> -->
    <van-nav-bar
      :title="title"
      :left-arrow="z_left_arrow"
      @click-left="onClickLeft"
      safe-area-inset-top
      fixed
    />
  </div>
</template>

<script>
export default {
  // title：用来显示导航栏的title，isleftarrow用来显示导航栏的左侧返回箭头
  props: ["title", "z_left_arrow"],
  methods: {
    onClickLeft() {
      // 点击回退的时候当做地址回退
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.van-nav-bar {
  background-color: #325cfe;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
/* #NavBar {
  position: fixed;
  top: 0;
  left: 0;
  height: 46px;
  line-height: 46px;
  width: 100%;
  z-index: 100;
  background-color: white;
} */
/* .nav-top-placeholder {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  height: 44px;
  background-color: yellow;
} */
/* 设置导航栏的渐变色 */
/* .van-nav-bar {
  font-size: 18px !important;

  background: linear-gradient(to right, #ff2e29, #fe6e49) !important;
  background: -webkit-linear-gradient(to right, #ff2e29, #fe6e49) !important;
  border: 0;
} */
/* 去除导航栏底部的白色横线 */
/* .van-hairline--bottom::after {
  border-bottom-width: 0px !important;
} */
</style>
