<template>
  <!-- 顶部导航 -->
  <!-- 表单内容：驻点拒单 -->
  <div class="main" style="padding-top: 66px">
    <!-- <van-sticky :offset-top="50"> -->
    <van-cell-group inset>
      <van-steps style="padding-bottom: 500px;" direction="vertical" :active="active">
        <van-step>
          <van-button
            round="true"
            @click="goSingin"
            :type="active >= 0 ? 'success' : 'default'"
            :disabled="active === 0 ? false : true"
            >签到</van-button
          >
          <p v-if="signinTime != null ? true : false">
            {{ formatDate(signinTime) }}
          </p>
          <div v-if="signinAddress != null ? true : false">
            <van-icon name="location" /> {{ signinAddress }}
          </div>
          <van-notice-bar
            left-icon="volume-o"
            text="签到时需提前向店员说明具体情况"
          />
        </van-step>
        <van-step>
          <van-button
            @click="photograph"
            :type="active >= 1 ? 'success' : 'default'"
            :disabled="active === 1 ? false : true"
            round="true"
            >拍照上传</van-button
          >
          <p></p>
          <van-notice-bar
            left-icon="volume-o"
            text="请根据订单工作内容上传施工照片"
          />
        </van-step>
        <van-step>
          <van-button
            @click="signAndUpload"
            :type="active >= 2 ? 'success' : 'default'"
            :disabled="active === 2 ? false : true"
            round="true"
            >签单上传</van-button
          >
          <p></p>
          <van-notice-bar
            left-icon="volume-o"
            text="施工完毕请及时上传签单照片"
          />
        </van-step>
        <van-step>
          <van-button
            @click="sthsigned"
            :type="active >= 3 ? 'success' : 'default'"
            :disabled="active === 3 ? false : true"
            round="true"
            >签出</van-button
          >
          <p v-if="signUpLastTime != null ? true : false">
            {{ formatDate(signUpLastTime) }}
          </p>
          <div v-if="signUpLastAddress != null ? true : false">
            <van-icon name="location" /> {{ signUpLastAddress }}
          </div>
          <van-notice-bar
            left-icon="volume-o"
            text="签出时请检查现场情况方可离场"
          />
        </van-step>
        <van-step>
          <van-button
            @click="toBeFinished"
            :type="active >= 4 ? 'success' : 'default'"
            :disabled="active === 4 ? false : true"
            round="true"
            >完工</van-button
          >
          <p></p>
        </van-step>
      </van-steps>
    </van-cell-group>
    <NavBar fixed z_left_arrow title="施工流程" @click-left="onClickLeft" />
  <!-- </van-sticky> -->
  </div>
</template>

<script>
import {
  OrderExecutionorder,
  OrderSignin,
  OrderDingdanup,
  OrderWangong,
} from "../../api";
import { Toast } from "vant";
import NavBar from "../../view/NavBar.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      active: -1,
      signinTime: "",
      signinAddress: "",
      status: 0,
      signUpLastTime: "",
      signUpLastAddress: "",
      saveZ_token: "",
      saveZ_userid: "",
    };
  },
  methods: {
    // 返回
    onClickLeft() {
      // 跳转上一级。
      this.$router.go(-1);
    },
    initMap(signStatus) {
      if (signStatus === 0 || signStatus === 1) {
        Toast.loading({
          message: "定位中...",
          forbidClick: true,
          duration: 0,
        });
      }
      AMapLoader.load({
        key: "32822d1c979836a2a762cf592494124f", // 申请好的Web端开发者Key，首次调用 load 时必填
        // jscode: "f4267f462a22633818706591633c684e",
        // version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.CitySearch", "AMap.Geolocation", "AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        // Loca: { version: "2.0" },
      })
        .then((AMap) => {
          let that = this;
          AMap.plugin("AMap.Geolocation", function () {
            var geolocation = new AMap.Geolocation({
              enableHighAccuracy: true, // 是否使用高精度定位，默认:true
              timeout: 10000, // 超过10秒后停止定位，默认：无穷大
              maximumAge: 0, // 定位结果缓存0毫秒，默认：0
              convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
              showButton: true, // 显示定位按钮，默认：true
              buttonPosition: "LB", // 定位按钮停靠位置，默认：'LB'，左下角
              buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
              showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
              showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
              panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
              zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            });

            geolocation.getCurrentPosition(function (status, result) {
              if (status == "complete") {
                onComplete(result);
              } else {
                onError(result);
              }
            });

            function onComplete(data) {
              // document.getElementById('status').innerHTML='定位成功'
              console.log(data);
              var str = [];
              if (signStatus == 0) {
                that.OrderIn(
                  that.bd_encrypt(data.position.lng, data.position.lat).bd_lng,
                  that.bd_encrypt(data.position.lng, data.position.lat).bd_lat,
                  data.formattedAddress
                );
              }
              if (signStatus == 1) {
                that.OrderOut(
                  that.bd_encrypt(data.position.lng, data.position.lat).bd_lng,
                  that.bd_encrypt(data.position.lng, data.position.lat).bd_lat,
                  data.formattedAddress
                );
              }
              str.push("定位结果：" + data.position);
              str.push("定位类别：" + data.location_type);
              str.push("定位地址：" + data.formattedAddress);
              if (data.accuracy) {
                str.push("精度：" + data.accuracy + " 米");
              }
              //如为IP精确定位结果则没有精度信息
              str.push("是否经过偏移：" + (data.isConverted ? "是" : "否"));
              // console.log(str);
              // alert(str)
              // Toast("定位成功" + str);
            }
            function onError(data) {
              // 定位出错
              if (signStatus == 0) {
                that.OrderIn(0, 0, that.$route.query.place);
              }
              if (signStatus == 1) {
                that.OrderOut(0, 0, that.$route.query.place);
              }
              var str = "失败原因排查信息:" + data.message;
              // Toast("定位失败" + str);
              console.log("定位失败" + str);
              // Toast.loading({
              //   message: str,
              //   forbidClick: true,
              //   duration: 0,
              // });
            }
          });
        })
        .catch((e) => {
          // 定位出错
          if (signStatus == 0) {
            this.OrderIn(0, 0, this.$route.query.place);
          }
          if (signStatus == 1) {
            this.OrderOut(0, 0, this.$route.query.place);
          }
          console.log(e);
        });
    },
    //高德坐标转百度（传入经度、纬度）
    bd_encrypt(gg_lng, gg_lat) {
      var X_PI = (Math.PI * 3000.0) / 180.0;
      var x = gg_lng,
        y = gg_lat;
      var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
      var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
      var bd_lng = z * Math.cos(theta) + 0.0065;
      var bd_lat = z * Math.sin(theta) + 0.006;
      return {
        bd_lat: bd_lat,
        bd_lng: bd_lng,
      };
    },
    getdate() {
      var date = new Date();

      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    },
    // 时间戳转换
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " "
          // +
          // dt.getHours() +
          // ":" +
          // dt.getMinutes() +
          // ":" +
          // dt.getSeconds()
        );
      }
    },
    //签到
    goSingin() {
      this.initMap(0);
    },
    // 签出
    sthsigned() {
      this.initMap(1);
    },
    OrderIn(positionLng, positionLat, currentAddress) {
      console.log(positionLng + "," + positionLat + "," + currentAddress);
      OrderSignin({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
        // userid: "24",
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        constructionnum: "1",
        signinaddress: currentAddress,
        //签到维度
        signinlat: positionLat,
        //签到经度
        signinlng: positionLng,
        signintime: this.getdate(),
        orderid: this.$route.query.id,
      })
        .then((result) => {
          Toast.clear();
          if (result.code == 1) {
            console.log(result);
            Toast("签到成功");
            this.OrderExe();
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
        });
    },
    OrderOut(positionLng, positionLat, currentAddress) {
      console.log(positionLng + "," + positionLat + "," + currentAddress);
      OrderDingdanup({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
        // userid: "24",
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        constructionnum: "1",
        signupaddress: currentAddress,
        //签到维度
        signuplat: positionLat,
        //签到经度
        signuplng: positionLng,
        signuptime: this.getdate(),
        orderid: this.$route.query.id,
      })
        .then((result) => {
          Toast.clear();
          if (result.code == 1) {
            console.log(result);
            Toast("签出成功");
            this.OrderExe();
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
        });
    },
    // 拍照
    photograph() {
      // let query = this.$route.query;
      // query.z_isSeePhones = "true";
      // query.z_status = this.status;
      // this.$router.push({
      //   path: "/UploadJobPic",
      //   // query: this.$route.query,
      //   query: query,
      // });
      // Toast(this.$route.query.id +'你好'+ this.status)
      let s = this.status+'';
      // console.log(this.$route.query.id);
      if (this.$route.query.id) { 
        let que = {"orderStatus":s,"ID":this.$route.query.id};
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.uploadJobPic.postMessage({
            // title: this.$route.query.id, //vue给iOS传值
            title: que, //vue给iOS传值
          });
        } else {
          window.android.startActivity(this.$route.query.id,this.status);
          // window.android.onFinish();
        }
      }
    },
    // 签单上传
    signAndUpload() {
      let query = this.$route.query;
      query.z_status = this.status;
      this.$router.push({
        path: "/UploadSignPic",
        // query: this.$route.query,
        query: query,
      });
    },
    // 完工
    toBeFinished() {
      OrderWangong({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
        // userid: "24",
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        wangong: "1",
        orderid: this.$route.query.id,
      })
        .then((result) => {
          if (result.code == 1) {
            console.log(result);
            this.$router.go(-2);
            Toast("完工");
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    OrderExe() {
      // var z_params = {}
      // if (this.$route.query.z_token) {
      //   z_params = {
      //     token: this.$route.query.z_token,
      //     userid: this.$route.query.z_userid,
      //     orderid: this.$route.query.id,
      //   }
      // } else { 
      //   z_params = {
      //     token: this.saveZ_token,
      //     userid: this.saveZ_userid,
      //     orderid: this.$route.query.id,
      //   }
      // }
      OrderExecutionorder({
          token: this.$route.query.z_token,
          userid: this.$route.query.z_userid,
          orderid: this.$route.query.id,
        })
        .then((result) => {
          if (result.code == 1) {
            // console.log(111 + result);
            // this.active = result.data.executionStatus;
            // console.log(11 + result.data.executionStatus);
            this.active = parseInt(result.data.executionStatus);
            // this.active = 1;
            this.signinTime = result.data.order.signInLastTime;
            this.signinAddress = result.data.orderSign.signInAddress;
            this.status = parseInt(result.data.status);
            this.signUpLastTime = result.data.order.signUpLastTime;
            this.signUpLastAddress = result.data.orderSign.signUpAddress;
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    // Toast(111111111);
    //初始化完成进行地图初始化
    this.saveZ_userid = localStorage.getItem("z_userid"); 
    this.saveZ_token = localStorage.getItem("z_token"); //获取指定key本地存储的值
    // console.log('获取信息');
    // console.log(this.saveZ_userid,this.saveZ_token);
    window._AMapSecurityConfig = {
      securityJsCode: "f4267f462a22633818706591633c684e",
    };
    window.OrderExe=this.OrderExe;
    this.initMap(); // 调用获取地理位置
    this.OrderExe();
  },
};
</script>
<style></style>
