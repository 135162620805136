<template>
  <div class="mian" style="padding-top: 46px">
    <!-- 顶部导航 -->
    <van-nav-bar
      :title="$route.query.z_title"
      left-arrow
      @click-left="onClickBack"
      fixed
      safe-area-inset-top
    ></van-nav-bar>
    <br />
    <span
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: 70%;
      "
    >
      敬请期待
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onClickBack() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.getDataFormVue.postMessage({
          title: "333", //vue给iOS传值
        });
      } else {
        window.android.onFinish();
      }
    },
  },
};
</script>

<style scoped>
div .main {
  width: 100%;
  height: max-content;
  position: absolute;
  background-color: #f8f8f8;
}
.van-nav-bar {
  background-color: #325cfe;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>
