<template>
  <!-- 顶部导航 -->
  <!-- <NavBar z_left_arrow title="费用报销录入" @click-left="onClickLeft" /> -->
  <NavBar z_left_arrow :title="$route.query.z_isEdit === '1' ? '费用报销编辑': '费用报销录入'" @click-left="onClickLeft" />
  
  <!-- 表单内容：费用报销录入 -->
  <div class="main" style="padding-top: 46px">
    <van-notice-bar
      left-icon="volume-o"
      :text="
        $route.query.z_isEdit === '1' && $route.query.pre31_62_2_
          ? $route.query.pre31_62_2_
          : '请根据要求填写并上传报销内容与凭据(仅支持上传一张照片)'
      "
    />
    <br />
    <!-- 费用类型 -->
    <van-cell-group inset>
      <van-field
        v-model="result"
        is-link
        readonly
        label="选择费用类型"
        placeholder="请选择"
        @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
    </van-cell-group>
    <br />

    <!-- 报销金额 -->
    <van-cell-group inset>
      <van-field
        v-model="number"
        type="number"
        label="报销金额"
        maxlength="7"
      />
    </van-cell-group>
    <br />

    <!-- 报销附件上传  :after-read="afterRead" -->
    <!-- <div v-if="$route.query.z_isEdit === '1'">
      <van-cell-group inset>
        <van-uploader
          :preview-options="{
            images: this.fileGQList,
          }"
          :before-delete="fileGQListDelete"
          class="preview-cover"
          v-model="fileList"
          :max-count="1"
          :after-read="afterRead"
        />
      </van-cell-group>
    </div> -->
    <!-- <div v-if="$route.query.z_isEdit === '2'"> -->
    <van-cell-group inset>
      <van-uploader
        class="preview-cover"
        :before-delete="fileGQListDelete"
        v-model="fileList"
        :max-count="1"
        :after-read="afterRead"
        @oversize="onOversize"
        :max-size="12 * 1024 * 1024"
      />
    </van-cell-group>
    <!-- </div>   -->
    <br />

    <!-- 报销备注 
        show-word-limit 字数显示不好用
    -->
    <van-cell-group inset>
      <van-field
        v-model="message"
        rows="2"
        autosize
        label="报销备注"
        type="textarea"
        placeholder="请输入报销备注信息"
        maxlength="55"
      />
    </van-cell-group>
    <br />

    <!-- 提交按钮 -->
    <div
      class="footer"
      style="width: 95%; margin-left: 10px; background-color: #f8f8f8"
    >
      <van-button
        round
        type="primary"
        size="large"
        @click="submit(result, number, message)"
        :disabled="disable"
        >保存</van-button
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { Toast } from "vant";
import { OrderRinputfeiyong } from "../../api";
import NavBar from "../../view/NavBar.vue";
import compressor from "compressorjs";
import axios from "axios";
import global from "@/js/global";
// import { fi } from "element-plus/lib/locale";

export default {
  components: {
    NavBar,
  },
  setup() {
    const columns = [
      "商场费",
      "交通费",
      "出差补助费",
      "物流费用",
      "运输费",
      "自采材料费",
    ];
    const result = ref("");
    const imageBase = ref("");
    const number = ref("");
    const message = ref("");
    // const maxSize = ref("");
    const showPicker = ref(false);

    const onConfirm = (value) => {
      result.value = value;
      showPicker.value = false;
    };

    // const afterRead = (file) => {
    //   // console.log(file);
    //   // 此时可以自行将文件上传至服务器
    //   imageBase.value = file;
    // };

    return {
      result,
      columns,
      onConfirm,
      showPicker,

      // afterRead,
      imageBase,
      number,
      message,
    };
  },
  watch: {
    message(newValue) {
      this.message = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
  },
  data() {
    return {
      feiyongtype: "",
      feiyongtypeid: "",
      imageBase64: "",
      fileList: [],
      fileGQList: [],
      disable: false,
    };
  },
  methods: {
    // 返回
    onClickLeft() {
      // 跳转上一级。
      this.$router.go(-1);
    },
    onOversize(file) {
      console.log(file);
      Toast("照片过大");
    },
    //读取完图片后
    afterRead(file) {
      // console.log("afterRead------", file.file.size);
      // if (file.file.size > 12 * 1024 * 1024) {
      //   Toast("照片过大");
      //   return;
      // }
      file.status = "uploading";
      file.message = "上传中...";
      const that = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      let qua = 0.2;
      // if (file.file.size > 1 * 1024 * 1024) {
      //   qua = 0.2;
      // } else {
      //   qua = 0.8;
      // }
      if (file.length >= 1) {
        // Toast("仅可选一张照片进行上传。");
        file.forEach((item, index) => {
          // photo.push(item.content);
          if(index == 0){
          new compressor(item.file, {
            quality: 0.2,
            // type: "JPEG",
            success(result) {
              const formData = new FormData();
              formData.append("photo", result, result.name);
              formData.append("token", that.$route.query.z_token);
              formData.append("userid", that.$route.query.z_userid);
              let config = {
                headers: {
                  //添加请求头
                  "Content-Type": "multipart/form-data",
                },
              };
              axios
                .post(global.apiUrl + "/upload/uploadphoto", formData, config)
                .then((resultData) => {
                  Toast.clear();
                  if (resultData.data.code == 1) {
                    // console.log("获取到的值" + resultData.data.data);
                    file.status = "done ";
                    that.imageBase64 = resultData.data.data;
                    Toast("上传成功");
                  } else {
                    Toast("上传失败");
                    file.status = "failed";
                    file.message = "上传失败";
                  }
                })
                .catch((err) => {
                  Toast.clear();
                  console.log(err);
                });
            },
          });
        }
        });
      }else{
        new compressor(file.file, {
        quality: qua,
        success(result) {
          const formData = new FormData();
          formData.append("photo", result, result.name);
          formData.append("token", that.$route.query.z_token);
          formData.append("userid", that.$route.query.z_userid);
          let config = {
            headers: {
              //添加请求头
              "Content-Type": "multipart/form-data",
            },
          };
          axios
            .post(global.apiUrl + "/upload/uploadphoto", formData, config)
            .then((resultData) => {
              // console.log(result);
              Toast.clear();
              if (resultData.data.code == 1) {
                // console.log("获取到的值" + resultData.data.data);
                file.status = "done ";
                that.imageBase64 = resultData.data.data;
                Toast("上传成功");
              } else {
                Toast("上传失败");
                file.status = "failed";
                file.message = "上传失败";
              }
            })
            .catch((err) => {
              Toast.clear();
              console.log(err);
            });
        },
      });
      }
    },
    //压缩图片保存
    // compressAndUploadFile(file) {
    //   compressImage(file.content, file.file.size).then((result) => {
    //     // console.log("压缩后的结果", result); // result即为压缩后的结果
    //     // console.log("压缩前大小", file);
    //     // console.log("压缩后大小", result);
    //     if (file.file.size > 1024 * 2) {
    //       //保存压缩图片二进制
    //       if (result.size > file.file.size) {
    //         //压缩后比原来更大，则将原图上传
    //         // console.log(1 + file.content);
    //         this.imageBase64 = file.content;
    //       } else {
    //         // console.log(2 + result);
    //         this.imageBase64 = result;
    //       }
    //     } else {
    //       // console.log(3);
    //       //保存压缩图片二进制
    //       this.imageBase64 = result;
    //     }
    //     file.status = "done ";
    //   });
    // },
    submit(result, number, message) {
      console.log("value" + message + number + parseFloat(number));

      if (result == "") {
        Toast("请选择费用类型");
        return;
      }
      if (number == "" || parseFloat(number) <= 0 || number == "-") {
        Toast("请输入正确的报销金额");
        return;
      }
      // if (message == "") {
      //   Toast("请输入报销备注信息");
      //   return;
      // }
      // let feiyongtype = "";
      switch (result) {
        case "商场费":
          this.feiyongtype = "REIMBUSER";
          this.feiyongtypeid = "14";
          break;
        case "交通费":
          this.feiyongtype = "REIMBUSER";
          this.feiyongtypeid = "12";
          break;
        case "出差补助费":
          this.feiyongtype = "REIMBUSER";
          this.feiyongtypeid = "13";
          break;
        case "物流费用":
          this.feiyongtype = "REIMBUSER";
          this.feiyongtypeid = "9";
          break;
        case "运输费":
          this.feiyongtype = "REIMBUSER";
          this.feiyongtypeid = "16";
          break;
        case "自采材料费":
          this.feiyongtype = "REIMBUSER";
          this.feiyongtypeid = "15";
          break;
      }
      console.log("录入" + result);
      // let feiyongtype = "";
      // if (value == "") {
      //   feiyongtype = result;
      // } else {
      //   feiyongtype = value;
      // }
      let parmasYong = {};
      if (this.$route.query.z_isEdit === "1") {
        if (this.fileList.length <= 0) {
          Toast("请重新上传报销照片");
          return;
        }
        this.disable = true;
        let pathImage = "";
        if (this.imageBase64) {
          pathImage = this.imageBase64;
        }
        parmasYong = {
          token: this.$route.query.z_token,
          userid: this.$route.query.z_userid,
          feiyong: 0,
          feiyongtype: this.feiyongtype,
          feiyongtypeid: this.feiyongtypeid,
          memo: message,
          money: number,
          path: pathImage,
          orderid: this.$route.query.id,
          feiyongid: this.$route.query.id62_2_,
        };
      } else {
        if (this.imageBase64 == "") {
          Toast("请上传报销照片");
          return;
        }
        this.disable = true;
        parmasYong = {
          token: this.$route.query.z_token,
          userid: this.$route.query.z_userid,
          feiyong: 0,
          feiyongtype: this.feiyongtype,
          feiyongtypeid: this.feiyongtypeid,
          memo: message,
          money: number,
          path: this.imageBase64,
          orderid: this.$route.query.id,
          feiyongid: 0,
        };
      }
      OrderRinputfeiyong(parmasYong)
        .then((result) => {
          if (result.code == 1) {
            Toast("保存成功");
            this.$router.go(-1);
          } else {
            Toast(result.message);
          }
          this.disable = false;
        })
        .catch((err) => {
          this.disable = false;
          console.log(err);
        });
    },
    // 删除照片
    fileGQListDelete() {
      // 删除本地
      Toast("删除成功");
      this.fileList = [];
    },
  },
  mounted: function () {
    // console.log(global.apiUrl);
    // 编辑赋值
    if (this.$route.query.z_isEdit === "1") {
      this.result = this.$route.query.name4_0_;
      this.number = this.$route.query.money62_2_;
      this.message = this.$route.query.memo62_2_;
      this.fileList = [{ url: this.$route.query.path }];
      // this.fileGQList.push(this.$route.query.path);
    }
  },
};
</script>

<style>
div .main {
  /* position: fixed; */
  height: 100%;
  width: 100%;
  background-color: #f8f8f8;
  padding-top: 20px;
  position: absolute;
}

.preview-cover {
  margin: 5px 0 0 10px;
}

/* :root {
  --van-uploader-delete-icon-size: 26px;
} */
</style>
