<template>
  <div class="OrderList" style="padding-top: 46px">
    <van-nav-bar
      title="订单列表"
      left-arrow
      @click-left="onClickBack"
      fixed
      safe-area-inset-top
    >
      ></van-nav-bar
    >
    <!--    @click-right="ticketArrears"
      right-text="欠票信息"
    -->
    <!-- <van-search
      v-model="searchValue"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
    >
      <template #action>
        <div @click="onClickButton">搜索</div>
      </template>
    </van-search> -->
    <!-- 滑动swipeable -->
    <van-tabs
      v-model:active="active"
      swipeable
      sticky
      animated
      ref="tabs"
      offset-top="46px"
      title-active-color="#325cfe"
      @click="change"
    >
      <van-tab v-for="item in tabList" :key="item">
        <template #title>
          <span>{{ item.name }}</span>
          <van-tag
            color="#325cfe"
            round
            type="primary"
            style="
              margin-left: 5px;
              height: 16px;
              width: 23px;
              text-align: center;
              color: white;
              display: inline-block;
              bottom: 0.5px;
            "
            >{{ parseInt(item.numbers) > 99 ? "99+" : item.numbers }}</van-tag
          >
        </template>
        <article-list
          :channel="item"
          @changeComponentData="componentDataChange"
        ></article-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import articleList from "./ArticleList.vue";
import { Toast } from "vant";
import { Getorderstatecount } from "../api";
export default {
  components: { articleList },
  name: "MapContainer",
  data() {
    return {
      active: 0,
      tabList: [
        {
          name: "未接",
          state: "SENDED",
          numbers: 0,
          z_active: 0,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_name: this.$route.query[3],
          z_phone: this.$route.query[4],
        },
        {
          name: "已接",
          state: "RECEIVE",
          numbers: 0,
          z_active: 1,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_name: this.$route.query[3],
          z_phone: this.$route.query[4],
        },
        {
          name: "完成",
          state: "CLOSED",
          numbers: 0,
          z_active: 2,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_name: this.$route.query[3],
          z_phone: this.$route.query[4],
        },
        {
          name: "已拒",
          state: "DENY",
          numbers: 0,
          z_active: 3,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_name: this.$route.query[3],
          z_phone: this.$route.query[4],
        },
        {
          name: "关闭",
          state: "EXECUTE_VERFY",
          numbers: 0,
          z_active: 4,
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
          z_name: this.$route.query[3],
          z_phone: this.$route.query[4],
        },
      ],
    };
  },
  created() {
    console.log("updated");
    setTimeout(() => {
      console.log("ssss");
      this.$refs.tabs.resize();
    }, 1);
  },
  methods: {
    getorderstatecount() {
      Getorderstatecount({
        token: this.$route.query[1],
        userid: this.$route.query[0],
      })
        .then((result) => {
          if (result.code == 1) {
            this.tabList[0].numbers = result.data.sendedCount;
            this.tabList[1].numbers = result.data.receiveCount;
            this.tabList[2].numbers = result.data.closedCount;
            this.tabList[3].numbers = result.data.denyCount;
            this.tabList[4].numbers = result.data.executeVerfyCount;
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    componentDataChange(item, params) {
      this.tabList[item].numbers = params;
    },
    change(name) {
      console.log(name);
      sessionStorage.setItem("active", name);
    },
    onClickBack() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.getDataFormVue.postMessage({
          title: "333", //vue给iOS传值
        });
      } else {
        window.android.onFinish();
      }
    },
    // ticketArrears() {
    //   this.$router.push({
    //     path: "/OweTicketCenter",
    //     query: this.detailsHomeData,
    //   });
    // },
  },
  // created() {
  //   setTimeout(() => {
  //     console.log("ssss");
  //     this.$refs.tabs.resize();
  //   }, 1);
  // },
  mounted: function () {
    this.getorderstatecount();
    let data = "";
    switch (sessionStorage.getItem("active")) {
      case "1":
        data = 1;
        break;
      case "2":
        data = 2;
        break;
      case "3":
        data = 3;
        break;
      case "4":
        data = 4;
        break;
      default:
        data = 0;
        break;
    }
    this.active = data;
  },
};
</script>

<style scoped>
:deep(.van-tabs__line) {
  background: white !important;
}
.van-nav-bar {
  background-color: #325cfe;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>
