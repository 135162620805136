<template>
  <div class="turnSendList">
    <!-- error-text="请求失败，点击重新加载" -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        @load="onLoad"
      >
        <div v-show="list.length > 0">
          <span
            style="
              text-align: center;
              display: block;
              font-size: 14px;
              height: 18px;
              padding-top: 5px;
              color: rgb(151, 152, 154);
            "
            >{{ this.name }} 共：{{ list.length }}单</span
          >
        </div>
        <van-cell v-for="item in list" :key="item" @click="jump(item)">
          <span class="projectName">[{{ item.code }}] </span>
          <p
            v-if="item.relationFlag == 2"
            style="display: inline-block; color: rgb(253, 184, 120)"
          >
            [子单]
          </p>
          <p
            v-if="item.relationFlag == 1"
            style="display: inline-block; color: rgb(253, 116, 118)"
          >
            [母单]
          </p>
          <p
            v-if="item.relationFlag == 0"
            style="display: inline-block; color: rgb(74, 175, 202)"
          >
            [原单]
          </p>
          <p
            v-if="item.orderLevel == 3"
            style="display: inline-block; color: red"
          >
            [紧急]
          </p>
          <p
            v-if="item.preOrder == true"
            style="display: inline-block; color: rgb(97, 125, 83)"
          >
            [预备]
          </p>
          <p>{{ item.name }}</p>
          <p>下单日期：{{ item.strDate + " " }}</p>
          <p>计划完成日期：{{ item.askDate + " " }}</p>
          <!-- v-if="$route.query.relationFlag == 2" -->
          <div>
            <span>状态：</span>
            <span
              v-if="item.state == 'NEW' || item.state == 'CREATED'"
              style="display: inline-block; color: rgb(238, 172, 87)"
            >
              未转派
            </span>
            <span
              v-if="
                item.state == 'DAQUDENY' ||
                item.state == 'SHIGONGDENY' ||
                item.state == 'DENY'
              "
              style="display: inline-block; color: red"
            >
              已拒单
            </span>
            <span
              v-if="item.state == 'SENDED' || item.state == 'RECEIVE'"
              style="display: inline-block; color: rgb(38, 222, 116)"
            >
              已转派
            </span>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from "vue";

import { RedeployOrder } from "../../api";
import { useRoute } from "vue-router";
import { Toast } from "vant";
export default {
  props: {
    turnsend: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const list = ref([]);
    let route = useRoute();
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const name = ref(props.turnsend.name);
    const state = ref(props.turnsend.state);
    const error = ref(false);
    const onLoad = () => {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      RedeployOrder({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0MyIsImV4cCI6MTk2NTYzNDY4NCwiaWF0IjoxNjUwMDE1NDg0fQ.kH0yUe8WZUq-g44r_U7h8Qvb5FPfckoAki9jC9dnc2M",
        // userid: "43",
        state: props.turnsend.state,
        token: route.query[1],
        userid: route.query[0],
      })
        .then((result) => {
          Toast.clear();
          if (refreshing.value) {
            list.value = [];
            refreshing.value = false;
          }
          list.value.push(...result.data);
          this.listArr = result.data;
          this.total = this.listArr.lenght;
          loading.value = false;

          finished.value = true;
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
          error.value = true;
          loading.value = false;
          // this.finished = true;
          // Toast("网络错误");
        });
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      list,
      loading,
      refreshing,
      onRefresh,
      finished,
      error,
      onLoad,
      name,
      state,
    };
  },
  methods: {
    data() {
      return {
        listArr: [],
        total: 0,
      };
    },
    jump(item) {
      this.listData = item;
      this.listData.jhjcsgTime = item.planEnterStartDate;
      this.listData.askDate = item.askDate;
      this.listData.z_userid = this.$route.query[0];
      this.listData.z_token = this.$route.query[1];
      this.$router.push({
        path: "/ReassignOrder",
        query: this.listData,
      });
    },

    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "" || isNaN(date)) {
        return "暂无";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " "
          // +
          // dt.getHours() +
          // ":" +
          // dt.getMinutes() +
          // ":" +
          // dt.getSeconds()
        );
      }
    },
  },
};
</script>

<style scoped>
.projectName {
  color: #02a7f0;
}
p {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}
</style>
