<template>
  <!-- 顶部导航 -->

  <!-- 表单内容：大区转派订单 -->
  <div class="main" style="padding-top: 46px">
    <van-nav-bar
      left-arrow
      title="订单转派"
      right-text="转派拒单"
      @click-left="onClickLeft"
      @click-right="onClickRight"
      fixed
    />
    <!-- <van-notice-bar
      left-icon="volume-o"
      text="选择大区负责的其他驻点区域时支持跨区转派"
    />g
    <br /> -->
    <!-- 选择驻点区域 -->
    <!-- <van-cell-group inset>
      <van-field
        v-model="value"
        is-link
        readonly
        label="选择驻点区域"
        placeholder="请选择"
        @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
    </van-cell-group>
    <br /> -->

    <!-- 选择驻点人员 -->
    <van-cell-group inset style="margin-top: 20px">
      <van-field
        v-model="result"
        is-link
        readonly
        label="选择驻点人员"
        placeholder="请选择"
        @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
          :columns="columns"
          @cancel="showPicker = false"
          value-key="name"
          @confirm="onConfirm"
        />
      </van-popup>
    </van-cell-group>
    <br />

    <!-- 计划进场日期 -->
    <van-cell-group inset style="align: center">
      <van-cell
        v-if="reassignContent === '' ? false : true"
        center
        :title="reassignName"
        :value="reassignContent"
      />
    </van-cell-group>
    <br />

    <van-notice-bar left-icon="volume-o" text="转派订单时大区需填写施工计划" />
    <br />
    <!-- 计划进场日期 -->
    <van-cell-group inset>
      <van-cell-group>
        <van-cell title="计划进场施工日期" :value="$route.query.jhjcsgTime" />
        <van-cell title="计划进场施工结束日期" :value="$route.query.askDate" />
      </van-cell-group>
    </van-cell-group>
    <!-- 是否需要物料 -->
    <van-cell-group inset>
      <van-cell center title="是否需要物料">
        <template #right-icon>
          <van-switch v-model="checked" size="24" />
        </template>
      </van-cell>
    </van-cell-group>
    <br />
    <!-- 计划物料到货日期 -->
    <van-cell-group inset v-if="this.checked == true">
      <van-cell
        title="计划物料到货日期"
        :value="dateJHWL"
        @click="showJHWL = true"
      />
      <van-calendar
        v-model:show="showJHWL"
        @confirm="onConfirmJHWL"
        color="#1989fa"
      />
    </van-cell-group>
    <!-- 计划完工日期 -->
    <van-cell-group inset>
      <van-cell
        title="计划完工日期"
        :value="dateJHWG"
        @click="showJHWG = true"
      />
      <van-calendar
        v-model:show="showJHWG"
        @confirm="onConfirmJHWG"
        color="#1989fa"
      />
    </van-cell-group>
    <br />
    <!-- <van-notice-bar
      left-icon="volume-o"
      text="转派维修类订单必须输入施工方式"
    />
    <br /> -->
    <!-- 大区施工方式 -->
    <!-- <van-cell-group inset>
      <van-field
        v-model="value"
        label="大区施工方式"
        placeholder="请输入施工方式"
        maxlength="20"
      />
    </van-cell-group> -->
    <br />
  </div>

  <!-- 转派 -->
  <div
    style="
      margin: 0px 0px 0px 0px;
      width: 100%;
      position: absolute;
      bottom: 16px;
    "
  >
    <van-button round type="primary" size="large" @click="TurnToSend"
      >转派</van-button
    >
  </div>
</template>

<script>
import { ref } from "vue";
import { EtzhudianRegion } from "../../api";
import { Redeploy } from "../../api";
import { AirScore } from "../../api";
import { Toast } from "vant";
import { useRoute } from "vue-router";
export default {
  setup() {
    const columns = ref([]);
    const result = ref("");
    let route = useRoute();
    const reassignId = ref(0);
    const reassignName = ref("");
    const reassignContent = ref("");
    const showPicker = ref(false);
    // 选择是否需要物料
    const checked = ref(false);
    // 物料到货日期
    const dateJHWL = ref("");
    const dateJHWLLong = ref("");
    const showJHWL = ref(false);

    const formatDateJHWL = (dateJHWL) =>
      // `${dateJHWL.getFullYear()}-${
      //   dateJHWL.getMonth() + 1
      // }-${dateJHWL.getDate()}`;
      {
        var year = dateJHWL.getFullYear();
        var month = dateJHWL.getMonth() + 1;
        var strDate = dateJHWL.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var currentdate = year + "-" + month + "-" + strDate;
        return currentdate;
      };
    const onConfirmJHWL = (valueJHWL) => {
      showJHWL.value = false;
      dateJHWLLong.value = valueJHWL;
      dateJHWL.value = formatDateJHWL(valueJHWL);
    };

    // 计划完工日期
    const dateJHWG = ref("");
    const dateJHWGLong = ref("");
    const showJHWG = ref(false);
    const year = ref("");

    const formatDateJHWG = (dateJHWG) =>
      // `${dateJHWG.getFullYear()}-${
      //   dateJHWG.getMonth() + 1
      // }-${dateJHWG.getDate()}`;
      {
        var year = dateJHWG.getFullYear();
        var month = dateJHWG.getMonth() + 1;
        var strDate = dateJHWG.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var currentdate = year + "-" + month + "-" + strDate;
        return currentdate;
      };
    const onConfirmJHWG = (valueJHWG) => {
      showJHWG.value = false;
      dateJHWGLong.value = valueJHWG;
      dateJHWG.value = formatDateJHWG(valueJHWG);
    };
    const onConfirm = (value) => {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      result.value = value.name;
      reassignId.value = value.id;
      reassignName.value = value.name;
      showPicker.value = false;
      console.log(route.query.z_token);
      AirScore({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0MyIsImV4cCI6MTk2NTUyNTY2NCwiaWF0IjoxNjQ5OTA2NDY0fQ.tWkomw7zIoi8nmR4gBxok8fY4niKIK8a_LMy1h2Rd4A",
        // userid: "43",
        token: route.query.z_token,
        userid: route.query.z_userid,
        airuserid: value.id,
      })
        .then((result) => {
          reassignContent.value = `超期订单数：${result.data.overtime} 未完成订单数：${result.data.incomplete} 近30日已完工订单数：${result.data.completed}`;
          Toast.clear();
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          // Toast("网络错误");
        });
    };

    const afterRead = (file) => {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    };

    return {
      result,
      columns,
      onConfirm,
      showPicker,
      reassignId,
      reassignName,
      reassignContent,
      afterRead,
      checked,
      year,
      dateJHWL,
      showJHWL,
      dateJHWLLong,
      onConfirmJHWL,
      dateJHWG,
      showJHWG,
      dateJHWGLong,
      onConfirmJHWG,
    };
  },
  methods: {
    // 返回
    onClickLeft() {
      // 跳转上一级。
      this.$router.go(-1);
    },
    // 拒单
    onClickRight() {
      this.$route.query.routeName = "ReassignOrder";
      // 跳转拒单
      this.$router.push({
        path: "/DenyOrder",
        query: this.$route.query,
      });
    },
    TurnToSend() {
      if (this.reassignName === "") {
        Toast("请选择驻点人员");
        return;
      }
      if (this.checked) {
        if (this.dateJHWL === "") {
          Toast("请选择物料到货日期");
          return;
        }
      }
      if (this.dateJHWG === "") {
        Toast("请选择计划完工日期");
        return;
      }
      Redeploy({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        isuserid: this.reassignId,
        jhjcsgtime: this.$route.query.jhjcsgTime,
        jhjcsgtimeend: this.$route.query.askDate,
        jhwgTime: this.dateJHWG,
        jhwldhtime: this.dateJHWL,
        needmateriel: this.checked ? 1 : 0,
        orderid: this.$route.query.id,
        // token: route.query.z_token,
        // userid: route.query.z_userid,
        // orderid: route.query.id,
      })
        .then((result) => {
          if (result.code == 1) {
            Toast("转派成功");
            this.$router.go(-1);
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
    formatDate(date) {
      console.log(date);
      // 获取单元格数据
      let data = date;
      if (data === "" || isNaN(date)) {
        return "暂无";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " "
          // +
          // dt.getHours() +
          // ":" +
          // dt.getMinutes() +
          // ":" +
          // dt.getSeconds()
        );
      }
    },
    getEtzhudianRegion() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      EtzhudianRegion({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0MyIsImV4cCI6MTk2NTUyNTY2NCwiaWF0IjoxNjQ5OTA2NDY0fQ.tWkomw7zIoi8nmR4gBxok8fY4niKIK8a_LMy1h2Rd4A",
        // userid: "43",
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
      })
        .then((result) => {
          Toast.clear();
          this.columns.push(...result.data);
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
          // this.finished = true;
          Toast("网络错误");
        });
    },
  },
  mounted() {
    this.getEtzhudianRegion();
  },
};
</script>

<style scoped>
div .main {
  /* position: fixed; */
  height: max-content;
  width: 100%;
  background-color: #f8f8f8;
  padding-top: 20px;
  position: absolute;
}
.van-nav-bar {
  background-color: #325cfe;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>
