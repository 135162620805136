// 本地
// const apiUrl = "http://192.168.8.109:8088"; // url = base api url + request url
// 测试接口地址
// const apiUrl = "https://capacityapi.dbshopplus.com";
// 正式接口地址
const apiUrl = "https://sopapi.dbshopplus.com";

export default {
  apiUrl,
};
