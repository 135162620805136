<template>
  <!-- 顶部导航 -->
  <NavBar
    z_left_arrow
    title="地图"
    @click-left="onClickLeft"
    safe-area-inset-top
  />
  <div class="MapContainer" style="padding-top: 46px">
    <button @click="getAddress">ssss</button>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import NavBar from "../view/NavBar.vue";
import { Toast } from "_vant@3.4.5@vant";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      ssposition: "",
    };
  },
  methods: {
    /** 获取高德地图定位 */

    getAddress() {
      // Toast(this.ssposition.lng + "," + this.ssposition.lat);
      alert(
        "转换" +
          this.bd_encrypt(this.ssposition.lng, this.ssposition.lat).bd_lng +
          this.bd_encrypt(this.ssposition.lng, this.ssposition.lat).bd_lat
      );
      alert(this.ssposition.lng + "," + this.ssposition.lat);
      // console.log(this.ssposition.lng, this.ssposition.lat);
    },
    initMap() {
      AMapLoader.load({
        key: "32822d1c979836a2a762cf592494124f", // 申请好的Web端开发者Key，首次调用 load 时必填
        // jscode: "f4267f462a22633818706591633c684e",
        // version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.CitySearch", "AMap.Geolocation", "AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        // Loca: { version: "2.0" },
      })
        .then((AMap) => {
          let that = this;
          // AMap.plugin("AMap.CitySearch", function () {
          //   var citySearch = new AMap.CitySearch();
          //   citySearch.getLocalCity(function (status, result) {
          //     console.log("位置111" + result);
          //     if (status === "complete" && result.info === "OK") {
          //       // 查询成功，result即为当前所在城市信息
          //     }
          //   });
          // });
          AMap.plugin("AMap.Geolocation", function () {
            var geolocation = new AMap.Geolocation({
              enableHighAccuracy: true, // 是否使用高精度定位，默认:true
              timeout: 10000, // 超过10秒后停止定位，默认：无穷大
              maximumAge: 0, // 定位结果缓存0毫秒，默认：0
              convert: true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
              showButton: true, // 显示定位按钮，默认：true
              buttonPosition: "LB", // 定位按钮停靠位置，默认：'LB'，左下角
              buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
              showMarker: true, // 定位成功后在定位到的位置显示点标记，默认：true
              showCircle: true, // 定位成功后用圆圈表示定位精度范围，默认：true
              panToLocation: true, // 定位成功后将定位到的位置作为地图中心点，默认：true
              zoomToAccuracy: true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            });

            geolocation.getCurrentPosition(function (status, result) {
              if (status == "complete") {
                onComplete(result);
              } else {
                onError(result);
              }
            });

            function onComplete(data) {
              // document.getElementById('status').innerHTML='定位成功'
              console.log(data);
              var str = [];
              that.ssposition = data.position;
              str.push("定位结果：" + data.position);
              str.push("定位类别：" + data.location_type);
              str.push("定位地址：" + data.formattedAddress);
              if (data.accuracy) {
                str.push("精度：" + data.accuracy + " 米");
              }
              //如为IP精确定位结果则没有精度信息
              str.push("是否经过偏移：" + (data.isConverted ? "是" : "否"));
              // console.log(str);
              Toast("定位成功" + str);
              // 转换
              // Toast(
              //   "转换" + this.bd_encrypt(data.position.lng, data.position.lat)
              // );
              // AMap.Geocoder().getAddress(
              //   [data.position.lng, data.position.lat],
              //   (status, { regeocode }) => {
              //     // if (status === "complete" && regeocode) {
              //     // address即经纬度转换后的地点名称
              //     // const address = regeocode?.formattedAddress;
              //     Toast(111 + regeocode?.formattedAddress);
              //     // }
              //   }
              // );
              // var lnglat = [116.396574, 39.992706];
              // geolocation.getAddress(lnglat, function (status, result) {
              //   if (status === "complete" && result.info === "OK") {
              //     // result为对应的地理位置详细信息
              //     Toast("地址" + result);
              //   }
              // });
            }
            function onError(data) {
              // 定位出错
              var str = "失败原因排查信息:" + data.message;
              Toast("定位失败" + str);
            }
          });
          // AMap.plugin("AMap.Geocoder", function () {
          //   var geocoder = new AMap.Geocoder({
          //     // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          //     city: "",
          //   });
          //   var lnglat = [116.396574, 39.992706];
          //   geocoder.getAddress(lnglat, function (status, result) {
          //     console.log("位置" + result);
          //     if (status === "complete" && result.info === "OK") {
          //       // result为对应的地理位置详细信息
          //       console.log("位置" + result);
          //     }
          //   });
          // });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //高德坐标转百度（传入经度、纬度）
    bd_encrypt(gg_lng, gg_lat) {
      var X_PI = (Math.PI * 3000.0) / 180.0;
      var x = gg_lng,
        y = gg_lat;
      var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
      var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
      var bd_lng = z * Math.cos(theta) + 0.0065;
      var bd_lat = z * Math.sin(theta) + 0.006;
      return {
        bd_lat: bd_lat,
        bd_lng: bd_lng,
      };
    },
    //百度坐标转高德（传入经度、纬度）
    // bd_decrypt(bd_lng, bd_lat) {
    //   var X_PI = (Math.PI * 3000.0) / 180.0;
    //   var x = bd_lng - 0.0065;
    //   var y = bd_lat - 0.006;
    //   var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
    //   var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
    //   var gg_lng = z * Math.cos(theta);
    //   var gg_lat = z * Math.sin(theta);
    //   return { lng: gg_lng, lat: gg_lat };
    // },
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    // this.initMap();
    window._AMapSecurityConfig = {
      securityJsCode: "f4267f462a22633818706591633c684e",
    };
    this.initMap(); // 调用获取地理位置
  },
};
</script>

<style scoped></style>
