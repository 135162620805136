<template>
  <div class="mian" style="padding-top: 46px">
    <!-- 顶部导航 -->
    <van-nav-bar
      title="我的报销"
      left-arrow
      @click-left="onClickBack"
      fixed
      safe-area-inset-top
    ></van-nav-bar>
    <van-notice-bar
      wrapable
      :scrollable="false"
      text="当未查询到报销数据时，可能由于大区或财务未审核通过，请您及时联系负责人核实情况。"
    />
    <br>
    <van-cell-group inset>
      <van-field
        v-model="repairType"
        is-link
        readonly
        label="月份"
        :placeholder="selectedMonth"
        @click="showMonthPicker = true"
      />

      <van-popup v-model:show="showMonthPicker" round position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          title="选择年月"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatterMonth"
          @cancel="showMonthPicker = false"
          @confirm="onMonthConfirm"
        />
      </van-popup>
      <van-field
        v-model="orderStatus"
        is-link
        readonly
        label="审核状态"
        placeholder="全部"
        @click="showOrderStatusPicker = true"
      />

      <van-popup v-model:show="showOrderStatusPicker" round position="bottom">
        <van-picker
          default-index="0"
          :columns="orderStatusList"
          @cancel="showOrderStatusPicker = false"
          @confirm="onOrderStatusConfirm"
        />
      </van-popup>
    </van-cell-group>
    <br />
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div>
        <span
          style="
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: center;
            display: block;
            color: rgb(150, 151, 153);
            font-size: 14px;
          "
          >{{ yearMonth }}合计报销（含税）：{{ total }}元</span
        >
      </div>
      <van-cell v-for="item in list" :key="item">
        <div style="float: left">
          <span>费用编号：{{ item.feiyong19_62_2_ }}</span>
          <br />
          <span>订单编号：{{ item.code57_1_ }}</span>
          <br />
          <span>报销金额：{{ item.money62_2_ }}</span>
          <br /><span>费用类型：{{ item.name4_0_ }}</span> <br /><span
            >审核状态：{{ item.verify43_62_2_ }}</span
          >
        </div>
        <van-image
          style="float: right; margin-top: 10px"
          width="100"
          height="100"
          @click="showPic(item.path62_2_)"
          :src="item.path62_2_"
        />
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { Toast } from "vant";
import { ImagePreview } from "vant";
import { Getuserreimbursementlist } from "../api";
export default {
  data() {
    return {
      yearMonth:
        new Date().getFullYear() + "年" + (new Date().getMonth() + 1) + "月",
      selectedMonth:
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1 < 10
          ? "0" + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1),
      currentDate: new Date(),
      maxDate: new Date(2100, 10, 1),
      showMonthPicker: false,
      loading: false,
      orderStatus: "",
      repairType: "",
      finished: false,
      list: [],
      showOrderStatusPicker: false,
      orderStatusList: ["全部", "审核通过", "待审核", "审核拒绝"],
      total: 0,
    };
  },
  methods: {
    onClickBack() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.getDataFormVue.postMessage({
          title: "333", //vue给iOS传值
        });
      } else {
        window.android.onFinish();
      }
    },
    formatterMonth(type, val) {
      if (type === "year") {
        return `${val}年`;
      }
      if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    onMonthConfirm(value) {
      this.finished = false;
      this.repairType = this.formatDate(value);
      this.selectedMonth = this.formatDate(value);
      this.showMonthPicker = false;
      this.list = [];
      this.total = 0;
      this.yearMonth =
        value.getFullYear() + "年" + (value.getMonth() + 1) + "月";
      this.Getuserreimbursementlist(this.orderStatus);
    },
    onOrderStatusConfirm(value) {
      this.finished = false;
      this.showOrderStatusPicker = false;
      this.orderStatus = value;
      this.list = [];
      this.total = 0;
      this.Getuserreimbursementlist(value);
    },
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        // let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        // let hours = dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours();
        // let minutes =
        //   dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month
          //  +
          // "-" +
          // // dt.getDate() +
          // day +
          // " " +
          // hours +
          // ":" +
          // minutes
          // +
          // ":" +
          // dt.getSeconds()
        );
      }
    },
    Getuserreimbursementlist(value) {
      let verifystatus = "";
      switch (value) {
        case "待审核":
          verifystatus = "TODO";
          break;
        case "审核通过":
          verifystatus = "PASS";
          break;
        case "审核拒绝":
          verifystatus = "DENY";
          break;
        default:
          verifystatus = "全部";
          break;
      }
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      Getuserreimbursementlist({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
        // userid: "24",
        token: this.$route.query[1],
        userid: this.$route.query[0],
        verifystatus: verifystatus,
        strdate: this.selectedMonth,
      })
        .then((result) => {
          Toast.clear();
          this.total = result.data.money;
          if (result.data.feiYongVoList.length > 0) {
            this.list.push(...result.data.feiYongVoList);
          }
          this.finished = true;
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
          Toast("网络错误");
        });
    },
    showPic(value) {
      ImagePreview([value]);
    },
  },
  mounted() {
    this.Getuserreimbursementlist("全部");
  },
};
</script>

<style scoped>
div .main {
  width: 100%;
  height: max-content;
  position: absolute;
  background-color: #f8f8f8;
}
.van-nav-bar {
  background-color: #325cfe;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>
