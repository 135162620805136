<template>
  <!-- 顶部导航 -->
  <van-nav-bar
    title="我的地址"
    left-arrow
    @click-left="onClickBack"
    fixed
    safe-area-inset-top
  >
    ></van-nav-bar
  >
  <!-- 解决事件冲突问题 
  @click.stop -->
  <div class="main" style="padding-top: 50px">
    <h5 style="width: 90%">地址列表</h5>
    <van-cell-group inset>
      <van-list>
        <van-cell v-for="item in list" :key="item" @click="onEdit(item)">
          <span
            >联系人：{{ "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            }}{{ item.username }}</span
          >
          <button class="editBtn" @click.stop="editList(item)">修改</button>
          <br />
          <span>联系电话：{{ "&nbsp;&nbsp;" }}{{ item.phone }}</span>
          <br />
          <span>收货地址：{{ "&nbsp;&nbsp;" }}{{ item.address }}</span>
          <!-- <van-row>
            <van-col span="6">收货地址：</van-col>
            <van-col span="17">{{ item.address }}</van-col>
          </van-row> -->
        </van-cell></van-list
      >
    </van-cell-group>
    <van-popup v-model:show="show" round :style="{ width: '80%' }">
      <span
        style="
          line-height: 33px;
          text-align: center;
          display: block;
          font-size: 14px;
          height: 33px;
        "
        >填写地址信息</span
      >
      <van-field
        v-model="name"
        label="姓名"
        placeholder="请输入"
        required
        maxlength="10"
        input-align="right"
      />
      <van-field
        v-model="phone"
        label="联系电话"
        placeholder="请输入"
        required
        maxlength="13"
        type="number"
        input-align="right"
      />
      <van-field
        v-model="address"
        label="收件地址"
        placeholder="请输入"
        required
        maxlength="55"
        input-align="right"
      />
      <div v-if="isEdit == true" style="height: 66px">
        <van-button
          type="primary"
          size="large"
          round
          style="
            display: block;
            margin: 0 auto;
            width: 65%;
            height: 44px;
            top: 11px;
          "
          @click="confirmBtn()"
        >
          确定
        </van-button>
      </div>
      <div v-if="isEdit == false" style="height: 66px">
        <van-button
          type="danger"
          size="large"
          round
          style="
            margin: 0 auto;
            width: 40%;
            height: 44px;
            top: 11px;
            float: left;
            left: 10px;
          "
          @click="deleteItem()"
        >
          删除
        </van-button>
        <van-button
          type="primary"
          size="large"
          round
          style="
            margin: 0 auto;
            width: 40%;
            height: 44px;
            top: 11px;
            float: right;
            right: 10px;
          "
          @click="editItem()"
        >
          修改
        </van-button>
      </div>
    </van-popup>
    <div class="footer" style="width: 95%; margin-left: 10px">
      <van-button
        type="primary"
        size="large"
        round
        :disabled="disable"
        @click="showView()"
        >新增地址</van-button
      >
    </div>
  </div>
</template>

<script>
// import { Toast } from "vant";
// import NavBar from "../view/NavBar.vue";
import { Addaddress, Getaddress, Deleteaddress, Updateaddress } from "../api";
import { Toast } from "vant";
import { Dialog } from "vant";

// import { fa } from "_element-plus@2.0.5@element-plus/lib/locale";
// import Qs from "qs"; // 传参需要用qs转换一下

export default {
  data() {
    return {
      list: [],
      name: "",
      address: "",
      phone: "",
      contactsaddressid: "",
      show: false,
      isEdit: true,
      z_token: "",
      z_userid: "",
    };
  },
  watch: {
    name(newValue) {
      this.name = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    address(newValue) {
      this.address = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    phone(newValue) {
      this.phone = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
  },
  methods: {
    // 添加地址
    showView() {
      this.name = "";
      this.address = "";
      this.phone = "";
      this.contactsaddressid = "";
      this.isEdit = true;
      //   Toast("新增地址");
      this.show = true;
    },
    confirmBtn() {
      if (this.name == "") {
        Toast("请输入姓名");
        return;
      }
      if (this.phone == "") {
        Toast("请输入联系电话");
        return;
      }
      if (this.name == "") {
        Toast("请输入收件地址");
        return;
      }
      Addaddress({
        token: this.z_token,
        userid: this.z_userid,
        phone: this.phone,
        username: this.name,
        address: this.address,
      })
        .then((result) => {
          if (result.code == 1) {
            this.list = [];
            this.show = false;
            // 刷新数据
            this.onLoad();
            Toast(result.data);
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择地址
    onEdit(item) {
      // console.log(item);
      // console.log(2222);
      if (
        this.$route.query.z_isTap == true ||
        this.$route.query.z_isTap == "true"
      ) {
        localStorage.setItem("stack", JSON.stringify(item));
        // localStorage.setItem("st", false);
        // 返回上一页
        this.$router.back();
      }
    },
    // 修改地址
    editList(item) {
      this.isEdit = false;
      console.log(item.username);
      this.name = item.username;
      this.phone = item.phone;
      this.address = item.address;
      this.contactsaddressid = item.id;
      this.show = true;
      // console.log(11111);
    },
    // 删除地址
    deleteItem() {
      Dialog.confirm({
        title: "",
        message: "是否删除该条地址",
      })
        .then(() => {
          Deleteaddress({
            token: this.z_token,
            userid: this.z_userid,
            contactsaddressid: this.contactsaddressid,
          })
            .then(() => {
              this.show = false;
              this.list = [];
              Toast("删除成功");
              this.onLoad();
            })
            .catch((err) => {
              this.show = false;
              console.log(err);
              Toast("网络错误");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 确认编辑
    editItem() {
      if (this.name == "") {
        Toast("请输入姓名");
        return;
      }
      if (this.phone == "") {
        Toast("请输入联系电话");
        return;
      }
      if (this.name == "") {
        Toast("请输入收件地址");
        return;
      }
      Updateaddress({
        token: this.z_token,
        userid: this.z_userid,
        phone: this.phone,
        username: this.name,
        address: this.address,
        contactsaddressid: this.contactsaddressid,
      })
        .then((result) => {
          if (result.code == 1) {
            this.list = [];
            this.show = false;
            // 刷新数据
            this.onLoad();
            Toast(result.data);
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onLoad() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      Getaddress({
        token: this.z_token,
        userid: this.z_userid,
      })
        .then((result) => {
          Toast.clear();
          console.log(result);
          // 将新数据与老数据进行合并
          this.list = [...this.list, ...result.data];
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
          Toast("网络错误");
        });
    },
    onClickBack() {
      if (this.z_isTap == true || this.z_isTap == "true") {
        // console.log(111);
        this.$router.go(-1);
      } else {
        console.log(222);
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      }
    },
  },
  mounted: function () {
    if (
      this.$route.query.z_isTap == "true" ||
      this.$route.query.z_isTap == true
    ) {
      this.z_isTap = true;
      this.z_userid = this.$route.query.z_userid;
      this.z_token = this.$route.query.z_token;
    } else {
      this.z_isTap = false;
      this.z_userid = this.$route.query[0];
      this.z_token = this.$route.query[1];
    }
    this.onLoad();
  },
};
</script>

<style scoped>
div .main {
  height: 100%;
  width: 100%;
  background-color: #f8f8f8;
  padding-bottom: 350px;
  position: absolute;
}
.addressSpan {
  background-color: black;
  color: rgb(0, 158, 237);
  font-size: 13px;
  /* height: 200px; */
}
.topSpan {
  background-color: rgb(0, 158, 237);
  width: 16.66%;
  border: 1 solid rgb(110, 110, 110);
  text-align: center;
  color: white;
  font-size: 13px;
}
.contentSpan {
  background-color: white;
  width: 16.66%;
  /* border: 1% solid rgb(110, 110, 110); */
  text-align: center;
  color: black;
  font-size: 13px;
}
.editBtn {
  color: rgb(0, 158, 239);
  width: 20.28%;
  border: none;
  background-color: white;
  font-size: 13px;
  text-align: right;
  float: right;
}
.clearBtn {
  border: none;
  background-color: #f8f8f8;
}
.preview-cover {
  margin: 5px 0 0 10px;
}
h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(136, 145, 145);
  text-align: left;
  /* b
  ackground-color: clear; */
}
div .footer {
  height: 60px;
  background-color: #f8f8f8;
  position: fixed;
  bottom: 0;
}
.van-nav-bar {
  background-color: #325cfe;
}
:deep(.van-nav-bar) {
  color: white !important;
}
:deep(.van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>
