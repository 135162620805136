<template>
    <div class="OrderList" style="padding-top: 46px">
      <van-nav-bar
        title="审核中心"
        left-arrow
        @click-left="onClickBack"
        fixed
        safe-area-inset-top
      >
        ></van-nav-bar
      >
      <van-tabs
        v-model:active="active"
        swipeable
        sticky
        animated
        ref="tabs"
        offset-top="46px"
        title-active-color="#325cfe"
        @click="change"
      >
        <van-tab v-for="item in tabList" :key="item">
          <template #title>
            <span>{{ item.name }}</span>
            <van-tag
              color="#325cfe"
              round
              type="primary"
              style="
                margin-left: 5px;
                height: 16px;
                width: 23px;
                text-align: center;
                color: white;
                display: inline-block;
                bottom: 0.5px;
              "
              >{{ parseInt(item.numbers) > 99 ? "99+" : item.numbers }}</van-tag
            >
          </template>
          <approvalCenter-List
            :channel="item"
            @changeComponentData="componentDataChange"
          ></approvalCenter-List>
        </van-tab>
      </van-tabs>
    </div>
  </template>
  
  <script>
//   import articleList from "../ArticleList.vue" OrderfeiyongGetfeiyongstatecount;
  import approvalCenterList from "../approvalcenter/ApprovalCenterList.vue";
  import { Toast } from "vant";
  import { OrderfeiyongGetfeiyongstatecount } from "../../api";
  export default {
    components: { approvalCenterList },
    name: "MapContainer",
    data() {
      return {
        active: 0,
        tabList: [
          {
            name: "已拒绝",
            state: "DENY",
            numbers: 0,
            z_active: 0,
            z_userid: this.$route.query[0],
            z_token: this.$route.query[1],
            z_name: this.$route.query[3],
            z_phone: this.$route.query[4],
          },
          {
            name: "待审核",
            state: "TODO",
            numbers: 0,
            z_active: 1,
            z_userid: this.$route.query[0],
            z_token: this.$route.query[1],
            z_name: this.$route.query[3],
            z_phone: this.$route.query[4],
          },
          {
            name: "已通过",
            state: "PASS",
            numbers: 0,
            z_active: 2,
            z_userid: this.$route.query[0],
            z_token: this.$route.query[1],
            z_name: this.$route.query[3],
            z_phone: this.$route.query[4],
          },
        ],
      };
    },
    created() {
      // console.log("updated");
      setTimeout(() => {
        console.log("ssss");
        this.$refs.tabs.resize();
      }, 1);
    },
    methods: {
      getorderstatecount() {
        Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
        OrderfeiyongGetfeiyongstatecount({
          token: this.$route.query[1],
          userid: this.$route.query[0],
        })
          .then((result) => {
            Toast.clear();
            if (result.code == 1) {
              this.tabList[0].numbers = result.data.denyCount;
              this.tabList[1].numbers = result.data.todoCount;
              this.tabList[2].numbers = result.data.passCount;
            }
          })
          .catch((err) => {
            Toast.clear();
            console.log(err);
            // this.finished = true;
            Toast("网络错误");
          });
      },
      componentDataChange(item, params) {
        this.tabList[item].numbers = params;
      },
      change(name) {
        console.log(name);
        sessionStorage.setItem("active", name);
      },
      onClickBack() {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.getDataFormVue.postMessage({
            title: "333", //vue给iOS传值
          });
        } else {
          window.android.onFinish();
        }
      },
      // ticketArrears() {
      //   this.$router.push({
      //     path: "/OweTicketCenter",
      //     query: this.detailsHomeData,
      //   });
      // },
    },
    // created() {
    //   setTimeout(() => {
    //     console.log("ssss");
    //     this.$refs.tabs.resize();
    //   }, 1);
    // },
    mounted: function () {
      this.getorderstatecount();
      let data = "";
      switch (sessionStorage.getItem("active")) {
        case "1":
          data = 1;
          break;
        case "2":
          data = 2;
          break;
        case "3":
          data = 3;
          break;
        default:
          data = 0;
          break;
      }
      this.active = data;
    },
  };
  </script>
  
  <style scoped>
  :deep(.van-tabs__line) {
    background: white !important;
  }
  .van-nav-bar {
    background-color: #325cfe;
  }
  :deep(.van-nav-bar .van-icon) {
    color: white !important;
  }
  :deep(.van-nav-bar__title) {
    color: white !important;
  }
  :deep(.van-nav-bar__text) {
    color: white !important;
  }
  </style>
  