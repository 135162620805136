<template>
  <div></div>
</template>

<script>
// import { Toast } from "_vant@3.4.5@vant";
export default {
  // 钩子
  mounted: function () {
    window.getDataFromNative = this.getDataFromNative;
    // if (this.userList) {
    // //   var z_userid = "11"  //声明个变量存储下数据
    // //   localStorage.setItem('z_userid', z_userid);  //将变量imgs存储到name字段
    // //   var z_token = "11111111"  //声明个变量存储下数据
    // //   localStorage.setItem('z_token', z_token);  //将变量imgs存储 到name字段

    //   this.$router.push({
    //     path: "/homePage",
    //     query: this.userList,
    //     // path: "/MyAddress",
    //     // query: this.userList,
    //     // path: "/TurnSendPage",
    //     // query: this.userList,
    //     // path: "/OweTicketCenter",
    //     // query: this.userList,
    //   });
    // }
  },
  data() {
    return {
      // userList: [
      //   423,
      //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0MjMiLCJleHAiOjE5OTM4NzgyMTYsImlhdCI6MTY3ODI1OTAxNn0.lEB5LJY0x-iEtJJ6ANglhlXpHEXcBQ0ri52YWRkO_-Y",
      //   24,
      //   "包思朝",
      //   "17600000000",
      // ],
      // 正式
      // userList: [
      //   423,
      //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0MjMiLCJleHAiOjE5ODMxNTk5NDYsImlhdCI6MTY2NzU0MDc0Nn0.JyegYmwC2IWDAA3s2k_KKOIWdIPAIKMvKxgEpOsLNOY",
      //   24,
      //   "包思朝",
      //   "17600000000",
      // ],
      // userList: [
      //   423,
      //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI0MjMiLCJleHAiOjE5NzkzNjEyMzUsImlhdCI6MTY2Mzc0MjAzNX0.88QnqZ8vhLSUH0_0xuVjuaJgOOI9L17Rmiwp2-Phyao",
      //   24,
      //   "包思朝",
      //   "17600000000",
      // ],
      userList: [],
      content: 0,
    };
  },
  methods: {
    getDataFromNative(params) {
      // console.log(1 + params);
      this.userList = params.split(",");
      // Toast(this.userList[2]);
      // var data = {
      //   name: "xiaomili",
      // };
      // return data; //回调给原生，可写可不写
      if (this.userList && this.content == 0) {
        this.content++;
        switch (this.userList[2]) {
          case "101":
            this.$router.push({
              path: "/homePage",
              query: this.userList,
            });
            break;
          case "102101":
            this.$router.push({
              path: "/ReimburseMent",
              query: this.userList,
            });
            break;
          case "102102":
            this.$router.push({
              path: "/OweTicketCenter",
              query: this.userList,
            });
            break;
          case "102103":
            this.$router.push({
              path: "/OperatingCosts",
              query: this.userList,
            });
            break;
          case "103":
            this.$router.push({
              path: "/TurnSendPage",
              query: this.userList,
            });
            break;
          case "104":
            this.userList.z_title = "统计报表";
            this.$router.push({
              path: "/StatisticalReports",
              query: this.userList,
            });
            break;
          case "105":
          this.userList.z_title = "审批中心";
          this.$router.push({
            path: "/ApprovalCenter",
            query: this.userList,
          });
          break;
          case "888":
            this.userList.z_title = "评价";
            this.$router.push({
              path: "/StatisticalReports",
              query: this.userList,
            });
            break;
          case "999":
            this.$router.push({
              path: "/MyAddress",
              query: this.userList,
            });
            break;
        }
      }
    },
  },
};
</script>

<style></style>
