<template>
  <!-- 顶部导航 -->
  <div class="main" style="padding-top: 66px">
    <NavBar z_left_arrow title="物料查看" @click-left="onClickLeft" />
    <h5>基本信息</h5>
    <van-cell-group inset>
      <van-cell title="订单编号" :value="$route.query.code" />
      <van-cell title="订单名称" :value="$route.query.name" />
      <van-cell title="申请日期" :value="$route.query.strDate" />
    </van-cell-group>
    <h5>收货人信息</h5>
    <van-cell-group inset>
      <van-cell @click="jumpAddress()">
        <span>{{ this.addressDict.username }}</span>
        <br />
        <span>{{ this.addressDict.phone }}</span>
        <br />
        <span>{{ this.addressDict.address }}</span>
      </van-cell>
    </van-cell-group>
    <h5>到货信息</h5>
    <van-cell-group inset>
      <van-cell
        v-if="this.isEdit == true"
        required
        title="到货日期"
        :value="dataDHRQ"
        @click="showDHRQ = true"
      />
      <van-calendar
        v-model:show="showDHRQ"
        @confirm="onConfirmDHRQ"
        color="#1989fa"
        :max-date="maxDate"
      />
      <van-cell
        v-if="this.isEdit == false"
        title="到货日期"
        :value="$route.query.endDate"
      />
      <van-field
        v-if="this.isEdit == true"
        v-model="memo"
        label="备注"
        placeholder="点击输入"
        input-align="right"
        maxlength="30"
      />
      <van-cell
        v-if="this.isEdit == false"
        title="备注"
        :value="$route.query.memo"
      />
    </van-cell-group>
    <div
      v-if="this.riskStatementVo.risk == 1 || this.riskStatementVo.risk == '1'"
    >
      <div><h5>风险信息</h5></div>
      <van-cell-group inset>
        <van-cell style="color: red" :title="this.riskStatementVo.statement" />
      </van-cell-group>
    </div>

    <h5 style="width: 90%">
      物料列表
      <button
        v-if="this.isEdit == true"
        @click="showView"
        style="float: right"
        class="clearBtn"
      >
        <!-- <van-icon name="plus" /> -->
        新增
      </button>
    </h5>
    <van-cell-group
      v-for="(item, index) in this.lookDatalist"
      :key="item"
      inset
    >
      <van-cell>
        <!-- <van-row>
          <van-col span="4">名称：</van-col>
          <van-col span="11">{{ item.name }}</van-col>
          <van-col span="5">{{ item.purchaseWay }}</van-col>
          <van-col span="4"> 删除 </van-col>
        </van-row> -->
        <!-- <div>
          <span>名称{{ "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0" }}</span
          ><span>{{ item.name }}</span
          ><span style="color: rgb(0, 158, 237)"
            >{{ "\u00A0\u00A0\u00A0" }}[{{ item.purchaseWay }}]</span
          >
          <button
            v-if="this.isEdit == true"
            class="deleteBtn"
            @click="deleteList(index)"
          >
            删除
          </button>
        </div> -->
        <van-row>
          <van-col span="6">名称：</van-col>
          <van-col span="14">{{ item.name }}</van-col>
          <van-col
            v-if="this.isEdit == true"
            @click="deleteList(index)"
            style="color: rgb(0, 158, 237); text-align: right"
            span="4"
            >删除</van-col
          >
        </van-row>
        <van-row>
          <van-col span="6">采购方式：</van-col>
          <van-col style="color: rgb(0, 158, 237)" span="18"
            >[{{ item.purchaseWay }}]</van-col
          >
        </van-row>
        <van-row>
          <van-col span="6">品牌：</van-col>
          <van-col span="18">{{ item.pinpai }}</van-col>
        </van-row>
        <van-row>
          <van-col span="6">型号：</van-col>
          <van-col span="18">{{ item.xinghao }}</van-col>
        </van-row>
        <van-row>
          <van-col span="6">数量：</van-col>
          <van-col span="18">{{ item.count }}{{ item.danwei }}</van-col>
        </van-row>
        <van-row>
          <van-col span="6">备注：</van-col>
          <van-col v-if="item.remark" span="18">{{ item.remark }}</van-col>
          <van-col v-if="item.remark == ''" span="18">暂无</van-col>
        </van-row>
      </van-cell>
    </van-cell-group>

    <div
      v-if="
        this.imagePathList.length > 0 || this.btnStates == 'editCancelState'
      "
    >
      <div><h5>附件信息</h5></div>
      <van-cell-group inset>
        <van-uploader
          class="preview-cover"
          v-model="this.photoList200"
          :after-read="afterReadPhoto"
          :before-delete="beforeDeletePhone"
          :max-size="12 * 1024 * 1024"
          @oversize="onOversize"
          multiple
          :max-count="9"
          :deletable="this.isEdit"
          :show-upload="this.isEdit"
        />
      </van-cell-group>
    </div>
    <van-popup
      v-model:show="wlshow"
      round
      :style="{ width: '80%', height: '80%' }"
    >
      <van-steps direction="vertical" :active="0">
        <van-step v-for="item in wuliuList" :key="item">
          <h4>{{ item.context }}</h4>
          <p>{{ formatDate(item.ftime) }}</p>
        </van-step>
      </van-steps>
    </van-popup>
    <div v-if="$route.query.state == 'SEND'">
      <div><h5>物流信息</h5></div>
      <van-cell-group inset>
        <van-cell
          v-for="item in logisticsList"
          :key="item"
          @click="lookLogistics(item)"
          :title="item.code"
          :value="item.fhCode"
        />
      </van-cell-group>
    </div>
    <div v-if="$route.query.state == 'RETURNED'">
      <div><h5>退货信息</h5></div>
      <van-cell-group inset>
        <van-cell
          v-for="item in returnWuliuList"
          :key="item"
          @click="lookLogistics(item)"
          :title="item.code"
          :value="item.fhCode"
        />
      </van-cell-group>
    </div>
    <!-- 弹出取消物料订单 -->
    <van-popup v-model:show="show" round :style="{ width: '80%' }">
      <span
        style="
          line-height: 33px;
          text-align: center;
          display: block;
          font-size: 14px;
          height: 33px;
        "
        >取消申请</span
      >
      <van-cell-group inset>
        <van-field
          v-model="cancelTitle"
          rows="4"
          autosize
          type="textarea"
          placeholder="请输入取消理由"
          maxlength="100"
          show-word-limit
        />
      </van-cell-group>
      <div style="height: 66px">
        <van-button
          type="danger"
          size="large"
          round
          style="
            display: block;
            margin: 0 auto;
            width: 65%;
            height: 44px;
            top: 11px;
          "
          @click="cancelSubmitBtn()"
        >
          取消
        </van-button>
      </div>
    </van-popup>
    <van-popup v-model:show="showWuliuPicker" round position="bottom">
      <van-picker
        :columns="wuliuDataList"
        @cancel="showWuliuPicker = false"
        @confirm="onWuliuConfirm"
      />
    </van-popup>
    <!-- <van-popup v-model:show="show" teleport="body" /> -->
  </div>
  <!-- 物料pop -->
  <van-popup v-model:show="wuliaoShow" round :style="{ width: '80%' }">
    <span
      style="
        line-height: 33px;
        text-align: center;
        display: block;
        font-size: 14px;
        height: 33px;
      "
      >添加物料</span
    >
    <van-field
      v-model="name"
      label="材料名称"
      placeholder="请输入"
      required
      maxlength="16"
      input-align="right"
    />
    <van-field
      v-model="brand"
      label="品牌"
      placeholder="请输入"
      required
      maxlength="16"
      input-align="right"
    />
    <van-field
      v-model="model"
      label="型号"
      placeholder="请输入"
      required
      maxlength="16"
      input-align="right"
    />
    <van-field
      v-model="number"
      label="数量"
      placeholder="请输入"
      required
      type="number"
      maxlength="6"
      input-align="right"
    />
    <van-field
      v-model="result"
      readonly
      label="单位"
      placeholder="请选择"
      input-align="right"
      required
      @click="showPicker = true"
    />
    <van-field
      v-model="modeResult"
      readonly
      label="采购方式"
      placeholder="请选择"
      input-align="right"
      required
      @click="modeShowPicker = true"
    />
    <van-field
      v-model="remarks"
      label="备注"
      placeholder="请输入"
      maxlength="30"
      input-align="right"
    />
    <div style="height: 66px">
      <van-button
        type="primary"
        size="large"
        round
        style="
          display: block;
          margin: 0 auto;
          width: 65%;
          height: 44px;
          top: 11px;
        "
        @click="confirmBtn(result, modeResult)"
      >
        确定
      </van-button>
    </div>
  </van-popup>
  <!-- 物料选择单位以及采购方式时弹出 -->
  <van-popup v-model:show="showPicker" round position="bottom">
    <van-picker
      :columns="columns"
      @cancel="showPicker = false"
      @confirm="onConfirm"
    />
  </van-popup>
  <van-popup v-model:show="modeShowPicker" round position="bottom">
    <van-picker
      :columns="modeColumns"
      @cancel="modeShowPicker = false"
      @confirm="modeOnConfirm"
    />
  </van-popup>
  <!-- 退货 -->
  <van-popup
    v-model:show="tuihuoShow"
    round
    :style="{ width: '90%', height: '35%' }"
  >
    <span
      style="
        text-align: center;
        display: block;
        line-height: 33px;
        text-align: center;
        display: block;
        font-size: 14px;
        height: 33px;
      "
      >填写退货信息</span
    >
    <van-field
      v-model="result"
      readonly
      label="快递公司"
      placeholder="请选择"
      @click="showWuliuPicker = true"
      required
    />
    <van-field
      v-model="kuaididanhao"
      label="快递单号"
      placeholder="请输入"
      required
    />
    <!-- <span></span> -->
    <van-field
      v-model="tuihaoPhone"
      label="电话号码"
      placeholder="请输入收或寄件人的电话号码"
      type="digit"
    />
    <van-tag type="danger">注：收、寄件人的电话号码（手机和固定电话均可，只能填写一个，顺丰速运和丰网速运必填，其他快递公司选填。如座机号码有分机号，分机号无需传入。）</van-tag>
    <br />
    <van-button
      style="
        display: block;
        margin: 0 auto;
        width: 65%;
        height: 44px;
        top: 11px;
      "
      type="primary"
      size="large"
      round
      :disabled="disable"
      @click="querentuihuo(result, reindex)"
      >确认退货</van-button
    >
  </van-popup>
  <!-- 底部按钮 -->
  <div
    v-if="this.btnStates == 'receivingState'"
    class="footer"
    style="width: 95%; margin-left: 10px"
  >
    <van-button
      type="primary"
      size="large"
      round
      :disabled="disable"
      @click="receiving"
      >确认收货</van-button
    >
  </div>
  <div
    v-if="this.btnStates == 'cancelState'"
    class="footer"
    style="width: 95%; margin-left: 10px"
  >
    <van-button
      type="primary"
      size="large"
      round
      :disabled="disable"
      @click="cancelBtn()"
      >取消申请</van-button
    >
  </div>
  <div
    v-if="this.btnStates == 'returnGoodsState'"
    class="footer"
    style="width: 95%; margin-left: 10px"
  >
    <van-button
      type="primary"
      size="large"
      round
      :disabled="disable"
      @click="returnGoodsBtn()"
      >退货</van-button
    >
  </div>
  <div
    class="footer"
    v-if="this.btnStates == 'editCancelState'"
    style="width: 95%; margin-left: 10px"
  >
    <van-button
      class="twoBtn"
      type="primary"
      size="large"
      round
      :disabled="disable"
      @click="editBtn(dataDHRQ)"
      >修改申请</van-button
    >
    <van-button
      class="twoBtn"
      type="danger"
      size="large"
      round
      :disabled="disable"
      @click="cancelBtn()"
      >取消申请</van-button
    >
  </div>
</template>

<script>
import NavBar from "../../view/NavBar.vue";
import { ref } from "vue";
import { Toast } from "_vant@3.4.5@vant";
import {
  Express,
  Confirmreceipt,
  Airclosedmaterial,
  Updateairmaterial,
  Iscompany,
  Returnmreceipt,
} from "../../api";
import { Dialog } from "vant";
// import { compressImage } from "../config/utils";
import compressor from "compressorjs";
import axios from "axios";
import global from "@/js/global";
// import { fa } from "_element-plus@2.0.5@element-plus/lib/locale";
// import Qs from "qs"; // 传参需要用qs转换一下

export default {
  components: {
    NavBar,
  },
  setup() {
    // 计划日期
    const dataDHRQ = ref("");
    const showDHRQ = ref(false);
    const dateDHRQLong = ref("");

    const showWuliuPicker = ref(false);
    const reindex = ref("");

    const columns = ["块", "件", "套", "个", "KG", "米", "MM（毫米）"];
    const result = ref("");
    const showPicker = ref(false);

    const modeColumns = ["自采", "工厂发货"];
    const modeResult = ref("");
    const modeShowPicker = ref(false);

    const onWuliuConfirm = (value, index) => {
      reindex.value = index;
      result.value = value;
      showWuliuPicker.value = false;
    };

    const formatDateDHRQ = (dateDHRQ) => {
      var year = dateDHRQ.getFullYear();
      var month = dateDHRQ.getMonth() + 1;
      var strDate = dateDHRQ.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    };
    const onConfirmDHRQ = (valueDHRQ) => {
      showDHRQ.value = false;
      dateDHRQLong.value = valueDHRQ;
      dataDHRQ.value = formatDateDHRQ(valueDHRQ);
    };

    const onConfirm = (value) => {
      result.value = value;
      showPicker.value = false;
    };

    const modeOnConfirm = (value) => {
      modeResult.value = value;
      modeShowPicker.value = false;
    };
    return {
      // ...toRefs(state),
      dataDHRQ,
      showDHRQ,
      formatDateDHRQ,
      onConfirmDHRQ,
      onWuliuConfirm,

      result,
      columns,
      onConfirm,
      showPicker,

      modeResult,
      modeOnConfirm,
      modeColumns,
      modeShowPicker,
      showWuliuPicker,
      reindex,

      maxDate: new Date(
        new Date().getFullYear() + 1,
        new Date().getMonth() + 1,
        new Date().getDate()
      ),
    };
  },
  data() {
    return {
      cancelTitle: "",
      list: [],
      listString: "",
      photoList: [],
      lookDatalist: [],
      photoList200: [],
      imagePathList: [],
      logisticsList: [],
      returnWuliuList: [],
      wuliuList: [],
      wlshow: false,
      wuliaoShow: false,
      show: false, //取消物料显示
      isEdit: true,
      btnStates: "",
      memo: "", // 备注
      // 物料内容
      name: "",
      brand: "",
      model: "",
      number: "",
      company: "",
      remarks: "",
      // 收货人信息
      addressDict: { username: "", phone: "", address: "" },
      disable: false,
      //退货
      tuihuoShow: false,
      wuliuDataList: [],
      wuliuIDDataList: [],
      kuaididanhao: "",
      tuihaoPhone:"",
      // 物流风险
      riskStatementVo: { risk: "", "": "" },
      // 处理选择地址的问题
      z_isLook: true,
      // 退货
    };
  },
  watch: {
    name(newValue) {
      this.name = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    brand(newValue) {
      this.brand = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    model(newValue) {
      this.model = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    number(newValue) {
      this.number = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    company(newValue) {
      this.company = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    remarks(newValue) {
      this.remarks = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    memo(newValue) {
      this.memo = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    cancelTitle(newValue) {
      this.cancelTitle = newValue.replace(
        /[\uD800-\uDBFF][\uDC00-\uDFFF]/g,
        ""
      );
    },
  },
  methods: {
    onOversize(file) {
      console.log(file);
      Toast("照片过大");
    },
    receiving() {
      Dialog.confirm({
        title: "",
        message: "是否确认全部的物流已收到",
      })
        .then(() => {
          // Toast("收货");
          Confirmreceipt({
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
            orderid: this.$route.query.z_id,
            // num: item.code,
            ordermaterialid: this.$route.query.id,
          })
            .then((result) => {
              if (result.code == 1) {
                // 刷新数据
                Toast("确认收货成功");
                this.$router.go(-1);
              } else {
                Toast(result.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    lookLogistics(item) {
      this.wuliuList = [];
      // console.log(item.code);
      if (item.code && item.fhCode) {
        Express({
          token: this.$route.query.z_token,
          userid: this.$route.query.z_userid,
          com: item.code,
          // num: item.code,
          num: item.fhCode,
        })
          .then((result) => {
            if (result.code == 1) {
              this.wuliuList = result.data;
              // console.log(this.wuliuList);
              this.wlshow = true;
            } else {
              Toast(result.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 获取当前年月日
    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      var yy = new Date().getFullYear();
      var mm = new Date().getMonth() + 1;
      var dd = new Date().getDate();
      if (mm >= 1 && mm <= 9) {
        mm = "0" + mm;
      }
      if (dd >= 0 && dd <= 9) {
        dd = "0" + dd;
      }
      _this.gettime = yy + "-" + mm + "-" + dd;
      return _this.gettime;
    },
    // 时间戳转换
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          "-" +
          dt.getHours() +
          ":" +
          dt.getMinutes() +
          ":" +
          dt.getSeconds()
        );
      }
    },
    // 添加物料
    showView() {
      this.wuliaoShow = true;
    },
    // 物料提交
    confirmBtn(result, modeResult) {
      if (this.name == "") {
        Toast("请输入材料名称");
        return;
      }
      if (this.brand == "") {
        Toast("请输入品牌");
        return;
      }
      if (this.model == "") {
        Toast("请输入型号");
        return;
      }
      if (this.number == "") {
        Toast("请输入数量");
        return;
      }
      if (parseFloat(this.number) < 0) {
        Toast("请输入正确的数量");
        return;
      }
      if (result == "") {
        Toast("请选择单位");
        return;
      }
      if (modeResult == "") {
        Toast("请选择采购方式");
        return;
      }
      // if (this.remarks == "") {
      //   Toast("请输入备注");
      //   return;
      // }
      let addData = {
        name: this.name,
        pinpai: this.brand,
        xinghao: this.model,
        count: this.number,
        danwei: result,
        remark: this.remarks,
        purchaseWay: modeResult,
      };
      this.lookDatalist.push(addData);
      (this.name = ""),
        (this.brand = ""),
        (this.model = ""),
        (this.number = ""),
        // (this.company = ""),
        // (result.value = ""),
        (this.result = ""),
        (this.modeResult = ""),
        (this.remarks = ""),
        (this.wuliaoShow = false);
    },
    // 物料删除
    deleteList(index) {
      console.log(index);
      Dialog.confirm({
        title: "",
        message: "是否删除该条物料信息",
      })
        .then(() => {
          this.lookDatalist.splice(index, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    // 选择地址
    jumpAddress() {
      if (this.isEdit == true) {
        this.z_isLook = false;
        let query = this.$route.query;
        query.z_isTap = true;
        this.$router.push({
          path: "/MyAddress",
          query: query,
        });
      }
    },
    // 取消申请
    cancelBtn() {
      this.show = true;
    },
    cancelSubmitBtn() {
      Dialog.confirm({
        title: "",
        message: "是否取消该物料申请",
      })
        .then(() => {
          Airclosedmaterial({
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
            materialid: this.$route.query.id,
            // num: item.code,
            closedmemo: this.cancelTitle,
          })
            .then((result) => {
              if (result.code == 1) {
                Toast("取消成功");
                this.$router.go(-1);
              } else {
                Toast(result.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    editBtn(dateDHRQ) {
      const that = this;
      console.log(this.imagePathList);
      if (this.lookDatalist.length <= 0) {
        Toast("请添加所需要的物料信息");
        return;
      }
      this.disable = true;
      Updateairmaterial({
        materialid: this.$root.$route.query.id,
        appdate: this.getCurrentTime(),
        endDate: dateDHRQ,
        address: this.addressDict.address,
        linkman: this.addressDict.username,
        phonenum: this.addressDict.phone,
        memo: this.memo,
        ordercailiaolist: JSON.stringify(this.lookDatalist),
        orderid: this.$root.$route.query.orderId,
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        cailiaophotolist: that.imagePathList,
      })
        .then((result) => {
          this.disable = false;
          if (result.code == 1) {
            Toast("提交成功");
            this.$router.go(-1);
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          this.disable = false;
          console.log(err);
        });
    },
    // 退货
    returnGoodsBtn() {
      this.tuihuoShow = true;
    },
    querentuihuo(result, reindex) {
      // console.log(11111111111);
      // console.log(result);
      // console.log(reindex);
      // console.log(this.wuliuIDDataList[parseInt(reindex)]);
      // this.showPicker = true;
      // console.log();
      if (result == "") {
        Toast("请选择快递公司");
        return;
      }
      if (this.kuaididanhao == "") {
        Toast("请输入快递单号");
        return;
      }
      // if (this.tuihaoPhone == "") {
      //   Toast("请输入收或寄件人的电话号码");
      //   return;
      // }
      this.disable = true;
      Returnmreceipt({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        orderid: this.$root.$route.query.orderId,
        // num: item.code,
        ordermaterialid: this.$root.$route.query.id,
        returncode: this.kuaididanhao,
        expresscompany: result,
        expresscompanyid: this.wuliuIDDataList[parseInt(reindex)],
        phone: this.tuihaoPhone,
      })
        .then((result) => {
          this.disable = false;
          if (result.code == 1) {
            Toast("提交成功");
            this.$router.go(-1);
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          this.disable = false;
          console.log(err);
        });
    },
    // 上传照片
    afterReadPhoto(file) {
      const that = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      if (file.length >= 1) {
        file.forEach((item, index) => {
          let qua = 0.2;
          if (item.file.size > 1 * 1024 * 1024) {
            qua = 0.2;
          } else {
            qua = 0.8;
          }
          new compressor(item.file, {
            quality: qua,
            success(result) {
              const formData = new FormData();
              formData.append("photo", result, result.name);
              formData.append("token", that.$route.query.z_token);
              formData.append("userid", that.$route.query.z_userid);
              let config = {
                headers: {
                  //添加请求头
                  "Content-Type": "multipart/form-data",
                },
              };
              axios
                .post(global.apiUrl + "/upload/uploadphoto", formData, config)
                .then((resultData) => {
                  if (resultData.data.code == 1) {
                    that.imagePathList.push(resultData.data.data);
                    if (file.length - 1 == index) {
                      Toast.clear();
                      Toast("上传成功");
                    }
                  } else {
                    if (file.length - 1 == index) {
                      Toast.clear();
                      Toast("上传失败");
                      that.photoList.splice(index, 1);
                      that.imagePathList.splice(index, 1);
                    }
                  }
                })
                .catch((err) => {
                  if (file.length - 1 == index) {
                    Toast.clear();
                    Toast("上传失败");
                    that.photoList.splice(index, 1);
                    that.imagePathList.splice(index, 1);
                  }
                  console.log(err);
                });
            },
          });
          // if (item.file.size > 1 * 1024 * 1024) {
          //   compressImage(item.content, item.file.size).then((result) => {
          //     Addphoto({
          //       token: this.$route.query.z_token,
          //       userid: this.$route.query.z_userid,
          //       signphoto: result,
          //     })
          //       .then((result) => {
          //         if (result.code == 1) {
          //           if (file.length - 1 == index) {
          //             Toast.clear();
          //             Toast("上传成功");
          //           }
          //           this.imagePathList.push(result.data);
          //         } else {
          //           if (file.length - 1 == index) {
          //             Toast.clear();
          //             Toast("上传失败");
          //           }
          //           // file.status = "failed";
          //           // file.message = "上传失败";
          //         }
          //       })
          //       .catch((err) => {
          //         if (file.length - 1 == index) {
          //           Toast.clear();
          //           Toast("上传失败");
          //         }
          //         console.log(err);
          //       });
          //     // photo.push(result);
          //   });
          // } else {
          //   Addphoto({
          //     token: this.$route.query.z_token,
          //     userid: this.$route.query.z_userid,
          //     signphoto: item.content,
          //   })
          //     .then((result) => {
          //       if (result.code == 1) {
          //         if (file.length - 1 == index) {
          //           Toast.clear();
          //           Toast("上传成功");
          //         }
          //         this.imagePathList.push(result.data);
          //       } else {
          //         if (file.length - 1 == index) {
          //           Toast.clear();
          //           Toast("上传失败");
          //         }
          //         // file.status = "failed";
          //         // file.message = "上传失败";
          //       }
          //     })
          //     .catch((err) => {
          //       if (file.length - 1 == index) {
          //         Toast.clear();
          //         Toast("上传失败");
          //       }
          //       console.log(err);
          //     });
          // }
        });
      } else {
        file.status = "uploading";
        file.message = "上传中...";
        let qua = 0.2;
        if (file.file.size > 1 * 1024 * 1024) {
          qua = 0.2;
        } else {
          qua = 0.8;
        }
        new compressor(file.file, {
          quality: qua,
          success(result) {
            console.log(result);
            const formData = new FormData();
            formData.append("photo", result, result.name);
            formData.append("token", that.$route.query.z_token);
            formData.append("userid", that.$route.query.z_userid);
            let config = {
              headers: {
                //添加请求头
                "Content-Type": "multipart/form-data",
              },
            };
            axios
              .post(global.apiUrl + "/upload/uploadphoto", formData, config)
              .then((resultData) => {
                if (resultData.data.code == 1) {
                  Toast("上传成功");
                  file.status = "done ";
                  that.imagePathList.push(resultData.data.data);
                } else {
                  Toast("上传失败");
                  file.status = "failed";
                  file.message = "上传失败";
                }
              })
              .catch((err) => {
                Toast("上传失败");
                console.log(err);
              });
          },
        });
        // if (file.file.size > 1 * 1024 * 1024) {
        //   compressImage(file.content, file.file.size).then((result) => {
        //     // console.log("压缩后的结果", result); // result即为压缩后的结果
        //     // console.log("压缩前大小", item.file.size);
        //     Addphoto({
        //       token: this.$route.query.z_token,
        //       userid: this.$route.query.z_userid,
        //       signphoto: result,
        //     })
        //       .then((result) => {
        //         if (result.code == 1) {
        //           file.status = "done ";
        //           Toast("上传成功");
        //           this.imagePathList.push(result.data);
        //           // 返回的url抓紧
        //         } else {
        //           Toast("上传失败");
        //           file.status = "failed";
        //           file.message = "上传失败";
        //         }
        //       })
        //       .catch((err) => {
        //         Toast("上传失败");
        //         console.log(err);
        //       });
        //   });
        // } else {
        //   Addphoto({
        //     token: this.$route.query.z_token,
        //     userid: this.$route.query.z_userid,
        //     signphoto: file.content,
        //   })
        //     .then((result) => {
        //       if (result.code == 1) {
        //         file.status = "done ";
        //         Toast("上传成功");
        //         this.imagePathList.push(result.data);
        //         // 返回的url抓紧
        //       } else {
        //         Toast("上传失败");
        //         file.status = "failed";
        //         file.message = "上传失败";
        //       }
        //     })
        //     .catch((err) => {
        //       Toast("上传失败");
        //       console.log(err);
        //     });
        // }
      }
    },
    // 删除
    beforeDeletePhone(index, detail) {
      // console.log(this.beforeCounterPhotoList[detail.index]);
      // console.log(detail.index);
      //name.index代表图片的索引
      Dialog.confirm({
        title: "",
        message: "是否删除该照片",
      })
        .then(() => {
          this.photoList200.splice(detail.index, 1);
          this.imagePathList.splice(detail.index, 1);
          // if (this.imagePathList[detail.index]) {
          //   Deletephoto({
          //     token: this.$route.query.z_token,
          //     userid: this.$route.query.z_userid,
          //     photourl: this.imagePathList[detail.index],
          //   })
          //     .then((result) => {
          //       if (result.code == 1) {
          //         Toast("删除成功");
          //         this.photoList.splice(detail.index, 1);
          //         this.imagePathList.splice(detail.index, 1);
          //       } else {
          //         Toast(result.message);
          //       }
          //     })
          //     .catch((err) => {
          //       console.log(err);
          //     });
          // } else {
          //   Toast("删除失败");
          // }
        })
        .catch(() => {
          // on cancel
        });
    },
    getWuliuData() {
      Iscompany({
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
      })
        .then((result) => {
          // this.wuliuList.push(...result.data);
          if (result.data.length > 0) {
            console.log(result);
            result.data.forEach((item) => {
              // console.log(item);
              this.wuliuDataList.push(item.name);
              this.wuliuIDDataList.push(item.id);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted: function () {
    console.log(this.$route.query);
    this.getWuliuData();
    this.lookDatalist = JSON.parse(this.$route.query.z_materialList);
    // console.log(11111111);
    // console.log(this.lookDatalist);
    // 备注默认值
    this.memo = this.$route.query.memo;
    this.dataDHRQ = this.$route.query.endDate;

    // 收货地址复制
    if (
      this.addressDict["username"] == "" ||
      this.addressDict["username"] == null
    ) {
      this.addressDict["username"] = this.$route.query.linkman;
      this.addressDict["phone"] = this.$route.query.phoneNum;
      this.addressDict["address"] = this.$route.query.address;
    }

    // this.addressDict.username = "1";
    // this.addressDict.phone = "2";
    // this.addressDict.address = "3";

    switch (this.$route.query.state) {
      case "CLOSED":
      case "RETURNED":
        this.isEdit = false;
        this.btnStates = "nothingState";
        break;
      case "NEW":
        this.isEdit = true;
        this.btnStates = "editCancelState";
        break;
      case "SEND":
        this.isEdit = false;
        this.btnStates = "receivingState";
        break;
      case "RECEIVE":
        this.isEdit = false;
        this.btnStates = "returnGoodsState";
        break;
      default:
        this.isEdit = false;
        this.btnStates = "cancelState";
        break;
    }
    // console.log(this.$route.query.photoList);
    // 循环一下
    if (this.$route.query.z_primaryPhotoList) {
      // if (this.$route.query.primaryPhotoList.length > 0) {
      // this.imagePathList = this.$route.query.primaryPhotoList;
      let photoList = JSON.parse(this.$route.query.z_primaryPhotoList);
      photoList.forEach((item) => {
        this.imagePathList.push(item);
        let parent = { url: item };
        this.photoList200.push(parent);
      });
    }
    // }
    console.log(222222222);
    console.log(this.$route.query);
    // 添加物料信息
    if (this.$route.query.z_fahuoWuliuCompany1 && this.$route.query.z_fahuoWuliuCompany1 != 'null') {
      // let fahuoWuliuC = JSON.parse(this.$route.query.fahuoWuliuCompany1);
      let fahuo = JSON.parse(this.$route.query.z_fahuoWuliuCompany1);
      this.logisticsList.push({
        fhCode: fahuo.remark,
        code: fahuo.name,
      });
    }
    // console.log(this.logisticsList);
    if (this.$route.query.z_fahuoWuliuCompany2 && this.$route.query.z_fahuoWuliuCompany2 != 'null') {
      let fahuo = JSON.parse(this.$route.query.z_fahuoWuliuCompany2);
      this.logisticsList.push({
        fhCode: fahuo.remark,
        code: fahuo.name,
      });
    }
    if (this.$route.query.z_fahuoWuliuCompany3 && this.$route.query.z_fahuoWuliuCompany3 != 'null') {
      let fahuo = JSON.parse(this.$route.query.z_fahuoWuliuCompany3);
      this.logisticsList.push({
        fhCode: fahuo.remark,
        code: fahuo.name,
      });
    }
    if (this.$route.query.z_returnWuliuCompany && this.$route.query.z_returnWuliuCompany != 'null') {
      let fahuo = JSON.parse(this.$route.query.z_returnWuliuCompany);
      this.returnWuliuList.push({
        fhCode: fahuo.remark,
        code: fahuo.name,
      });
    }
    if (this.$route.query.z_riskStatementVo) {
      this.riskStatementVo = JSON.parse(this.$route.query.z_riskStatementVo && this.$route.query.z_returnWuliuCompany != 'null');
    }
  },
  created() {
    // let st = localStorage.getItem("st");
    // console.log(st);
    // if (this.z_isLook == false) {
    // 如果是从提醒、重复页面返回的
    let stack = localStorage.getItem("stack");
    console.log(stack);
    if (stack) {
      this.addressDict = JSON.parse(stack);
      localStorage.removeItem("stack");
    }
    // }

    // console.log(1111);
    // console.log(stack);
    // if (stack == "pop") {
    //   return;
    // }
  },
};
</script>

<style scoped>
div .main {
  width: 100%;
  height: max-content;
  padding-top: 0px;
  background-color: #f8f8f8;
  padding-bottom: 80px;
}
.addressSpan {
  background-color: black;
  color: rgb(0, 158, 237);
  font-size: 13px;
  /* height: 200px; */
}
.topSpan {
  background-color: rgb(0, 158, 237);
  width: 16.66%;
  border: 1 solid rgb(110, 110, 110);
  text-align: center;
  color: white;
  font-size: 13px;
}
.contentSpan {
  background-color: white;
  width: 16.66%;
  /* border: 1% solid rgb(110, 110, 110); */
  text-align: center;
  color: black;
  font-size: 13px;
}
.lookBtn {
  color: rgb(68, 68, 239);
  width: 14.28%;
  border: none;
  background-color: white;
  font-size: 13px;
  text-align: center;
}
.clearBtn {
  border: none;
  background-color: #f8f8f8;
}
.preview-cover {
  margin: 5px 0 0 10px;
}
h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(136, 145, 145);
  text-align: left;
  /* b
  ackground-color: clear; */
}
div .footer {
  height: 60px;
  background-color: #f8f8f8;
  position: fixed;
  bottom: 0;
}
.deleteBtn {
  color: rgb(0, 158, 239);
  width: 20.28%;
  border: none;
  background-color: white;
  font-size: 13px;
  text-align: right;
  float: right;
}
.twoBtn {
  width: 45%;
  margin: 0px 3% 0px 2%;
}
:deep(.van-field__control--right) {
  color: rgb(143, 144, 146);
}
</style>
