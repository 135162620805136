<template>
  <div class="main" style="padding-top: 46px">
    <!-- 顶部导航 -->
    <NavBar z_left_arrow title="订单详情" fixed="true" />

    <div><h5>基本信息</h5></div>
    <van-cell-group inset>
      <van-cell title="订单编号" :value="$route.query.code" align="left" />
      <van-cell title="订单标签">
        <span
          v-if="$route.query.relationFlag == 2"
          style="display: inline-block; color: rgb(253, 184, 120)"
        >
          [子单]
        </span>
        <span
          v-if="$route.query.relationFlag == 1"
          style="display: inline-block; color: rgb(253, 116, 118)"
        >
          [母单]
        </span>
        <span
          v-if="$route.query.relationFlag == 0"
          style="display: inline-block; color: rgb(74, 175, 202)"
        >
          [原单]
        </span>
        <span
          v-if="$route.query.orderLevel == 3"
          style="display: inline-block; color: red"
        >
          [紧急]
        </span>
        <span
          v-if="$route.query.preOrder == 'true'"
          style="display: inline-block; color: rgb(97, 125, 83)"
        >
          [预备]
        </span>
      </van-cell>
      <van-cell
        v-if="$route.query.name"
        title="订单名称"
        :value="$route.query.name"
        align="left"
      />
      <van-cell
        v-if="$route.query.createdAt"
        title="下单日期"
        :value="formatDate(parseInt($route.query.createdAt))"
        align="left"
      />
      <van-cell
        v-if="$route.query.planEnterStartDate"
        title="计划进场日期"
        :value="$route.query.planEnterStartDate"
        align="left"
      />
      <van-cell
        v-if="$route.query.askDate"
        title="计划完工日期"
        :value="$route.query.askDate"
        align="left"
      />
      <van-cell
        v-if="$route.query.place"
        title="商场地址"
        :value="$route.query.place"
        align="left"
      />
      <div v-if="$route.query.remark != null && $route.query.remark != ''">
        <van-cell
          v-if="$route.query.remark"
          title="备注"
          :value="$route.query.remark"
          align="left"
        />
      </div>
    </van-cell-group>

    <div
      v-if="this.riskStatementVo.risk == 1 || this.riskStatementVo.risk == '1'"
    >
      <div><h5>风险信息</h5></div>
      <van-cell-group inset>
        <van-cell style="color: red" :title="this.riskStatementVo.statement" />
      </van-cell-group>
    </div>

    <div><h5>大区计划</h5></div>
    <van-cell-group inset>
      <van-cell
        v-if="$route.query.jhjcsgTime"
        title="计划进场施工开始日期："
        :value="formatDate(parseInt($route.query.jhjcsgTime))"
        align="left"
      />
      <van-cell
        v-if="$route.query.jhjcsgTimeEnd"
        title="计划进场施工结束日期："
        :value="formatDate(parseInt($route.query.jhjcsgTimeEnd))"
        align="left"
      />
      <!-- <div v-if="$route.query.isNeedMateriel === 'true'"> -->
      <van-cell
        v-if="$route.query.isNeedMateriel === 'true'"
        title="是否需要物料："
        :value="$route.query.isNeedMateriel === 'true' ? '需要' : '不需要'"
        align="left"
      />
      <van-cell
        v-if="$route.query.jhwldhTime"
        title="计划物料到货日期："
        :value="formatDate(parseInt($route.query.jhwldhTime))"
        align="left"
      />
      <!-- </div> -->
      <van-cell
        v-if="$route.query.jhwgTime"
        title="计划完工日期："
        :value="formatDate(parseInt($route.query.jhwgTime))"
        align="left"
      />
    </van-cell-group>

    <!-- this.orderSendLog != {} &&
        this.orderSendLog != null && -->
    <div
      v-if="
        $route.query.z_itemState === 'RECEIVE' ||
        $route.query.z_itemState === 'CLOSED'
      "
    >
      <h5>个人计划</h5>
    </div>
    <van-cell-group
      inset
      v-if="
        $route.query.z_itemState === 'RECEIVE' ||
        $route.query.z_itemState === 'CLOSED'
      "
    >
      <van-cell
        v-if="this.detailsHomeData.airJhjcsgTime"
        title="计划进场施工开始日期："
        :value="formatDate(parseInt(this.detailsHomeData.airJhjcsgTime))"
        align="left"
      />
      <van-cell
        v-if="this.detailsHomeData.airJhwgTime"
        title="计划进场施工结束日期："
        :value="formatDate(parseInt(this.detailsHomeData.airJhwgTime))"
        align="left"
      />
      <div v-if="this.detailsHomeData.airIsNeedMateriel === true">
        <van-cell
          title="是否需要物料："
          :value="
            this.detailsHomeData.airIsNeedMateriel === true ? '需要' : '不需要'
          "
          align="left"
        />
        <van-cell
          title="计划物料到货日期："
          :value="formatDate(parseInt(this.detailsHomeData.airJhwldhTime))"
          align="left"
        />
      </div>
      <!-- <van-cell
        title="计划完工日期："
        :value="formatDate(parseInt(this.detailsHomeData.airJhwgTime))"
        align="left"
      /> -->
    </van-cell-group>
    <div><h5>联系方式</h5></div>
    <van-cell-group inset>
      <van-cell title="下单人:" :value="this.detailsData.name" align="left" />
      <van-cell
        title="下单联系人电话:"
        align="left"
        @click="callPhone(this.detailsData.phone)"
      >
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <van-icon name="phone" class="phone-icon" />
          <span class="right-title">{{ this.detailsData.phone }}</span>
        </template>
      </van-cell>
      <!-- <div
        v-if="$route.query.dfContact != null && $route.query.dfContact != ''"
      > -->
      <van-cell
        v-if="$route.query.dfContact != null && $route.query.dfContact != ''"
        title="店铺联系人"
        :value="$route.query.dfContact"
        align="left"
      />
      <van-cell
        v-if="$route.query.dfContact != null && $route.query.dfContact != ''"
        title="店铺联系人电话:"
        align="left"
        @click="callPhone($route.query.dfContactor)"
      >
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <van-icon name="phone" class="phone-icon" />
          <span class="right-title">{{ $route.query.dfContactor }}</span>
        </template>
      </van-cell>
      <!-- </div> -->
      <div
        v-if="$route.query.jfContact != null && $route.query.jfContact != ''"
      >
        <van-cell
          title="甲方联系人"
          :value="$route.query.jfContact"
          align="left"
        />
        <van-cell
          title="甲方联系人电话:"
          align="left"
          @click="callPhone($route.query.jfContactor)"
        >
          <!-- 使用 right-icon 插槽来自定义右侧图标 -->
          <template #right-icon>
            <van-icon name="phone" class="phone-icon" />
            <span class="right-title">{{ $route.query.jfContactor }}</span>
          </template>
        </van-cell>
      </div>
    </van-cell-group>

    <div><h5>任务描述</h5></div>
    <van-cell-group inset>
      <van-cell v-for="(item, index) in orderWorkList" :key="item">
        <template #title>
          <span>{{ index + 1 }}. {{ item.workContent }} </span>
          <div class="cell_img" v-if="item.orderFileList.length > 0">
            <p>附件：</p>
            <img
              v-for="(imageItem, index) in item.orderFileList"
              :key="index"
              :src="imageItem"
              alt=""
              @click="lookImage(item.orderFileList, index)"
            />
          </div>
          <van-row>
            <van-col span="10">大区建议施工方式：</van-col>
            <van-col span="14">{{ item.workContent }}</van-col>
          </van-row>
          <van-row>
            <van-col span="10">驻点施工方式：</van-col>
            <van-col span="14">{{ item.workContent }}</van-col>
          </van-row>
          <!-- <span class="describe-details"
            >大区建议施工方式：{{ item.workContent }}
          </span>
          <div>
            <span class="describe-details-wx"
              >驻点施工方式：{{ item.workContent }}
            </span> -->
          <!-- <span class="button-span"> v-if="$route.query.z_itemState != 'SENDED'"
              <a class="long-btn" @click="editContent">修改</a>
            </span> -->
          <!-- </div> -->
        </template>
      </van-cell>
    </van-cell-group>

    <div
      v-if="
        this.orderSendLog != {} &&
        this.orderSendLog != null &&
        $route.query.z_itemState === 'DENY'
      "
    >
      <div>
        <h5>拒单理由</h5>
      </div>
      <van-cell-group inset>
        <span class="describe-details">{{ this.orderSendLog.action }} </span>
      </van-cell-group>
    </div>
  </div>
  <div class="footer" v-if="$route.query.z_itemState === 'SENDED'">
    <van-button
      class="successBtn"
      type="success"
      size="normal"
      round
      @click="orderReceiving"
      >接单</van-button
    >
    <van-button
      class="successBtn"
      type="warning"
      size="normal"
      round
      @click="rejection"
      >拒单</van-button
    >
  </div>
  <div class="footer" v-if="$route.query.z_itemState === 'RECEIVE'">
    <van-button
      class="receivedBtn"
      type="warning"
      size="normal"
      round
      @click="plan"
      >计划</van-button
    >
    <van-button
      class="receivedBtn"
      type="success"
      size="normal"
      round
      @click="reimbursement"
      >报销</van-button
    >
    <van-button
      class="receivedBtn"
      type="primary"
      size="normal"
      round
      @click="implement"
      >执行</van-button
    >
    <van-button
      class="receivedBtn"
      type="danger"
      size="normal"
      round
      @click="material"
      >物料</van-button
    >
  </div>
  <div class="footer" v-if="$route.query.z_itemState === 'CLOSED'">
    <van-button
      class="planBtn"
      type="warning"
      size="normal"
      round
      @click="viewPhotos"
      >查看照片</van-button
    >
    <van-button
      class="planBtn"
      type="success"
      size="normal"
      round
      @click="viewAbill"
      >查看签单</van-button
    >
    <van-button
      class="planBtn"
      type="primary"
      size="normal"
      round
      @click="viewReimbursement"
      >查看报销</van-button
    >
    <van-button
      class="planBtn"
      type="danger"
      size="normal"
      round
      @click="viewOperatingCosts"
      >查看工费</van-button
    >
  </div>
</template>

<script>
import { Toast } from "vant";
import { ImagePreview } from "vant";
import { OrderDetails } from "../api";
import NavBar from "../view/NavBar.vue";
// import { imageViewerProps } from "_element-plus@2.0.4@element-plus/lib/components";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      ImagePreviewDialog: "",
      detailsHomeData: {},
      detailsData: {},
      orderWorkList: {},
      orderSendLog: {},
      imageList: [],
      riskStatementVo: { risk: "", "": "" },
      //Vue模板中使用v-for循环渲染图片时不能直接使用图片文件本地位置
      imgs1: [
        "https://img.yzcdn.cn/vant/cat.jpeg",
        "https://img.cap.cdhayaretail.com/group1/M00/00/00/rBE072Gto8GAXsVRAAJsnLXbU1g977_500x500.png",
        "https://img.yzcdn.cn/vant/cat.jpeg",
        "https://img.cap.cdhayaretail.com/group1/M00/00/00/rBE072GtpdiAIMfHAAIal4HtEyQ426_500x500.png",
        "https://img.yzcdn.cn/vant/cat.jpeg",
        "https://sop.dbshopplus.com/dianbangfile/enterprise/orderSignPhoto/VjmZQCEsXxHMsLRLLO6qn6U5yr-wRMhboBE6MIiJ1eH16cOAj1DPahl-6ADs2FVk.jpg",
      ],
    };
  },
  mounted: function () {
    // this.$nextTick(function () {
    // alert("details");
    // console.log(222, this.$route);
    // console.log(222 + this.$route.query.z_name);
    // var z_userid = localStorage.getItem("z_userid"); //获取指定key本地存储的值
    // var z_token = localStorage.getItem("z_token"); //获取指定key本地存储的值
    // console.log('我要打印的值：');
    // console.log(z_userid,z_token);
    window.OrderExe=this.OrderExe;
    this.getDetails();
    // console.log(11111);
    // console.log(this.$route.query.z_riskStatementVo);
    if (this.$route.query.z_riskStatementVo &&  this.$route.query.z_riskStatementVo != 'null') {
      // console.log(22222);
      this.riskStatementVo = JSON.parse(this.$route.query.z_riskStatementVo);
    }
    // });
  },
  methods: {
    // 接单
    orderReceiving() {
      this.customDetailsData = this.detailsHomeData;
      this.customDetailsData.jumpName = "接单";
      if (this.detailsHomeData) {
        this.$router.push({
          path: "/orderReceiving",
          query: this.customDetailsData,
        });
      }
    },
    // 拒单
    rejection() {
      if (this.detailsHomeData) {
        this.$router.push({
          path: "/DenyOrder",
          query: this.detailsHomeData,
        });
      }
    },
    // 计划
    plan() {
      this.customDetailsData = this.detailsHomeData;
      this.customDetailsData.jumpName = "计划";
      if (this.customDetailsData) {
        this.$router.push({
          path: "/orderReceiving",
          query: this.customDetailsData,
        });
      }
    },
    // 报销
    reimbursement() {
      this.$router.push({
        path: "/ReimburstMentPage",
        query: this.detailsHomeData,
      });
    },
    // 执行
    implement() {
      // 修改前
      // this.$router.push({ path: "/StepFlow", query: this.detailsHomeData });
      // 修改后
      // let s = this.status+'';
      // console.log(this.$route.query.id);
      if (this.$route.query.id) { 
        let que = {"ID":this.$route.query.id};
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          window.webkit.messageHandlers.uploadJobPic.postMessage({
            // title: this.$route.query.id, //vue给iOS传值
            title: que, //vue给iOS传值
          });
        } else {
          window.android.startActivity(this.$route.query.id);
          // window.android.onFinish();
        }
      }
    },
    // 物料
    material() {
      this.$router.push({
        path: "/MaterialRequisition",
        query: this.detailsHomeData,
      });
    },
    // 查看照片
    viewPhotos() {
      // Toast("查看照片");
      let query = this.$route.query;
      query.z_isSeePhones = "false";
      this.$router.push({
        path: "/UploadJobPic",
        // query: this.$route.query,
        query: query,
      });
    },
    // 查看签单
    viewAbill() {
      let query = this.$route.query;
      query.z_status = "3";
      this.$router.push({
        path: "/UploadSignPic",
        // query: this.$route.query,
        query: query,
      });
    },
    // 查看报销
    viewReimbursement() {
      this.$router.push({
        path: "/ReimburstMentPage",
        query: this.detailsHomeData,
      });
    },
    // 查看工费
    // viewOperatingCosts() {
    //   let list = [
    //     this.$route.query.z_userid,
    //     this.$route.query.z_token,
    //     "listDetails",
    //   ];
    //   this.$router.push({
    //     path: "/OperatingCosts",
    //     query: list,
    //   });
    // },
    // 查看工费
    viewOperatingCosts() {
      this.$router.push({
        path: "/CheckOperatingCosts",
        query: this.detailsHomeData,
      });
    },
    editContent() {
      Toast.success("修改内容");
    },
    lookImage(imgs, index) {
      this.ImagePreviewDialog = ImagePreview({
        images: imgs,
        startPosition: index,
      });
    },
    // 时间戳转换
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "") {
        return "";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " "
          // +
          // dt.getHours() +
          // ":" +
          // dt.getMinutes() +
          // ":" +
          // dt.getSeconds()
        );
      }
    },
    // 在原生点击完工回调方法
    OrderExe() {
      // 后退
      this.$router.go(-1);
    },
    // 电话
    callPhone(phone) {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.callPhoneiOS.postMessage({
          title: phone, //vue给iOS传值
        });
      } else {
        window.location.href = "tel:" + phone;
      }
    },
    // 返回
    onClickLeft() {
      // 跳转上一级。
      this.$router.go(-1);
    },
    getDetails() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      OrderDetails({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
        // userid: "24",
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        orderid: this.$route.query.id,
      })
        .then((result) => {
          Toast.clear();
          console.log(result.data);
          this.detailsHomeData = result.data;
          this.detailsData = result.data.createUserName;
          this.orderWorkList = result.data.orderWorkList;
          this.orderSendLog = result.data.orderSendLog;
          (this.detailsHomeData.z_token = this.$route.query.z_token),
            (this.detailsHomeData.z_userid = this.$route.query.z_userid),
            (this.detailsHomeData.z_name = this.$route.query.z_name),
            (this.detailsHomeData.z_phone = this.$route.query.z_phone),
            (this.detailsData = result.data.createUserName);
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
        });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div .main {
  width: 100%;
  height: max-content;
  padding-top: 0px;
  background-color: #f8f8f8;
  padding-bottom: 80px;
  /* bottom: 60px; */
  /* position: absolute; */
}

/* div {
  
} */

div h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(136, 145, 145);
  text-align: left;
  /* background-color: clear; */
}
div .gray_line {
  background: #e5e5e5;
  width: 100%;
  height: 0.1px;
  float: left;
  margin: 10px 5px;
}
.custom-title {
  margin-right: 4px;
  vertical-align: middle;
}

.phone-icon {
  font-size: 16px;
  line-height: inherit;
}

.successBtn {
  width: 35%;
  margin: 10px 7% 10px 7%;
}

.planBtn {
  width: 24%;
  margin: 10px 0.5% 10px 0.5%;
}

.receivedBtn {
  width: 20%;
  margin: 10px 3% 10px 2%;
}

.van-cell_value {
  text-align: left;
}

div .left-title {
  float: left;
  text-align: center;
}

div .right-title {
  color: gray;
  float: right;
}

.describe-details {
  float: left;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  /* border-bottom: 1px solid #d9d9d9; */
  display: flex;
  flex-direction: row;
}

.test {
  float: left;
  width: 25%;
  box-sizing: border-box;
  padding: 10px;
  min-width: 150px;
}

.container {
  width: 100%;
}

.cell_img {
  width: 100%;
  overflow: hidden;
}

.cell_img img {
  width: 31%;
  height: 6rem;
  float: left;
  margin-right: 8px;
  margin-bottom: 5px;
}

.long-btn {
  text-align: right;
  /* background: url(img/mail-tpl-view.gif) no-repeat; */
  font-size: 10px;
  width: 20%;
  line-height: 60px;
  color: rgb(119, 119, 209);
  text-decoration-line: underline;
}

.describe-details-wx {
  float: left;
  text-align: left;
  width: 80%;
  box-sizing: border-box;
  padding: 16px;
  /* border-bottom: 1px solid #d9d9d9; */
  display: flex;
  flex-direction: row;
}

.footer {
  height: 60px;
  width: 100%;
  background-color: #f8f8f8;
  position: fixed;
  bottom: 0px;
}
</style>
