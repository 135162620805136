<template>
  <div class="TurnSendPage" style="padding-top: 46px">
    <van-nav-bar
      title="订单转派"
      left-arrow
      @click-left="onClickBack"
      fixed
      safe-area-inset-top
    >
      ></van-nav-bar
    >
    <van-tabs
      v-model:active="active"
      swipeable
      sticky
      animated
      ref="tabs"
      offset-top="46px"
      title-active-color="#325cfe"
      @click="change"
    >
      <van-tab v-for="item in tabList" :key="item" :title="item.name">
        <turn-send-list :turnsend="item"></turn-send-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import turnSendList from "./TurnSendList.vue";
// import { Toast } from "vant";
// import { OrderList } from "../api";
export default {
  components: { turnSendList },
  name: "MapContainer",
  data() {
    return {
      active: 1,
      //  isShow: false,
      tabList: [
        {
          name: "未转派",
          state: "NEW",
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
        },
        {
          name: "已转派",
          state: "RECEIVE",
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
        },
      ],
    };
  },
  // watch: {
  //   isShow() {
  //     this.$nextTick(() => {
  //       this.$refs.tabs.resize();
  //     });
  //   },
  // },
  created() {
    setTimeout(() => {
      console.log("ssss");
      this.$refs.tabs.resize();
    }, 1);
  },
  methods: {
    change(name) {
      console.log(name);
      sessionStorage.setItem("active", name);
    },
    onClickBack() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.getDataFormVue.postMessage({
          title: "333", //vue给iOS传值
        });
      } else {
        window.android.onFinish();
      }
    },
    // ticketArrears() {
    //   this.$router.push({
    //     path: "/OweTicketCenter",
    //     query: this.detailsHomeData,
    //   });
    // },
  },
  mounted: function () {
    let data = "";
    switch (sessionStorage.getItem("active")) {
      case "1":
        data = 1;
        break;
      default:
        data = 0;
        break;
    }
    this.active = data;
  },
};
</script>

<style scoped>
:deep(.van-tabs__line) {
  background: white !important;
}
.van-nav-bar {
  background-color: #325cfe;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>
