// 刚刚封装的axios
import request from "./axios";
import Qs from "qs"; // 传参需要用qs转换一下
// 消息
export const News = (query) => {
  return request({
    url: "/message/messagemovementlist",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 查看所有订单列表
export const OrderList = (query) => {
  return request({
    url: "/order/getorderlist",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 查看所有订单
export const OrderDetails = (query) => {
  // console.log('这里可以打印吗?');
  // console.log(query);
  // var z_query = query;
  // var z_token = localStorage.getItem("z_token");
  // z_query["token"] = "1111";
  // query["userid"] = localStorage.getItem("z_userid");
  // console.log('打印修改后的?');
  // console.log(z_query);
  console.log(111);
  return request({
    url: "/order/orderdetails",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 接单或者拒单
export const ReceivingOrRejecting = (query) => {
  return request({
    url: "/order/receivingorrejecting",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 查看报销
export const OrderFeiyong = (query) => {
  return request({
    url: "/orderfeiyong/feiyong",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 报销录入
export const OrderRinputfeiyong = (query) => {
  return request({
    url: "/orderfeiyong/inputfeiyong",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 删除录入报销
export const OrderDeletefeiyong = (query) => {
  return request({
    url: "/orderfeiyong/deletefeiyong",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 修改个人计划
export const OrderUpdate = (query) => {
  return request({
    url: "order/updateorderplan",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 获取执行订单中的状态
export const OrderExecutionorder = (query) => {
  return request({
    url: "/order/getexecutionorder",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 签到
export const OrderSignin = (query) => {
  return request({
    url: "/order/ordersignin",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 上传签单照片
export const OrderUploadqiandanphoto = (query) => {
  return request({
    url: "/order/uploadqiandanphoto",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 订单签出
export const OrderDingdanup = (query) => {
  return request({
    url: "/order/ordercheckout",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 订单完工
export const OrderWangong = (query) => {
  return request({
    url: "/order/wangong",
    method: "post",
    data: Qs.stringify(query),
  });
};

// // 返回工作内容
// export const ReturnWork = (query) => {
//   return request({
//     url: "/order/returnwork",
//     method: "post",
//     data: Qs.stringify(query),
//   });
// };
// 返回工作内容
export const ReturnWork = (query) => {
  return request({
    url: "/order/viewOrderPhotos",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 上传门头照片
export const Uploadphoto = (query) => {
  return request({
    url: "/order/uploaddoorheadphoto",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 删除门头照片
export const Deletementouphoto = (query) => {
  return request({
    url: "/order/deletedoorheadphoto",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 上传施工柜台照片
export const Uploadsignphoto = (query) => {
  return request({
    url: "/order/uploadcounterphoto",
    method: "post",
    data: Qs.stringify(query, { arrayFormat: "repeat" }),
  });
};

// 上传施工照片
export const Uploadsigwphoto = (query) => {
  return request({
    url: "/order/uploadconstructionphoto",
    method: "post",
    data: Qs.stringify(query, { arrayFormat: "repeat" }),
  });
};

// 删除施工柜台照片
export const Deletesignphoto = (query) => {
  return request({
    url: "/order/deletecounterphoto",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 删除施工作项照片
export const Deletesigwphoto = (query) => {
  return request({
    url: "/order/deleteconstructionphoto",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 订单完工
export const Zhaopianisok = (query) => {
  return request({
    url: "/order/allcomplete",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 查看签单照片
export const Chakanqiandan = (query) => {
  return request({
    url: "/order/getqiandanphoto",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 查看欠票信息
export const UserOweList = (query) => {
  return request({
    url: "/owe/userowelist",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 工人物料申请
export const Airmaterial = (query) => {
  return request({
    url: "/material/airmaterial",
    method: "post",
    data: Qs.stringify(query, { indices: false }),
  });
};

// 物料跟踪列表
export const Viewitem = (query) => {
  return request({
    url: "/material/viewitem",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 上传单个图片
export const Addphoto = (query) => {
  return request({
    url: "/order/uploadphoto",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 删除单个图片
export const Deletephoto = (query) => {
  return request({
    url: "/material/deletephoto",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 物料确认收货
export const Confirmreceipt = (query) => {
  return request({
    url: "/material/confirmreceipt",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 查询快递
export const Express = (query) => {
  return request({
    url: "/material/express",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 物料退货
export const Returnmreceipt = (query) => {
  return request({
    url: "/material/returnmreceipt",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 订单工人工费
export const LaborCost = (query) => {
  return request({
    url: "/order/laborcost",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 返回区内工作人员
export const EtzhudianRegion = (query) => {
  return request({
    url: "/order/returnworkingpersonnel",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 查询个人最近接单评分
export const AirScore = (query) => {
  return request({
    url: "/order/getairscore",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 获取可以转派的所有订单
export const RedeployOrder = (query) => {
  return request({
    url: "/order/getredeployorder",
    method: "post",
    data: Qs.stringify(query),
  });
};
// 转派订单
export const Redeploy = (query) => {
  return request({
    url: "/order/redeployorder",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 转派拒单
export const Rejection = (query) => {
  return request({
    url: "/order/redeployrejectionorder",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 查看物流
export const Iscompany = (query) => {
  return request({
    url: "/material/iscompany",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 添加收货人收货地址
export const Addaddress = (query) => {
  return request({
    url: "/address/addaddress",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 删除收货人收货地址
export const Deleteaddress = (query) => {
  return request({
    url: "/address/deleteaddress",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 获取个人的所有收货地址
export const Getaddress = (query) => {
  return request({
    url: "/address/getaddress",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 修改收货人收货地址
export const Updateaddress = (query) => {
  return request({
    url: "/address/updateaddress",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 工人取消物料
export const Airclosedmaterial = (query) => {
  return request({
    url: "/material/airclosedmaterial",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 工人修改物料
export const Updateairmaterial = (query) => {
  return request({
    url: "/material/updateairmaterial",
    method: "post",
    data: Qs.stringify(query, { indices: false }),
  });
};

// 订单工人工费
export const getAircost = (query) => {
  return request({
    url: "/orderfeiyong/getaircost",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 确认工费
export const GetAirConfirmed = (query) => {
  return request({
    url: "/orderfeiyong/getairconfirmed",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 订单工人工费
export const GetAircost = (query) => {
  return request({
    url: "/orderfeiyong/getaircost",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 我的报销
export const Getuserreimbursementlist = (query) => {
  return request({
    url: "/orderfeiyong/getuserreimbursementlist",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 获取各个状态中的订单数量
export const Getorderstatecount = (query) => {
  return request({
    url: "/order/getorderstatecount",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 获取工人的所有报销
export const OrderfeiyongGetdenyfeiyong = (query) => {
  return request({
    url: "/orderfeiyong/getdenyfeiyong",
    method: "post",
    data: Qs.stringify(query),
  });
};

// 获取个人费用审批状态中的费用数量
export const OrderfeiyongGetfeiyongstatecount = (query) => {
  return request({
    url: "/orderfeiyong/getfeiyongstatecount",
    method: "post",
    data: Qs.stringify(query),
  });
};

