<template>
  <div class="OweTicketCenter" style="padding-top: 56px">
    <!-- 顶部导航 -->
    <van-nav-bar
      title="欠票信息"
      fixed="true"
      left-arrow
      @click-left="onClickBack"
    />
    <van-tabs
      v-model:active="active"
      swipeable
      sticky
      animated
      offset-top="46px"
      title-active-color="#325cfe"
    >
      <van-tab
        v-for="item in tabList"
        :key="item"
        :name="item.state"
        :title="item.name"
      >
        <oweTicketList :oweTicket="item" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import oweTicketList from "../oweticket/OweTicketList.vue";
export default {
  components: {
    oweTicketList,
  },
  data() {
    return {
      active: 0,
      tabList: [
        {
          name: "待回票",
          auditstate: "0",
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
        },
        {
          name: "部分回票",
          auditstate: "2",
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
        },
        {
          name: "全部回票",
          auditstate: "1",
          z_userid: this.$route.query[0],
          z_token: this.$route.query[1],
        },
      ],
    };
  },
  methods: {
    onClickBack() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        window.webkit.messageHandlers.getDataFormVue.postMessage({
          title: "333", //vue给iOS传值
        });
      } else {
        window.android.onFinish();
      }
    },
  },
};
</script>

<style scoped>
:deep(.van-tabs__line) {
  background: white !important;
}
.van-nav-bar {
  background-color: #325cfe;
}
:deep(.van-nav-bar .van-icon) {
  color: white !important;
}
:deep(.van-nav-bar__title) {
  color: white !important;
}
:deep(.van-nav-bar__text) {
  color: white !important;
}
</style>
