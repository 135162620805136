<template>
    <!-- 顶部导航 -->
    <NavBar
      z_left_arrow
      title="地图"
      @click-left="onClickLeft"
      safe-area-inset-top
    />
  </template>

<script>


import NavBar from "../view/NavBar.vue";


export default {
  components: {
    NavBar,
  },
    data() {
    
  },
  methods: {
  
  },
  mounted() {
//    console.log(this.$route);
  },
};
</script>

<style scoped></style>