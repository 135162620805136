import { createRouter, createWebHashHistory } from "vue-router";
// import OrderList from "./components/OrderList.vue";
import ListDetails from "./components/ListDetails.vue";
import OrderReceiving from "././components/missed/OrderReceiving.vue"; // 接单
import DenyOrder from "././components/missed/DenyOrder.vue"; // 拒单
import StepFlow from "./components/implement/StepFlow.vue";
import ReimburstMentPage from "././components/received/ReimburstMentPage.vue"; // 报销
import ReimburstMentEdit from "././components/received/ReimburstMentEdit.vue"; // 报销
import UploadJobPic from "./components/implement/UploadJobPic.vue";
import UploadSignPic from "./components/implement/UploadSignPic.vue";
import MapContainer from "../src/components/MapContainer.vue";
import HomePage from "./components/HomePage.vue";
import FlashPage from "../src/components/FlashPage.vue";
import OweTicketCenter from "../src/components/oweticket/OweTicketCenter.vue";
import MaterialRequisition from "../src/components/material/MaterialRequisition.vue";
import MaterialSubmission from "../src/components/material/MaterialSubmission.vue";
import MaterialLook from "../src/components/material/MaterialLook";
import OperatingCosts from "../src/components/OperatingCosts";
// import TurnSendList from "../src/components/reassignment/TurnSendList";
import TurnSendPage from "../src/components/reassignment/TurnSendPage";
import ReassignOrder from "../src/components/reassignment/ReassignOrder";
import MyAddress from "../src/components/MyAddress.vue";
import ReimburseMent from "../src/components/ReimburseMent.vue";
import StatisticalReports from "./components/StatisticalReports.vue";
import CheckOperatingCosts from "./components/CheckOperatingCosts.vue";
import ApprovalCenter from "./components/approvalcenter/ApprovalCenter.vue";
import ApprovalCenterList from "./components/approvalcenter/ApprovalCenterList.vue";
import TestJump from "./components/TestJump.vue";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      component: FlashPage,
    },
    {
      path: "/homePage",
      component: HomePage,
    },
    {
      path: "/listDetails",
      component: ListDetails,
    },
    {
      path: "/OrderReceiving",
      component: OrderReceiving,
    },
    {
      path: "/DenyOrder",
      component: DenyOrder,
    },
    {
      path: "/StepFlow",
      component: StepFlow,
    },
    {
      path: "/ReimburstMentPage",
      component: ReimburstMentPage,
    },
    {
      path: "/ReimburstMentEdit",
      component: ReimburstMentEdit,
    },
    {
      path: "/UploadJobPic",
      component: UploadJobPic,
    },
    {
      path: "/UploadSignPic",
      component: UploadSignPic,
    },
    {
      path: "/MapContainer",
      component: MapContainer,
    },
    {
      path: "/OweTicketCenter",
      component: OweTicketCenter,
    },
    {
      path: "/MaterialRequisition",
      component: MaterialRequisition,
    },
    {
      path: "/MaterialSubmission",
      component: MaterialSubmission,
    },
    {
      path: "/MaterialLook",
      component: MaterialLook,
    },
    {
      path: "/OperatingCosts",
      component: OperatingCosts,
    },
    {
      path: "/TurnSendPage",
      component: TurnSendPage,
    },
    {
      path: "/ReassignOrder",
      component: ReassignOrder,
    },
    {
      path: "/MyAddress",
      component: MyAddress,
    },
    {
      path: "/ReimburseMent",
      component: ReimburseMent,
    },
    {
      path: "/StatisticalReports",
      component: StatisticalReports,
    },
    {
      path: "/CheckOperatingCosts",
      component: CheckOperatingCosts,
    },
    {
      path: "/ApprovalCenter",
      component: ApprovalCenter,
    },
    {
      path: "/ApprovalCenterList",
      component: ApprovalCenterList,
    },
    {
      path: "/TestJump",
      component: TestJump,
    },
  ],
});

export default router;
