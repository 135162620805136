<template>
  <!-- 顶部导航 -->
  <NavBar z_left_arrow title="填写施工计划" @click-left="onClickLeft" />

  <!-- 表单内容：驻点接单 -->
  <div class="main" style="padding-top: 66px;">
    <!-- 计划进场开始日期 -->
    <van-cell-group inset>
      <van-field
        label="要求进场施工日期"
        :model-value="$route.query.planEnterStartDate"
        input-align="right"
        readonly
      />
    </van-cell-group>
    <br />
    <van-cell-group inset>
      <van-field
        label="计划完工日期"
        :model-value="$route.query.askDate"
        input-align="right"
        readonly
      />
    </van-cell-group>
    <br />

    <van-notice-bar
      left-icon="volume-o"
      text="计划进厂施工日期，要求在计划进场日期范围内"
    />
    <br />
    <!-- 计划进场施工日期 -->
    <van-cell-group inset>
      <van-cell
        title="计划进场施工日期"
        :value="dateJHJC"
        @click="showJHJC = true"
      />
      <van-calendar
        v-model:show="showJHJC"
        @confirm="onConfirmJHJC"
        color="#1989fa"
        :max-date="maxDate"
      />
    </van-cell-group>
    <br />

    <!-- 是否需要物料 -->
    <van-cell-group inset>
      <van-cell center title="是否需要物料">
        <template #right-icon>
          <van-switch v-model="checked" size="24" />
        </template>
      </van-cell>
    </van-cell-group>
    <br />

    <!-- 计划物料到货日期 -->
    <van-cell-group inset v-if="this.checked == true">
      <van-cell
        title="计划物料到货日期"
        :value="dateJHWL"
        @click="showJHWL = true"
      />
      <van-calendar
        v-model:show="showJHWL"
        @confirm="onConfirmJHWL"
        color="#1989fa"
        :max-date="maxDate"
      />
    </van-cell-group>
    <br />

    <van-notice-bar
      left-icon="volume-o"
      text="计划完工日期，要求小于等于店服计划完工日期"
    />
    <br />
    <!-- 计划完工日期 -->
    <van-cell-group inset>
      <van-cell
        title="计划完工日期"
        :value="dateJHWG"
        @click="showJHWG = true"
      />
      <van-calendar
        v-model:show="showJHWG"
        @confirm="onConfirmJHWG"
        color="#1989fa"
        :max-date="maxDate"
      />
    </van-cell-group>
    <br />
  </div>

  <!-- 提交按钮 -->
  <div class="footer" style="background-color: #f8f8f8">
    <van-button
      style="width: 95%; margin-left: 10px"
      round
      type="primary"
      size="large"
      @click="
        submit(dateJHJC, dateJHWG, dateJHWL, checked, $route.query.jumpName)
      "
      >{{
        $route.query.jumpName == "接单" ? "接单" : "修改个人计划"
      }}</van-button
    >
  </div>
</template>

<script>
import { ref } from "vue";
import { Toast } from "vant";
import { ReceivingOrRejecting, OrderUpdate } from "../../api";
import NavBar from "../../view/NavBar.vue";
// import { reactive, toRefs } from "vue";
export default {
  components: {
    NavBar,
  },
  setup() {
    // 计划进场施工日期
    const dateJHJCTime = ref("");
    const dateJHJC = ref("");
    const dateJHJCLong = ref("");
    const showJHJC = ref(false);
    // const state = reactive({
    //     isDisable:false
    // });
    const formatDateJHJC = (dateJHJC) => {
      // `${dateJHJC.getFullYear()}-${
      //   dateJHJC.getMonth() + 1
      // }-${dateJHJC.getDate()}`;
      var year = dateJHJC.getFullYear();
      var month = dateJHJC.getMonth() + 1;
      var strDate = dateJHJC.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    };
    const onConfirmJHJC = (valueJHJC) => {
      showJHJC.value = false;
      dateJHJCLong.value = valueJHJC;
      dateJHJC.value = formatDateJHJC(valueJHJC);
    };

    // 选择是否需要物料
    const checked = ref(false);

    // 计划进场施工日期
    const dateJHWL = ref("");
    const dateJHWLLong = ref("");
    const showJHWL = ref(false);

    const formatDateJHWL = (dateJHWL) =>
      // `${dateJHWL.getFullYear()}-${
      //   dateJHWL.getMonth() + 1
      // }-${dateJHWL.getDate()}`;
      {
        var year = dateJHWL.getFullYear();
        var month = dateJHWL.getMonth() + 1;
        var strDate = dateJHWL.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var currentdate = year + "-" + month + "-" + strDate;
        return currentdate;
      };
    const onConfirmJHWL = (valueJHWL) => {
      showJHWL.value = false;
      dateJHWLLong.value = valueJHWL;
      dateJHWL.value = formatDateJHWL(valueJHWL);
    };

    // 计划完工日期
    const dateJHWG = ref("");
    const dateJHWGLong = ref("");
    const showJHWG = ref(false);
    const year = ref("");

    const formatDateJHWG = (dateJHWG) =>
      // `${dateJHWG.getFullYear()}-${
      //   dateJHWG.getMonth() + 1
      // }-${dateJHWG.getDate()}`;
      {
        var year = dateJHWG.getFullYear();
        var month = dateJHWG.getMonth() + 1;
        var strDate = dateJHWG.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var currentdate = year + "-" + month + "-" + strDate;
        return currentdate;
      };
    const onConfirmJHWG = (valueJHWG) => {
      showJHWG.value = false;
      dateJHWGLong.value = valueJHWG;
      dateJHWG.value = formatDateJHWG(valueJHWG);
    };

    // const formatDateYear = (year) => `${year.getFullYear()}`;

    return {
      // ...toRefs(state),
      year,
      dateJHJCTime,
      dateJHJC,
      dateJHJCLong,
      showJHJC,
      onConfirmJHJC,

      dateJHWL,
      showJHWL,
      dateJHWLLong,
      onConfirmJHWL,

      dateJHWG,
      showJHWG,
      dateJHWGLong,
      onConfirmJHWG,
      // formatDateYear,
      checked,
      maxDate: new Date(
        new Date().getFullYear() + 1,
        new Date().getMonth() + 1,
        new Date().getDate()
      ),
      // minDate: new Date(2021, 0, 1),
      // maxDate: new Date(formatDateYear + 1, 0, 31),
    };
  },
  mounted() {
    this.dateJHJC =
      this.formatDate(parseInt(this.$route.query.airJhjcsgTime)) == "暂无"
        ? ""
        : this.formatDate(parseInt(this.$route.query.airJhjcsgTime));
    this.dateJHWG =
      this.formatDate(parseInt(this.$route.query.airJhwgTime)) == "暂无"
        ? ""
        : this.formatDate(parseInt(this.$route.query.airJhwgTime));
    this.dateJHWL =
      this.formatDate(parseInt(this.$route.query.airJhwldhTime)) == "暂无"
        ? ""
        : this.formatDate(parseInt(this.$route.query.airJhwldhTime));
  },
  methods: {
    // 返回
    onClickLeft() {
      // 跳转上一级。
      this.$router.go(-1);
    },
    // 时间戳转换
    formatDate(date) {
      // 获取单元格数据
      let data = date;
      if (data === "" || isNaN(date)) {
        return "暂无";
      } else {
        let dt = new Date(data);
        let month =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : "" + (dt.getMonth() + 1);
        let day = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
        return (
          dt.getFullYear() +
          "-" +
          // (dt.getMonth() + 1) +
          month +
          "-" +
          // dt.getDate() +
          day +
          " "
          // +
          // dt.getHours() +
          // ":" +
          // dt.getMinutes() +
          // ":" +
          // dt.getSeconds()
        );
      }
    },
    submit(dateJHJC, dateJHWG, dateJHWL, checked, jumpName) {
      let airisneedmateriel = "";
      if (checked) {
        airisneedmateriel = "1";
      } else {
        airisneedmateriel = "0";
      }
      if (dateJHJC == "") {
        Toast("请先选择计划进场施工日期");
        return;
      }
      if (dateJHWG == "") {
        Toast("请先选择计划完工日期");
        return;
      }
      if (checked && dateJHWL == "") {
        Toast("请先选择计划物料到货日期");
        return;
      }
      if (jumpName === "接单") {
        ReceivingOrRejecting({
          // token:
          //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
          // userid: "24",
          token: this.$route.query.z_token,
          userid: this.$route.query.z_userid,
          airisneedmateriel: airisneedmateriel,
          airjhjcsgtime: dateJHJC,
          airjhwgtime: dateJHWG,
          airjhwldhtime: dateJHWL,
          orderid: this.$route.query.id,
          receive: "1",
        })
          .then((result) => {
            if (result.code == 1) {
              Toast("接单成功，请及时处理");
              this.$router.go(-2);
            } else {
              Toast(result.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (jumpName === "计划") {
        OrderUpdate({
          // token:
          //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
          // userid: "24",
          token: this.$route.query.z_token,
          userid: this.$route.query.z_userid,
          airisneedmateriel: airisneedmateriel,
          airjhjcsgtime: dateJHJC,
          airjhwgtime: dateJHWG,
          airjhwldhtime: dateJHWL,
          orderid: this.$route.query.id,
        })
          .then((result) => {
            if (result.code == 1) {
              Toast("修改个人计划成功");
              this.$router.go(-1);
            } else {
              Toast(result.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
div .main {
  /* position: fixed; */
  height: max-content;
  width: 100%;
  background-color: #f8f8f8;
  padding-top: 20px;
  position: absolute;
  padding-bottom: 200px;
}
.footer {
  height: 60px;
  width: 100%;
  background-color: #f8f8f8;
  position: fixed;
  margin-bottom: 0px;
}
:deep(.van-field__label) {
  width: 40%;
}
</style>
