<template>
  <!-- 顶部导航 -->
  <NavBar z_left_arrow title="选择拒单理由" @click-left="onClickLeft" />

  <!-- 表单内容：驻点接单 -->
  <div class="main" style="padding-top: 56px">
    <van-notice-bar left-icon="volume-o" text="拒绝接单时需要选择拒单理由" />
    <br />

    <!-- 计划进场开始日期 -->

    <van-cell-group inset>
      <van-field
        v-model="result"
        is-link
        readonly
        label="选择拒单理由"
        placeholder="请选择"
        @click="showPicker = true"
      />

      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
          :columns="columns"
          @cancel="showPicker = false"
          @confirm="onConfirm"
        />
      </van-popup>
    </van-cell-group>
    <br />

    <!-- 填写拒单理由 -->

    <van-cell-group inset>
      <van-field
        v-model="value"
        label="拒单理由"
        placeholder="请输入拒单理由"
        maxlength="20"
      />
    </van-cell-group>
    <br />

    <!-- 提交按钮 -->

    <div class="footer" style="background-color: #f8f8f8">
      <!-- 提交按钮 -->
      <van-button
        style="width: 95%; margin-left: 10px"
        round
        type="primary"
        size="large"
        color="red"
        @click="submit(result, value)"
        >拒单</van-button
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { Toast } from "vant";
import { ReceivingOrRejecting, Rejection } from "../../api";
import NavBar from "../../view/NavBar.vue";
export default {
  components: {
    NavBar,
  },
  setup() {
    const columns = ["距离太远", "计划有变", "商家有变", "时间仓促"];
    const result = ref("");
    const showPicker = ref(false);

    const onConfirm = (value) => {
      result.value = value;
      showPicker.value = false;
    };

    const value = ref("");

    return {
      result,
      columns,
      onConfirm,
      showPicker,
      value,
    };
  },
  methods: {
    // 返回
    onClickLeft() {
      // 跳转上一级。
      this.$router.go(-1);
    },
    submit(result, value) {
      if (result == "") {
        Toast("请选择拒单理由");
        return;
      }
      let rejecting = "";
      if (value == "") {
        rejecting = result;
      } else {
        rejecting = value;
      }
      console.log(rejecting);
      console.log(this.$route.query.z_token);
      console.log(this.$route.query.z_userid);
      if (this.$route.query.routeName == "ReassignOrder") {
        Rejection({
          // token:
          //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
          // userid: "24",
          token: this.$route.query.z_token,
          userid: this.$route.query.z_userid,
          rejecting: rejecting,
          orderid: this.$route.query.id,
        })
          .then((result) => {
            if (result.code == 1) {
              Toast("拒单成功");
              this.$router.go(-2);
            } else {
              Toast(result.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        ReceivingOrRejecting({
          // token:
          //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
          // userid: "24",
          token: this.$route.query.z_token,
          userid: this.$route.query.z_userid,
          rejecting: rejecting,
          orderid: this.$route.query.id,
          receive: "0",
        })
          .then((result) => {
            if (result.code == 1) {
              Toast("拒单成功");
              this.$router.go(-2);
            } else {
              Toast(result.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
div .main {
  /* position: fixed; */
  height: max-content;
  width: 100%;
  background-color: #f8f8f8;
  padding-top: 20px;
  position: absolute;
}
</style>
