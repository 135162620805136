<template>
  <div class="OweTicketList">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        v-model:error="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <van-cell v-for="item in list" :key="item">
          <span class="projectName">[{{ item.code }}] </span>
          <span class="projectName" v-if="item.auditState == 1 ? true : false"
            >[{{ item.payState == 0 ? "待支付" : "已支付" }}]</span
          >
          <p>录入日期：{{ item.strDate + " " }}</p>
          <p v-if="item.auditState == 1 ? true : false">
            支付日期：{{ item.payDate }}
          </p>
          <p v-if="item.auditState == 1 ? true : false">
            支付金额：{{ item.oweMoneyOk }}元
          </p>
          <p v-if="item.auditState == 1 ? true : false">
            摘要：{{ item.payAbstract }}
          </p>
          <p v-if="item.auditState == 2 ? true : false">
            已回票金额：{{ item.oweMoneyOk }}
          </p>
          <p style="color: red" v-if="item.auditState == 2 ? true : false">
            未回票金额：{{
              parseFloat(item.oweMoney - item.oweMoneyOk).toFixed(2)
            }}
          </p>
          <p v-if="item.auditState == 1 ? false : true">
            欠票总金额：{{ item.oweMoney + " " }}
          </p>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from "vue";
import { UserOweList } from "../../api";
import { Toast } from "vant";
export default {
  props: {
    oweTicket: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const list = ref([]);
    const userList = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const error = ref(false);
    const pageNum = ref(1);
    const onLoad = () => {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      UserOweList({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk2NDE1Njk1MywiaWF0IjoxNjQ4NTM3NzUzfQ._KfES3vyOLUwAEBijcSlwXJ1yvLTvQ3aVGjylt6gvTQ",
        // userid: "24",
        userid: props.oweTicket.z_userid,
        token: props.oweTicket.z_token,
        auditstate: props.oweTicket.auditstate,
      })
        .then((result) => {
          Toast.clear();
          if (refreshing.value) {
            list.value = [];
            refreshing.value = false;
          }
          list.value.push(...result.data);
          loading.value = false;

          finished.value = true;
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
          error.value = true;
          loading.value = false;
          // this.finished = true;
          // Toast("网络错误");
        });
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      pageNum.value = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    return {
      list,
      loading,
      refreshing,
      onRefresh,
      finished,
      pageNum,
      error,
      onLoad,
      userList,
    };
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.projectName {
  color: #02a7f0;
}
p {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}
</style>
