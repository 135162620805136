<template>
  <!-- 顶部导航 -->
  <div class="main">
    <NavBar z_left_arrow title="物料申请提交" @click-left="onClickLeft" />
    <h5>基本信息</h5>
    <van-cell-group inset>
      <van-cell title="订单编号" :value="$route.query.code" />
      <van-cell title="订单名称" :value="$route.query.name" />
      <van-cell title="申请日期" :value="this.getCurrentTime()" />
    </van-cell-group>
    <h5>收货人信息</h5>
    <van-cell-group inset>
      <van-cell @click="jumpAddress()">
        <span>{{ this.addressDict.username }}</span>
        <br />
        <span>{{ this.addressDict.phone }}</span>
        <br />
        <span>{{ this.addressDict.address }}</span>
      </van-cell>
    </van-cell-group>
    <h5>到货信息</h5>
    <van-cell-group inset>
      <van-cell
        required
        title="到货日期"
        :value="dataDHRQ"
        @click="showDHRQ = true"
      />
      <van-calendar
        v-model:show="showDHRQ"
        @confirm="onConfirmDHRQ"
        color="#1989fa"
        :max-date="maxDate"
      />
      <van-field
        v-model="rsk"
        label="备注"
        placeholder="点击输入"
        input-align="right"
        maxlength="30"
      />
    </van-cell-group>

    <h5 style="width: 90%">
      物料列表
      <button @click="showView" style="float: right" class="clearBtn">
        <!-- <van-icon name="plus" /> -->
        新增
      </button>
    </h5>

    <van-cell-group v-for="item in list" :key="item" inset>
      <van-cell>
        <div>
          <span>名称{{ "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0" }}</span
          ><span>{{ item.name }}</span
          ><span style="color: rgb(0, 158, 237)"
            >{{ "\u00A0\u00A0\u00A0" }}[{{ item.purchaseWay }}]</span
          >
          <button class="deleteBtn" @click="deleteList(index)">删除</button>
        </div>
        <div>
          <span>品牌{{ "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0" }}</span
          ><span>{{ item.pinpai }}</span>
        </div>
        <div>
          <span>型号{{ "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0" }}</span>
          <span>{{ item.xinghao }}</span>
        </div>
        <div>
          <span>数量{{ "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0" }}</span
          ><span>{{ item.count }}</span
          ><span>{{ item.danwei }}</span>
        </div>
        <div>
          <!-- <van-row>
            <van-col span="6">备注</van-col>
            <van-col v-if="item.remark" span="17">{{ item.remark }}</van-col>
            <van-col v-if="item.remark == ''" span="17">{{
              item.remark
            }}</van-col>
          </van-row> -->
          <span>备注{{ "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0" }}</span
          ><span v-if="item.remark">{{ item.remark }}</span>
          <span v-if="item.remark == ''">暂无</span>
        </div>
      </van-cell>
    </van-cell-group>
    <div><h5>附件信息</h5></div>
    <van-cell-group inset>
      <van-uploader
        class="preview-cover"
        v-model="photoList"
        :after-read="afterReadPhoto"
        :before-delete="beforeDeletePhone"
        :max-size="12 * 1024 * 1024"
        @oversize="onOversize"
        multiple
        :max-count="50"
      />
    </van-cell-group>
    <van-popup v-model:show="show" round :style="{ width: '80%' }">
      <span
        style="
          line-height: 33px;
          text-align: center;
          display: block;
          font-size: 14px;
          height: 33px;
        "
        >添加物料</span
      >
      <van-field
        v-model="name"
        label="材料名称"
        placeholder="请输入"
        required
        maxlength="16"
        input-align="right"
      />
      <van-field
        v-model="brand"
        label="品牌"
        placeholder="请输入"
        required
        maxlength="16"
        input-align="right"
      />
      <van-field
        v-model="model"
        label="型号"
        placeholder="请输入"
        required
        maxlength="16"
        input-align="right"
      />
      <van-field
        v-model="number"
        label="数量"
        placeholder="请输入"
        required
        type="number"
        maxlength="6"
        input-align="right"
      />
      <van-field
        v-model="result"
        readonly
        label="单位"
        placeholder="请选择"
        input-align="right"
        required
        @click="showPicker = true"
      />
      <van-field
        v-model="modeResult"
        readonly
        label="采购方式"
        placeholder="请选择"
        input-align="right"
        required
        @click="modeShowPicker = true"
      />
      <van-field
        v-model="remarks"
        label="备注"
        placeholder="请输入"
        maxlength="30"
        input-align="right"
      />
      <div style="height: 66px">
        <van-button
          type="primary"
          size="large"
          round
          style="
            display: block;
            margin: 0 auto;
            width: 65%;
            height: 44px;
            top: 11px;
          "
          @click="confirmBtn(result, modeResult)"
        >
          确定
        </van-button>
      </div>
    </van-popup>
    <!-- <van-popup v-model:show="show" teleport="body" /> -->
    <div class="footer" style="width: 95%; margin-left: 10px">
      <van-button
        type="primary"
        size="large"
        round
        :disabled="disable"
        @click="confirmSubmission(dataDHRQ)"
        >确认提交</van-button
      >
    </div>
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <van-popup v-model:show="modeShowPicker" round position="bottom">
      <van-picker
        :columns="modeColumns"
        @cancel="modeShowPicker = false"
        @confirm="modeOnConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "_vant@3.4.5@vant";
import { Dialog } from "vant";
import NavBar from "../../view/NavBar.vue";
import { ref } from "vue";
import { Airmaterial, Deletephoto } from "../../api";
// import { compressImage } from "../config/utils";
import compressor from "compressorjs";
import axios from "axios";
import global from "@/js/global";
// import Qs from "qs"; // 传参需要用qs转换一下

export default {
  components: {
    NavBar,
  },
  setup() {
    // 计划日期
    const dataDHRQ = ref("");
    const showDHRQ = ref(false);
    const dateDHRQLong = ref("");

    const columns = ["块", "件", "套", "个", "KG", "米", "MM（毫米）"];
    const result = ref("");
    const showPicker = ref(false);

    const modeColumns = ["自采", "工厂发货"];
    const modeResult = ref("");
    const modeShowPicker = ref(false);

    const formatDateDHRQ = (dateDHRQ) => {
      var year = dateDHRQ.getFullYear();
      var month = dateDHRQ.getMonth() + 1;
      var strDate = dateDHRQ.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    };
    const onConfirmDHRQ = (valueDHRQ) => {
      showDHRQ.value = false;
      dateDHRQLong.value = valueDHRQ;
      dataDHRQ.value = formatDateDHRQ(valueDHRQ);
    };

    const onConfirm = (value) => {
      result.value = value;
      showPicker.value = false;
    };

    const modeOnConfirm = (value) => {
      modeResult.value = value;
      modeShowPicker.value = false;
    };

    const value = ref("");
    return {
      // ...toRefs(state),
      dataDHRQ,
      showDHRQ,
      formatDateDHRQ,
      onConfirmDHRQ,

      result,
      columns,
      onConfirm,
      showPicker,
      value,
      modeResult,
      modeOnConfirm,
      modeColumns,
      modeShowPicker,

      maxDate: new Date(
        new Date().getFullYear() + 1,
        new Date().getMonth() + 1,
        new Date().getDate()
      ),
    };
  },
  watch: {
    address(newValue) {
      this.address = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    contacts(newValue) {
      this.contacts = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    rsk(newValue) {
      this.rsk = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    name(newValue) {
      this.name = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    brand(newValue) {
      this.brand = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    model(newValue) {
      this.model = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    company(newValue) {
      this.company = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    remarks(newValue) {
      this.remarks = newValue.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, "");
    },
    // number(newValue) {
    //   this.number = newValue.replace(/[^a-zA-Z0-9\u4E00-\u9FA5]/g, "");
    // },
  },
  data() {
    return {
      list: [],
      listString: "",
      show: false,
      name: "",
      brand: "",
      model: "",
      number: "",
      company: "",
      remarks: "",
      photoList: [],
      imagePathList: [],
      // primaryPhotoList: [],
      // 收货人信息
      addressDict: { username: "", phone: "", address: "" },

      // 快递信息
      address: this.$route.query.place,
      contacts: this.$route.query.z_name,
      phone: this.$route.query.z_phone,
      rsk: "",
      // 处理地址选择
      z_isLook: true,
      disable: false,
    };
  },
  methods: {
    onOversize(file) {
      console.log(file);
      Toast("照片过大");
    },
    // 获取当前年月日
    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      var yy = new Date().getFullYear();
      var mm = new Date().getMonth() + 1;
      var dd = new Date().getDate();
      if (mm >= 1 && mm <= 9) {
        mm = "0" + mm;
      }
      if (dd >= 0 && dd <= 9) {
        dd = "0" + dd;
      }
      _this.gettime = yy + "-" + mm + "-" + dd;
      return _this.gettime;
    },
    confirmBtn(result, modeResult) {
      if (this.name == "") {
        Toast("请输入材料名称");
        return;
      }
      if (this.brand == "") {
        Toast("请输入品牌");
        return;
      }
      if (this.model == "") {
        Toast("请输入型号");
        return;
      }
      if (this.number == "") {
        Toast("请输入数量");
        return;
      }
      if (parseFloat(this.number) < 0) {
        Toast("请输入正确的数量");
        return;
      }
      if (result == "") {
        Toast("请选择单位");
        return;
      }
      if (modeResult == "") {
        Toast("请选择采购方式");
        return;
      }
      // if (this.remarks == "") {
      //   Toast("请输入备注");
      //   return;
      // }
      let addData = {
        name: this.name,
        pinpai: this.brand,
        xinghao: this.model,
        count: this.number,
        danwei: result,
        remark: this.remarks,
        purchaseWay: modeResult,
      };
      this.list.push(addData);
      (this.name = ""),
        (this.brand = ""),
        (this.model = ""),
        (this.number = ""),
        // (this.company = ""),
        // (result.value = ""),
        (this.result = ""),
        (this.modeResult = ""),
        (this.remarks = ""),
        (this.show = false);
    },
    showView() {
      this.show = true;
    },
    // 确认提交
    confirmSubmission(dateDHRQ) {
      if (dateDHRQ == "") {
        // Toast(this.list.length);
        Toast("请选择到货日期");
        return;
      }
      if (this.addressDict.username == "") {
        Toast("请选择收货人信息");
        return;
      }
      if (this.list.length <= 0) {
        Toast("请添加所需要的物料信息");
        return;
      }
      this.disable = true;
      Airmaterial({
        address: this.addressDict.address,
        appdate: this.getCurrentTime(),
        endDate: dateDHRQ,
        linkman: this.addressDict.username,
        memo: this.rsk,
        ordercailiaolist: JSON.stringify(this.list),
        orderid: this.$root.$route.query.id,
        phonenum: this.addressDict.phone,
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        // signphoto: JSON.stringify(this.imagePathList),
        cailiaophotolist: this.imagePathList,
      })
        .then((result) => {
          this.disable = false;
          if (result.code == 1) {
            Toast("提交成功");
            this.$router.go(-1);
          } else {
            Toast(result.message);
          }
        })
        .catch((err) => {
          this.disable = false;
          console.log(err);
        });
    },
    // 物料删除
    deleteList(index) {
      Dialog.confirm({
        title: "",
        message: "是否删除该条物料信息",
      })
        .then(() => {
          this.list.splice(index, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    // 上传照片
    afterReadPhoto(file) {
      const that = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      if (file.length >= 1) {
        file.forEach((item, index) => {
          let qua = 0.2;
          if (item.file.size > 1 * 1024 * 1024) {
            qua = 0.2;
          } else {
            qua = 0.8;
          }
          new compressor(item.file, {
            quality: qua,
            success(result) {
              const formData = new FormData();
              formData.append("photo", result, result.name);
              formData.append("token", that.$route.query.z_token);
              formData.append("userid", that.$route.query.z_userid);
              let config = {
                headers: {
                  //添加请求头
                  "Content-Type": "multipart/form-data",
                },
              };
              axios
                .post(global.apiUrl + "/upload/uploadphoto", formData, config)
                .then((resultData) => {
                  if (resultData.data.code == 1) {
                    that.imagePathList.push(resultData.data.data);
                    if (file.length - 1 == index) {
                      Toast.clear();
                      Toast("上传成功");
                    }
                  } else {
                    if (file.length - 1 == index) {
                      Toast.clear();
                      Toast("上传失败");
                      that.photoList.splice(index, 1);
                      that.imagePathList.splice(index, 1);
                    }
                  }
                })
                .catch((err) => {
                  if (file.length - 1 == index) {
                    Toast.clear();
                    Toast("上传失败");
                    that.photoList.splice(index, 1);
                    that.imagePathList.splice(index, 1);
                  }
                  console.log(err);
                });
            },
          });
        });
      } else {
        file.status = "uploading";
        file.message = "上传中...";
        // console.log("file - file", file.file);
        let qua = 0.2;
        if (file.file.size > 1 * 1024 * 1024) {
          qua = 0.2;
        } else {
          qua = 0.8;
        }
        new compressor(file.file, {
          quality: qua,
          success(result) {
            console.log(result);
            const formData = new FormData();
            formData.append("photo", result, result.name);
            formData.append("token", that.$route.query.z_token);
            formData.append("userid", that.$route.query.z_userid);
            let config = {
              headers: {
                //添加请求头
                "Content-Type": "multipart/form-data",
              },
            };
            axios
              .post(global.apiUrl + "/upload/uploadphoto", formData, config)
              .then((resultData) => {
                if (resultData.data.code == 1) {
                  Toast("上传成功");
                  file.status = "done ";
                  that.imagePathList.push(resultData.data.data);
                } else {
                  Toast("上传失败");
                  file.status = "failed";
                  file.message = "上传失败";
                }
              })
              .catch((err) => {
                Toast("上传失败");
                console.log(err);
              });
          },
        });
      }
    },
    // 删除
    beforeDeletePhone(index, detail) {
      Dialog.confirm({
        title: "",
        message: "是否删除该照片",
      })
        .then(() => {
          if (this.imagePathList[detail.index]) {
            Deletephoto({
              token: this.$route.query.z_token,
              userid: this.$route.query.z_userid,
              photourl: this.imagePathList[detail.index],
            })
              .then((result) => {
                if (result.code == 1) {
                  Toast("删除成功");
                  this.photoList.splice(detail.index, 1);
                  this.imagePathList.splice(detail.index, 1);
                  // this.primaryPhotoList.splice(detail.index, 1);
                } else {
                  this.photoList.splice(detail.index, 1);
                  this.imagePathList.splice(detail.index, 1);
                }
              })
              .catch((err) => {
                this.photoList.splice(detail.index, 1);
                this.imagePathList.splice(detail.index, 1);
                console.log(err);
              });
          } else {
            this.photoList.splice(detail.index, 1);
            this.imagePathList.splice(detail.index, 1);
            // Toast("删除失败");
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    // 选择地址
    jumpAddress() {
      let query = this.$route.query;
      query.z_isTap = true;
      this.$router.push({
        path: "/MyAddress",
        query: query,
      });
    },
  },
  mounted: function () {
    console.log(111);
  },
  /**
   * 生命周期函数--创建完毕
   */
  created() {
    // 如果是从提醒、重复页面返回的
    // let st = localStorage.getItem("st");
    // if (st == false) {
    // localStorage.removeItem("st");
    let stack = localStorage.getItem("stack");
    if (stack) {
      this.addressDict = JSON.parse(stack);
      localStorage.removeItem("stack");
    }
    // }
    // if (stack == "pop") {
    //   return;
    // }
  },
};
</script>

<style scoped>
div .main {
  width: 100%;
  height: max-content;
  padding-top: 66px;
  background-color: #f8f8f8;
  padding-bottom: 80px;
}
.addressSpan {
  background-color: black;
  color: rgb(0, 158, 237);
  font-size: 13px;
  /* height: 200px; */
}
.topSpan {
  background-color: rgb(0, 158, 237);
  width: 14.28%;
  border: 1 solid rgb(110, 110, 110);
  text-align: center;
  color: white;
  font-size: 13px;
}
.contentSpan {
  background-color: white;
  width: 14.28%;
  /* border: 1% solid rgb(110, 110, 110); */
  text-align: center;
  color: black;
  font-size: 13px;
}
.deleteBtn {
  color: rgb(0, 158, 239);
  width: 20.28%;
  border: none;
  background-color: white;
  font-size: 13px;
  text-align: right;
  float: right;
}
.clearBtn {
  border: none;
  background-color: #f8f8f8;
}
.preview-cover {
  margin: 5px 0 0 10px;
}
h5 {
  margin: 10px 20px 10px 20px;
  color: rgb(136, 145, 145);
  text-align: left;
  /* b
  ackground-color: clear; */
}
div .footer {
  height: 60px;
  width: 100%;
  background-color: #f8f8f8;
  position: fixed;
  bottom: 0;
}
:deep(.van-field__control--right) {
  color: rgb(143, 144, 146);
}
</style>
