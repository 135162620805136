<template>
  <!-- 顶部导航 -->
  <NavBar z_left_arrow title="费用报销" @click-left="onClickLeft" />
  <!-- <br /> -->

  <div class="main" style="padding-top: 46px">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        style="padding-bottom: 66px"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
      >
        <!-- <van-cell v-for="item in list" :key="item" :title="item"> -->
        <van-notice-bar
          v-if="this.list.length > 0"
          color="#1989fa"
          background="#ecf9ff"
          left-icon="wap-home-o"
        >
          已审金额：{{
            parseFloat(this.nmoney).toFixed(2)
          }}
          &nbsp;&nbsp;&nbsp;&nbsp;未审金额：{{
            parseFloat(this.ymoney).toFixed(2)
          }}
        </van-notice-bar>
        <van-card
          v-for="item in list"
          :key="item"
          :title="item.name4_0_"
          class="vanClass"
          :price="parseFloat(item.money62_2_).toFixed(2)"
          :desc="item.memo62_2_"
          :thumb="item.path62_2_"
          @click-thumb="showPic(item.path62_2_)"
        >
          <template #tags>
            <van-tag
              plain
              type="danger"
              v-if="item.verify41_62_2_ === false && item.pre31_62_2_"
              >审核拒绝</van-tag
            >
            <van-tag
              plain
              type="primary"
              v-if="item.verify41_62_2_ === true && item.verify42_62_2_"
              >区审通过</van-tag
            >
            <van-tag plain type="primary" v-if="item.verify43_62_2_ === 'PASS'"
              >财务通过</van-tag
            >
            <!-- <van-tag plain type="danger">区审拒绝</van-tag> -->
          </template>
          <template
            #footer
            v-if="
              item.verify41_62_2_ === false && item.verify43_62_2_ != 'PASS'
            "
          >
            <van-button
              type="primary"
              size="mini"
              round
              @click="showPopup(item)"
              >&nbsp;编辑&nbsp;</van-button
            >
            <van-button type="danger" size="mini" round @click="remove(item)"
              >&nbsp;删除&nbsp;</van-button
            >
          </template>
        </van-card>
        <!-- </van-cell> -->
      </van-list>
    </van-pull-refresh>
    <!-- 报销明细列表 -->
  </div>
  <!-- v-if="this.$route.query.feiyong != 'true' -->
  <div
    class="footer"
    style="width: 95%; margin-left: 10px; background-color: #f8f8f8"
  >
    <van-button type="primary" size="large" round @click="reimbursementEntry"
      >报销录入</van-button
    >
    <!-- <van-button
      class="successBtn"
      type="warning"
      size="normal"
      round
      @click="entryCompleted"
      >录入完成</van-button
    > -->
  </div>
</template>

<script>
import {
  OrderFeiyong,
  OrderRinputfeiyong,
  OrderDeletefeiyong,
} from "../../api";
import { Toast } from "vant";
import { Dialog } from "vant";
import { ImagePreview } from "vant";
import NavBar from "../../view/NavBar.vue";
// import e from "express";
// import { ref } from "vue";

export default {
  components: {
    NavBar,
  },
  data: function () {
    return {
      list: [],
      isLoading: false,
      loading: false,
      finished: false,
      nmoney: "",
      ymoney: "",
      isJujue: false,
    };
  },
  methods: {
    // 返回
    onClickLeft() {
      // 跳转上一级。
      this.$router.go(-1);
    },
    // 删除
    remove(itme) {
      // Toast(itme.name4_0_);
      Dialog.confirm({
        title: "",
        message: "是否删除该条费用报销",
      })
        .then(() => {
          OrderDeletefeiyong({
            // token:
            //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
            // userid: "24",
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
            feiyongid: itme.id62_2_,
          })
            .then(() => {
              this.list = [];
              Toast("删除成功");
              this.getDetails();
            })
            .catch((err) => {
              console.log(err);
              Toast("网络错误");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 编辑
    showPopup(item) {
      let listData = item;
      listData.z_isEdit = "1";
      listData.id = this.$route.query.id;
      listData.z_token = this.$route.query.z_token;
      listData.z_userid = this.$route.query.z_userid;
      this.$router.push({
        path: "/ReimburstMentEdit",
        query: listData,
      });
    },
    getDetails() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0, // 持续展示 toast
      });
      OrderFeiyong({
        // token:
        //   "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyNCIsImV4cCI6MTk1NTUxNjk1MCwiaWF0IjoxNjM5OTg0MTUwfQ.Zu6cTzX9o-6z2hkLGsJmDFECjzqkKcQYlzweYpeEnP8",
        // userid: "24",
        token: this.$route.query.z_token,
        userid: this.$route.query.z_userid,
        // orderid: this.$route.query.id,
        orderid: this.$route.query.id,
      })
        .then((result) => {
          Toast.clear();
          console.log(result.data);
          this.loading = false;
          this.finished = true;
          this.nmoney = result.data.nmoney;
          this.ymoney = result.data.ymoney;
          // // 将新数据与老数据进行合并
          this.list = [...this.list, ...result.data.orderFeiYongVoList];
          this.list.forEach((item) => {
            if (item.verify41_62_2_ === false && item.pre31_62_2_) {
              this.isJujue = true;
            } else {
              this.isJujue = false;
            }
          });
        })
        .catch((err) => {
          Toast.clear();
          console.log(err);
          Toast("网络错误");
        });
    },
    // getDetails() {
    //   SysuserAddresslist({
    //     token:
    //       "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI4MSIsImV4cCI6MTk2Mjc4NDMyMywiaWF0IjoxNjQ3MTY1MTIzfQ.GlMYeAgzO1636STcPJ2NgvINJPv1JmjtwszItryPn-8",
    //     userid: "81",
    //     // orderid: this.$route.query.id,
    //     departmentid: 22,
    //     page: 1,
    //     size: 100,
    //   })
    //     .then((result) => {
    //       console.log("成功" + result.code);
    //       this.loading = false;
    //       this.finished = true;
    //       // 将新数据与老数据进行合并
    //       this.list = [...this.list, ...result.data.list];
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       Toast("网络错误");
    //     });
    // },
    onRefresh() {
      // 上拉加载
      setTimeout(() => {
        Toast("刷新成功");
        this.isLoading = false;
        this.list = [];
        this.getDetails();
        // this.count++;
        // this.pageNume = 1;
        // this.onLoad(this.itmeState);
      }, 1000);
    },
    // 报销录入
    reimbursementEntry() {
      if (this.isJujue == true) {
        Toast(
          "请注意您有被驳回的报销单未处理，请您及时处理，期间系统将暂停此订单添加其他报销的功能"
        );
      } else {
        let listData = this.$route.query;
        listData.z_isEdit = 2;
        this.$router.push({
          path: "/ReimburstMentEdit",
          query: listData,
        });
      }
    },
    // 全部录入完成
    entryCompleted() {
      if (this.list.length <= 0) {
        Toast("请先进行报销录入");
        return;
      }
      Dialog.confirm({
        title: "",
        message: "是否确认报销录入完成",
      })
        .then(() => {
          OrderRinputfeiyong({
            token: this.$route.query.z_token,
            userid: this.$route.query.z_userid,
            feiyong: 1,
            orderid: this.$route.query.id,
            feiyongtypeid: 0,
            feiyongid: 0,
          })
            .then((result) => {
              if (result.code == 1) {
                Toast("全部录入完成");
                this.$router.go(-1);
              } else {
                Toast(result.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 点击左侧图片
    showPic(value) {
      ImagePreview([value]);
    },
  },
  mounted: function () {
    if (this.$route.query) { 
      this.getDetails();
    }
  },
};
</script>

<style>
.main {
  height: 100%;
  width: max-content;
  background-color: #f8f8f8;
  /* position: absolute; */
}
.successBtn {
  width: 35%;
  margin: 10px 7% 10px 7%;
}
div .footer {
  height: 60px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  bottom: 0;
}
.vanClass {
  background-color: #fff;
}
</style>
